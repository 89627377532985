var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Text } from '../../../../touring-ui/components/typography/text';
import { PasscodeIcon } from '../../../../touring-ui/icons/_export';
import { Form } from '../../../../touring-ui/components/form';
import { Input } from '../../../../touring-ui/components/form/input';
import { Button } from '../../../../touring-ui/components/buttons';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
import { updateUserRemote } from '../../../../../api/internal/auth';
import { useApplicationCtx } from '../../../../../context/context';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { Label } from '../../../../touring-ui/components/form/label';
import { InputInfo } from '../../../../touring-ui/components/form/info';
export const UserLoginSetPassword = (props) => {
    if (props.state != 'password')
        return React.createElement(React.Fragment, null);
    const context = useApplicationCtx();
    const { t, i18n } = useTranslation();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [errorData, setErrorData] = useState({
        password: null,
        passwordRepeat: null,
    });
    const valid = password != '' && passwordRepeat != '' && password == passwordRepeat;
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                if (value.length < 8)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('passwordRequirements') }));
                if (value.length >= 8)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                setPassword(value);
                break;
            case 'passwordRepeat':
                if (password != value)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('repeatPasswordNotSame') }));
                if (password == value)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                setPasswordRepeat(value);
                break;
        }
    };
    const saveRemote = (e) => __awaiter(void 0, void 0, void 0, function* () {
        if (e)
            e.preventDefault();
        if (!context.user || password != passwordRepeat || password == '' || passwordRepeat == '')
            return;
        setLoading(true);
        const userWithPassword = Object.assign(Object.assign({}, context.user), { password: password });
        const response = yield updateUserRemote(userWithPassword);
        setLoading(false);
        if (response) {
            props.onSetPassword();
        }
    });
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
        React.createElement(Text, { type: 'headline' },
            React.createElement(PasscodeIcon, { className: "w-8 h-8" }),
            t('setPassword')),
        React.createElement(Text, null, t('setPasswordInstructions')),
        React.createElement(Form, { onSubmit: saveRemote, className: 'mt-2' },
            React.createElement("div", { className: 'grid grid-cols-1 lg:grid-cols-2 gap-5' },
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('password')),
                    React.createElement(Input, { type: 'password', name: 'password', value: password, onChange: handleChange }),
                    React.createElement(InputInfo, { type: errorData.password ? 'error' : 'normal' },
                        errorData.password,
                        !errorData.password && t('passwordDescription'))),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('passwordRepeat')),
                    React.createElement(Input, { type: 'password', name: 'passwordRepeat', value: passwordRepeat, onChange: handleChange }),
                    React.createElement(InputInfo, { type: errorData.passwordRepeat ? 'error' : 'normal' },
                        errorData.passwordRepeat,
                        !errorData.passwordRepeat && t('passwordRepeatDescription')))),
            React.createElement(Button, { type: 'primary', size: 'md', onClick: saveRemote, disabled: !valid, className: 'place-content-center' }, t('save')))));
};
