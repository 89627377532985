import React from 'react';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { Text } from '../../../../touring-ui/components/typography/text';
import { Edit03Icon, Globe04Icon, Lock04Icon, PlusCircleIcon, Save01Icon } from '../../../../touring-ui/icons/_export';
import { Button } from '../../../../touring-ui/components/buttons';
import { ActivityIndicator } from '../../../../touring-ui/components/acitivityIndicator';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../../../touring-ui/components/form/switch';
import SimpleBar from 'simplebar-react';
export const FloatingTourWrapper = (props) => (React.createElement("div", { className: 'w-full md:w-450 max-h-full flex flex-col overflow-hidden border border-slate-300 rounded-lg bg-transparent drop-shadow-xl dark:border-neutral-700 pointer-events-auto' }, props.children));
export const FloatingTourHeader = (props) => (React.createElement(Flexbox, { gap: 'md', className: 'bg-indigo-600 px-4 py-2 text-white cursor-pointer', onClick: props.onClick, position: 'center' },
    React.createElement(Text, { type: 'title', className: 'font-semibold text-white' }, props.children),
    React.createElement(Edit03Icon, { className: "w-4 h-4" }),
    React.createElement("div", { className: 'flex-grow' }),
    props.isPublic && React.createElement(Globe04Icon, { className: "w-4 h-4" }),
    !props.isPublic && React.createElement(Lock04Icon, { className: "w-4 h-4" })));
export const FloatingTourBody = (props) => {
    return (React.createElement("div", { className: 'bg-white dark:bg-neutral-900 max-h-full overflow-hidden' },
        React.createElement(SimpleBar, { style: { maxHeight: props.maxHeight - 140 }, autoHide: false },
            React.createElement(Flexbox, { direction: 'vertical', gap: 'none', className: 'divide-y divide-slate-300 dark:divide-neutral-800' }, props.children))));
};
export const FloatingTourFooter = (props) => (React.createElement(Flexbox, { className: 'px-4 py-2 bg-slate-100 dark:bg-neutral-900 justify-center border-t border-slate-300 dark:border-neutral-700' }, props.children));
export const FloatingTourActions = (props) => (React.createElement(Flexbox, { gap: 'lg', className: 'px-4 py-3 bg-slate-100 dark:bg-neutral-900 border-b border-slate-300 dark:border-neutral-700' }, props.children));
export const FloatingTour = (props) => {
    const { t } = useTranslation();
    return (React.createElement(FloatingTourWrapper, null,
        React.createElement(FloatingTourHeader, { isPublic: props.isPublic, onClick: props.onEdit }, props.title),
        React.createElement(FloatingTourActions, null,
            React.createElement("div", { className: 'flex flex-row gap-1 font-semibold text-sm' },
                React.createElement(Switch, { enabled: props.isTourVisible, onChange: props.onToggleTour }),
                " ",
                t('tour', { count: 1 })),
            props.isLoggedIn && (React.createElement("div", { className: 'flex flex-row gap-1 font-semibold text-sm' },
                React.createElement(Switch, { enabled: props.arePlacesVisible, onChange: props.onTogglePlaces }),
                ' ',
                t('place', { count: 2 }))),
            !props.isLoggedIn && (React.createElement("div", { className: 'flex flex-row gap-1 font-semibold text-sm' },
                React.createElement(Switch, { enabled: false, onChange: props.onUseFreeAccountFeature }),
                " ",
                t('place', { count: 2 }))),
            React.createElement("div", { className: 'flex-grow' }),
            props.isLoading && React.createElement(ActivityIndicator, null, "Verarbeiten"),
            !props.isLoading && (React.createElement(React.Fragment, null,
                React.createElement(Button, { size: 'xs', onClick: props.isLoggedIn ? props.onSave : props.onUseFreeAccountFeature },
                    React.createElement(Save01Icon, { className: 'w-4 h-4' }),
                    t('save'))))),
        React.createElement(React.Fragment, null,
            React.createElement(FloatingTourBody, { maxHeight: props.maxHeight }, props.children),
            React.createElement(FloatingTourFooter, null,
                React.createElement(Button, { type: 'primary', size: 'sm', onClick: props.onAddDay, className: 'w-full justify-center' },
                    React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
                    t('addDay'))))));
};
