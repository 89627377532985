import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { appReducer } from './reducer';
import { initNewTour } from '../interfaces/tour';
import { isDarkModeActive } from '../lib/darkMode';
import i18n from '../lib/i18n';
export const initialAppContext = {
    tour: initNewTour(i18n.t, null, 'car'),
    user: null,
    ui: {
        tourVisible: true,
        placesVisible: true,
        isLoading: false,
        inSync: true,
        isReadOnly: false,
        isPrivate: false,
        isDarkModeActive: isDarkModeActive(),
        bbox: null,
        defaultTravelMode: 'car',
        zoom: 0,
    },
};
export const ApplicationCtx = createContext(initialAppContext);
export const ApplicationDispatchCtx = createContext((value) => { });
export const useApplicationCtx = () => useContext(ApplicationCtx);
export const useApplicationDispatchCtx = () => useContext(ApplicationDispatchCtx);
export function ApplicationContextProvider(props) {
    // Uncomment when fails
    const [ctx, dispatch] = useReducer(appReducer, initialAppContext);
    return (React.createElement(ApplicationCtx.Provider, { value: ctx },
        React.createElement(ApplicationDispatchCtx.Provider, { value: dispatch }, props.children)));
}
ApplicationContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
