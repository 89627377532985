var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { tutorials } from './tutorials';
import { TutorialItem } from './item';
import { nanoid } from 'nanoid';
import { useDialogDispatchCtx } from '../../atoms/dialog/context';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Button } from '../../../touring-ui/components/buttons';
import { useTranslation } from 'react-i18next';
export const TutorialDialogBody = () => {
    const { t } = useTranslation();
    const [currentIndex, setIndex] = useState(0);
    const dialogDispatch = useDialogDispatchCtx();
    const actions = {
        onClose: () => __awaiter(void 0, void 0, void 0, function* () {
            dialogDispatch({ type: 'REMOVE_NODE' });
        }),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Flexbox, { direction: 'horizontal', nowrap: true },
            React.createElement("div", { className: 'flex flex-col w-[300px]' },
                React.createElement("div", { className: 'text-lg font-semibold mb-3 mt-1' }, t('tableOfContents')),
                React.createElement("ul", { className: 'list-decimal divide-y pl-4 pr-3 text-sm' }, tutorials.map((tutorial, index) => (React.createElement("li", { key: tutorial.headline, className: `${currentIndex == index ? 'font-semibold' : ''} cursor-pointer py-2`, onClick: () => setIndex(index) }, tutorial.headline))))),
            React.createElement("div", { className: 'flex flex-col gap-3 w-full' },
                React.createElement(Flexbox, { direction: 'horizontal', gap: 'md', className: 'bg-indigo-600 p-2 rounded-lg', position: 'center' },
                    currentIndex - 1 >= 0 && (React.createElement(Button, { onClick: () => {
                            setIndex(currentIndex - 1);
                        }, type: 'secondary', size: 'sm' }, t('back'))),
                    React.createElement("div", { className: 'flex-grow' }),
                    React.createElement("div", { className: 'text-md text-white font-semibold' }, tutorials[currentIndex].headline),
                    currentIndex == tutorials.length - 1 && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex-grow' }),
                        React.createElement(Button, { onClick: actions.onClose, type: 'secondary', size: 'sm' }, t('close')))),
                    currentIndex < tutorials.length - 1 && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex-grow' }),
                        React.createElement(Button, { onClick: () => {
                                setIndex(currentIndex + 1);
                            }, type: 'secondary', size: 'sm' }, t('next'))))),
                React.createElement("div", null,
                    React.createElement(TutorialItem, Object.assign({ key: nanoid() }, tutorials[currentIndex])))))));
};
