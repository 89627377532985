import React from 'react';
export const Flexbox = (props) => {
    const gap = (() => {
        var _a;
        const selectedSize = (_a = props.gap) !== null && _a !== void 0 ? _a : 'sm';
        switch (selectedSize) {
            case 'sm':
                return `gap-1`;
            case 'md':
                return `gap-2`;
            case 'lg':
                return `gap-3`;
            case 'xl':
                return `gap-5`;
            case '2xl':
                return `gap-10`;
            case 'none':
                return '';
        }
    })();
    const direction = (() => {
        var _a;
        const selectedDirection = (_a = props.direction) !== null && _a !== void 0 ? _a : 'horizontal';
        switch (selectedDirection) {
            case 'horizontal':
                return `flex-row`;
            case 'vertical':
                return `flex-col`;
        }
    })();
    const wrap = (() => {
        var _a;
        const doNotWrap = (_a = props.nowrap) !== null && _a !== void 0 ? _a : false;
        if (!doNotWrap) {
            return `flex-wrap`;
        }
        return `flex-nowrap`;
    })();
    const grow = (() => {
        var _a;
        const shouldGrow = (_a = props.grow) !== null && _a !== void 0 ? _a : false;
        if (!shouldGrow) {
            return ``;
        }
        return `flex-grow`;
    })();
    const itemPosition = (() => {
        var _a;
        const selectedPosition = (_a = props.position) !== null && _a !== void 0 ? _a : 'none';
        switch (selectedPosition) {
            case 'start':
                return `items-start`;
            case 'center':
                return `items-center`;
            case 'end':
                return `items-end`;
            default:
                return '';
        }
    })();
    const nodeProps = (() => {
        const newProps = Object.assign({}, props);
        delete newProps.nowrap;
        delete newProps.gap;
        delete newProps.direction;
        delete newProps.grow;
        delete newProps.position;
        return newProps;
    })();
    return (React.createElement("div", Object.assign({}, nodeProps, { className: `flex ${grow} ${itemPosition} ${wrap} ${gap} ${direction} ${props.className}` }), props.children));
};
