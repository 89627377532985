var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { useModalDispatchCtx } from '../../../atoms/modal/context';
import { useNavigate } from 'react-router-dom';
import { saveTour, updateTour } from '../../../../../api/internal/tour';
import { TourDetail } from './detail';
import { GroupsIndex } from '../../groups/list';
import { FloatingTour } from '../floating';
import { TourShare } from './share';
import { useDialogDispatchCtx } from '../../../atoms/dialog/context';
import { UserLoginIndex } from '../../user/login';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { createDraft, finishDraft } from 'immer';
import { zoomTo } from '../../../../../lib/zoom';
import { ReminderKeys, useReminderCoordinator } from '../../reminders';
export const TourPrivateIndex = (props) => {
    var _a;
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const modalDispatch = useModalDispatchCtx();
    const navigate = useNavigate();
    const dialogDispatch = useDialogDispatchCtx();
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const reminders = useReminderCoordinator();
    useEffect(() => {
        zoomTo(context.tour.groups.flatMap((gr) => gr.waypoints));
    }, [context.tour.id]);
    const tavelModeOptions = [
        ['car', t('car')],
        ['pedestrian', t('pedestrian')],
        ['motorcycle', t('motorcycle')],
        ['bicycle', t('bicycle')],
        ['airplane', t('airplane')],
    ];
    const actions = {
        onToggleTour: function () {
            dispatch({ type: 'UPDATE_UI', ui: Object.assign(Object.assign({}, context.ui), { tourVisible: !context.ui.tourVisible }) });
        },
        onTogglePlaces: function () {
            dispatch({ type: 'UPDATE_UI', ui: Object.assign(Object.assign({}, context.ui), { placesVisible: !context.ui.placesVisible }) });
        },
        onLogin: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: (React.createElement(UserLoginIndex, { onDone: () => {
                        dialogDispatch({ type: 'REMOVE_NODE' });
                        actions.onSave();
                    } })),
            });
        },
        onSave: () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            if (context.tour.id) {
                yield updateTour(context.tour);
            }
            else {
                const persistedTour = yield saveTour(context.tour);
                if (persistedTour) {
                    const draft = createDraft(context.tour);
                    draft.id = persistedTour.id;
                    const finished = finishDraft(draft);
                    dispatch({ type: 'UPDATE_TOUR', tour: finished });
                    navigate(`/tour/${persistedTour.id}`, { replace: true });
                }
            }
            setLoading(false);
        }),
        openDetails: () => {
            var _a;
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: { type: 'tour', id: (_a = context.tour.id) !== null && _a !== void 0 ? _a : '' },
                node: React.createElement(TourDetail, { onSave: actions.onSave }),
            });
        },
        openShare: () => {
            var _a;
            modalDispatch({
                type: 'SET_NODE',
                title: 'Teilen',
                nodeType: { type: 'tour', id: (_a = context.tour.id) !== null && _a !== void 0 ? _a : '', subType: 'share' },
                node: React.createElement(TourShare, null),
            });
        },
        onAddDay: () => {
            dispatch({ type: 'ADD_GROUP' });
        },
        onSetTravelMode: function (travelMode) {
            const draft = createDraft(context.ui);
            draft.defaultTravelMode = travelMode;
            dispatch({ type: 'UPDATE_UI', ui: finishDraft(draft) });
        },
    };
    const onChangeTour = useCallback(debounce(actions.onSave, 1000, { maxWait: 30000 }), [context.user, context.tour]);
    useEffect(() => {
        if (!context.user || !context.tour.id || context.ui.inSync)
            return;
        onChangeTour();
        return () => onChangeTour.cancel();
    }, [context.user, context.tour]);
    return (React.createElement("div", { className: 'max-h-full' },
        React.createElement(FloatingTour, { maxHeight: props.maxHeight, title: (_a = context.tour.title) !== null && _a !== void 0 ? _a : '', isPublic: context.tour.isPublic, isLoading: context.ui.isLoading || isLoading, isTourVisible: context.ui.tourVisible, arePlacesVisible: context.ui.placesVisible, isShareVisible: context.tour.id != null, isLoggedIn: context.user != null, onEdit: actions.openDetails, onSave: actions.onSave, onToggleTour: actions.onToggleTour, onTogglePlaces: actions.onTogglePlaces, onAddDay: actions.onAddDay, onShare: actions.openShare, onLogin: actions.onLogin, travelModeOptions: tavelModeOptions, selectedTravelMode: context.ui.defaultTravelMode, onChangeTavelMode: actions.onSetTravelMode, onUseFreeAccountFeature: () => reminders.showReminder(ReminderKeys.SignUp) },
            React.createElement(GroupsIndex, null))));
};
