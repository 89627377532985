var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getAccessToken } from '../../lib/accessToken';
import axios from 'axios';
const baseUrl = process.env['AUTH_API_BASE_URL'];
// Login with E-Mail and Password
export const emailPasswordLogin = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${baseUrl}/login`;
    const headers = {
        'Content-Type': 'application/json',
    };
    const data = {
        email,
        password,
    };
    try {
        const response = yield axios.post(url, data, { headers });
        if (response.status == 200) {
            return response.data.accessToken;
        }
        return null;
    }
    catch (error) {
        console.error('Error requesting code:', error.response.data);
        return null;
    }
});
// Requesting a verification code
export const requestCode = (email) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${baseUrl}/requestCode`;
    const headers = {
        'Content-Type': 'application/json',
    };
    const data = {
        email,
    };
    try {
        const response = yield axios.post(url, data, { headers });
        return response.status == 200;
    }
    catch (error) {
        console.error('Error requesting code:', error.response.data);
    }
});
// Verifying the code
export const verifyCode = (email, verificationCode) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${baseUrl}/verify/code`;
    const headers = {
        'Content-Type': 'application/json',
    };
    const data = {
        email,
        verificationCode,
    };
    try {
        const response = yield axios.post(url, data, { headers });
        if (response.status == 200) {
            return response.data.accessToken;
        }
        return null;
    }
    catch (error) {
        console.error('Error verifying code:', error.response.data);
        return null;
    }
});
// Verifying the token
export const verifyToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const accessToken = getAccessToken();
    const url = `${baseUrl}/verify/token`;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };
    try {
        const response = yield axios.post(url, {}, { headers });
        if (response.status == 200) {
            return response.data.user;
        }
        return null;
    }
    catch (error) {
        console.error('Error verifying token:', error.response.data);
        return null;
    }
});
// Update User
export const updateUserRemote = (user) => __awaiter(void 0, void 0, void 0, function* () {
    const accessToken = getAccessToken();
    const url = `${baseUrl}/me/update`;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };
    try {
        const response = yield axios.post(url, user, { headers });
        if (response.status == 200) {
            return response.data;
        }
        return null;
    }
    catch (error) {
        console.error('Error verifying token:', error.response.data);
        return null;
    }
});
// Start checkout
export const startCheckout = (product, coupon) => __awaiter(void 0, void 0, void 0, function* () {
    const accessToken = getAccessToken();
    const url = `${baseUrl}/me/checkout?product=${product}&coupon=${coupon}`;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };
    try {
        const response = yield axios.post(url, {}, { headers });
        if (response.status == 200) {
            window.location.href = response.data.sessionUrl;
        }
        return null;
    }
    catch (error) {
        console.error('Error checkout start:', error.response.data);
        return null;
    }
});
// Start checkout
export const redirectToBilling = () => __awaiter(void 0, void 0, void 0, function* () {
    const accessToken = getAccessToken();
    const url = `${baseUrl}/me/billing`;
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };
    try {
        const response = yield axios.post(url, {}, { headers });
        if (response.status == 200) {
            window.location.href = response.data.sessionUrl;
        }
        return null;
    }
    catch (error) {
        console.error('Error checkout start:', error.response.data);
        return null;
    }
});
