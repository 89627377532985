import React from 'react';
import { Divider } from '../../../touring-ui/components/general/divider';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Button } from '../../../touring-ui/components/buttons';
import { AstronautToTheSky } from '../../../touring-ui/icons/_export';
import { Anchor } from '../../../touring-ui/components/anchor';
import { UserLoginIndex } from '../user/login';
import { useDialogDispatchCtx } from '../../atoms/dialog/context';
import { useTranslation } from 'react-i18next';
export const ReminderSignUp = () => {
    const dialogDispatch = useDialogDispatchCtx();
    const { t } = useTranslation();
    const actions = {
        onCTA: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: (React.createElement(UserLoginIndex, { onDone: () => {
                        dialogDispatch({ type: 'REMOVE_NODE' });
                    } })),
            });
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col items-center gap-8' },
            React.createElement("div", { className: 'w-full text-right relative overflow-hidden' },
                React.createElement("div", { className: 'bg-gradient-to-r from-indigo-600 to-blue-500 w-full place-content-center flex p-5 rounded-2xl' },
                    React.createElement(AstronautToTheSky, { className: 'w-48 ml-10' })),
                React.createElement("div", null,
                    React.createElement(Anchor, { className: 'text-white/70 text-xs absolute bottom-2 right-5', href: 'https://de.freepik.com/vektoren-kostenlos/niedlich-astronaut-halten-flagge-auf-mond-karikatur-vektor-symbol-abbildung-wissenschaft-technologie-symbol-begriff-freigestellt-premium-vector-flacher-cartoon-stil_22383468.htm#page=2&position=37&from_view=author&uuid=127878df-2551-4627-8465-bf0770ef103f', target: '_blank' }, "Image from catalyststuff on Freepik"))),
            React.createElement("div", { className: 'px-5' },
                React.createElement("div", { className: 'grid grid-cols-2 items-center gap-5' },
                    React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
                        React.createElement("div", { className: 'text-lg font-semibold' }, t('signupToUnlockFeaturesTitle')),
                        React.createElement("div", { className: 'text-balance ' }, t('signupToUnlockFeaturesMessage')),
                        React.createElement("div", { className: 'text-balance font-semibold' }, t('signupToUnlockFeaturesInfoMessage'))),
                    React.createElement(Flexbox, { direction: 'vertical', gap: 'sm', className: 'h-full' },
                        React.createElement("div", { className: 'border bg-slate-50 rounded-lg p-2 w-full h-full' },
                            React.createElement("p", { className: 'font-semibold' }, t('signupYourAdvantages')),
                            React.createElement("div", { className: 'py-2' },
                                React.createElement(Divider, null)),
                            React.createElement("ul", { className: 'list-disc pl-5 flex flex-col gap-2' },
                                React.createElement("li", null, t('signupAdvantage1')),
                                React.createElement("li", null, t('signupAdvantage2')),
                                React.createElement("li", null, t('signupAdvantage3')),
                                React.createElement("li", null, t('signupAdvantage4'))))))),
            React.createElement("div", { className: 'w-full' },
                React.createElement(Button, { type: 'primary', size: 'lg', className: 'w-full place-content-center', onClick: actions.onCTA }, t('signupNow'))))));
};
