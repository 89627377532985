import React, { useEffect, useRef, useState } from 'react';
import { useDialogCtx, useDialogDispatchCtx } from './context';
import { Dialog } from '@headlessui/react';
import { Card, CardBody } from '../../../touring-ui/components/cards';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Button } from '../../../touring-ui/components/buttons';
import { XIcon } from '../../../touring-ui/icons/_export';
import SimpleBar from 'simplebar-react';
import { useWindowDimensions } from '../../../../lib/window';
//onClose={() => dialogDispatch({ type: 'REMOVE_NODE' })}
export const DialogIndex = () => {
    const context = useDialogCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const [content, setContent] = useState(null);
    const ref = useRef(null);
    const { height } = useWindowDimensions();
    useEffect(() => {
        setContent(context.node);
    }, [context.node]);
    return (React.createElement(React.Fragment, null, context.node && (React.createElement(Dialog, { open: content != null, onClose: () => { }, className: "relative z-50 text-sm" },
        React.createElement("div", { className: "fixed inset-0 bg-black/70", "aria-hidden": "true" }),
        React.createElement("div", { className: "fixed inset-0 flex w-screen items-center justify-center p-4", ref: ref },
            React.createElement("div", { className: `w-full ${!context.maxWidth ? 'max-w-screen-sm' : ''}`, style: { maxWidth: context.maxWidth } },
                React.createElement(Card, { className: 'drop-shadow ts_animation_bounceIn' },
                    React.createElement("div", { className: 'rounded-tl-lg rounded-tr-lg  py-1 bg-slate-100 border-b border-slate-300 font-semibold dark:bg-neutral-800 dark:border-neutral-600 text-black dark:text-white' },
                        React.createElement(Flexbox, { direction: 'horizontal', position: 'center', className: 'px-6 py-2' },
                            React.createElement("div", { className: 'flex-grow' }, context.title),
                            React.createElement(Button, { size: 'xs', onClick: () => {
                                    dialogDispatch({ type: 'REMOVE_NODE' });
                                    if (context.onClose)
                                        context.onClose();
                                } },
                                React.createElement(XIcon, { className: 'w-4 h-4' })))),
                    React.createElement(SimpleBar, { style: { maxHeight: height - 100 }, autoHide: false },
                        React.createElement(CardBody, { className: '!py-5 !px-5' }, content)))))))));
};
