import React, { useState } from 'react';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { Label } from '../../../../touring-ui/components/form/label';
import { Input } from '../../../../touring-ui/components/form/input';
import { createDraft, finishDraft } from 'immer';
import { Anchor } from '../../../../touring-ui/components/anchor';
import { useTranslation } from 'react-i18next';
import { toAddressString } from '../../../../../lib/utils';
export const WaypointExtendedAddress = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { t } = useTranslation();
    const { waypoint, onUpdate } = props;
    const [isExtendedAddressOpen, openExtendedAddress] = useState(false);
    return (React.createElement(React.Fragment, null,
        isExtendedAddressOpen && (React.createElement("div", { className: 'grid grid-cols-2 gap-3 bg-slate-100 px-2 py-3 rounded-lg' },
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('latitude')),
                React.createElement(Input, { type: 'text', value: (_b = (_a = waypoint.location) === null || _a === void 0 ? void 0 : _a.coordinates[1]) !== null && _b !== void 0 ? _b : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.location || !draft.location.coordinates)
                            return;
                        draft.location.coordinates[1] = parseFloat(e.currentTarget.value);
                        onUpdate(finishDraft(draft));
                    } })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('longitude')),
                React.createElement(Input, { type: 'text', value: (_d = (_c = waypoint.location) === null || _c === void 0 ? void 0 : _c.coordinates[0]) !== null && _d !== void 0 ? _d : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.location)
                            return;
                        if (!draft.location || !draft.location.coordinates)
                            return;
                        draft.location.coordinates[0] = parseFloat(e.currentTarget.value);
                        onUpdate(finishDraft(draft));
                    } })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('streetName')),
                React.createElement(Input, { type: 'text', value: (_f = (_e = waypoint.address) === null || _e === void 0 ? void 0 : _e.streetName) !== null && _f !== void 0 ? _f : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.address)
                            return;
                        draft.address.streetName = e.currentTarget.value;
                        draft.address.freeformAddress = toAddressString(draft.address);
                        onUpdate(finishDraft(draft));
                    } })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('streetNumber')),
                React.createElement(Input, { type: 'text', value: (_h = (_g = waypoint.address) === null || _g === void 0 ? void 0 : _g.streetNumber) !== null && _h !== void 0 ? _h : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.address)
                            return;
                        draft.address.streetNumber = e.currentTarget.value;
                        draft.address.freeformAddress = toAddressString(draft.address);
                        onUpdate(finishDraft(draft));
                    } })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('postalCode')),
                React.createElement(Input, { type: 'text', value: (_k = (_j = waypoint.address) === null || _j === void 0 ? void 0 : _j.postalCode) !== null && _k !== void 0 ? _k : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.address)
                            return;
                        draft.address.postalCode = e.currentTarget.value;
                        draft.address.freeformAddress = toAddressString(draft.address);
                        onUpdate(finishDraft(draft));
                    } })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('municipality')),
                React.createElement(Input, { type: 'text', value: (_m = (_l = waypoint.address) === null || _l === void 0 ? void 0 : _l.municipality) !== null && _m !== void 0 ? _m : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.address)
                            return;
                        draft.address.municipality = e.currentTarget.value;
                        draft.address.freeformAddress = toAddressString(draft.address);
                        onUpdate(finishDraft(draft));
                    } })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('country')),
                React.createElement(Input, { type: 'text', value: (_p = (_o = waypoint.address) === null || _o === void 0 ? void 0 : _o.country) !== null && _p !== void 0 ? _p : '', onChange: (e) => {
                        const draft = createDraft(waypoint);
                        if (!draft.address)
                            return;
                        draft.address.country = e.currentTarget.value;
                        draft.address.freeformAddress = toAddressString(draft.address);
                        onUpdate(finishDraft(draft));
                    } })))),
        React.createElement(Anchor, { onClick: () => openExtendedAddress(!isExtendedAddressOpen) }, t('extendedAddressSettings'))));
};
