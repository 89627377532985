import React, { useEffect } from 'react';
import { useApplicationDispatchCtx } from '../../../../context/context';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Text } from '../../../touring-ui/components/typography/text';
import { MascotImage } from '../../../touring-ui/icons/_export';
import { useTranslation } from 'react-i18next';
export const LogoutPage = () => {
    const { t } = useTranslation();
    const dispatch = useApplicationDispatchCtx();
    useEffect(() => {
        dispatch({ type: 'REMOVE_USER' });
    }, []);
    return (React.createElement("div", { className: 'text-sm' },
        React.createElement(Flexbox, { direction: 'vertical', gap: 'xl', className: 'justify-center', position: 'center' },
            React.createElement(Text, null,
                React.createElement(MascotImage, { className: "w-24 h-24" })),
            React.createElement(Flexbox, { direction: 'vertical', gap: 'sm', className: 'justify-center', position: 'center' },
                React.createElement(Text, { type: 'title', className: 'font-semibold' }, t('signedOutSuccessful')),
                React.createElement(Text, null, t('seeYouNextTime'))))));
};
