import React, { useRef } from 'react';
import { useDnDCtx, useDndDispatchCtx } from './context';
export const DnDDraggable = (props) => {
    const ctx = useDnDCtx();
    const dispatch = useDndDispatchCtx();
    const draggableRef = useRef(null);
    const handleDragStart = () => {
        dispatch({ type: 'UPDATE', context: { active: { id: props.waypointId, groupId: props.groupId } } });
    };
    const onDragOver = (e) => {
        e.preventDefault();
        dispatch({
            type: 'UPDATE',
            context: { over: { type: props.type, id: props.waypointId, groupId: props.groupId } },
        });
    };
    const isOver = ctx.over.id == props.waypointId && ctx.over.type == props.type;
    const isActive = ctx.active.id == props.waypointId;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: draggableRef, draggable: true, onDragOver: onDragOver, onDragStart: handleDragStart, className: `${isActive ? 'opacity-50' : ''}` }, props.children),
        isOver && React.createElement("div", { className: "w-full bg-indigo-600 h-1" })));
};
