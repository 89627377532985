var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { memo, useEffect, useRef } from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import MapLibreGL from 'maplibre-gl';
import { WaypointMapMarker } from './marker';
import { useWaypointGlobalPosition } from '../../../../../context/effects/waypoints/useWaypointGlobalPosition';
import { useModalCtx, useModalDispatchCtx } from '../../../atoms/modal/context';
import { WaypointDetailIndex } from '../detail';
import { reverseGeocode } from '../../../../../api/tomtom';
import { createDraft, finishDraft } from 'immer';
export const WaypointItemMap = memo((props) => {
    var _a, _b, _c, _d;
    const { ui: { isReadOnly }, } = useApplicationCtx();
    const { groupIndex, waypointIndex, map } = props;
    const context = useApplicationCtx();
    const group = (_a = context.tour.groups[groupIndex]) !== null && _a !== void 0 ? _a : null;
    const waypoint = group ? group.waypoints[waypointIndex] : null;
    const location = waypoint ? waypoint.location : null;
    const markerRef = useRef(null);
    const markerElementRef = useRef(null);
    const modalDispatch = useModalDispatchCtx();
    const modalContext = useModalCtx();
    const globalPosition = waypoint ? useWaypointGlobalPosition(context, waypoint.id) : null;
    const dispatch = useApplicationDispatchCtx();
    useEffect(() => {
        var _a;
        if (((_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.type) == 'waypoint' && modalContext.meta.id == (waypoint === null || waypoint === void 0 ? void 0 : waypoint.id) && markerElementRef.current) {
            modalDispatch({ type: 'SET_MARKER_REF', markerRef: markerElementRef.current });
        }
    }, [modalContext.meta]);
    const initMarker = () => {
        var _a;
        if (markerRef.current == null || !waypoint || waypoint.location == null || !map)
            return null;
        const marker = new MapLibreGL.Marker({
            element: markerRef.current,
            draggable: ((_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.type) == 'waypoint' && modalContext.meta.id == (waypoint === null || waypoint === void 0 ? void 0 : waypoint.id),
            className: 'waypoint-marker',
        })
            .setLngLat(waypoint.location.coordinates)
            .addTo(map);
        marker.on('dragend', action.onDragEnd);
        return marker;
    };
    useEffect(() => {
        const marker = initMarker();
        markerElementRef.current = marker !== null && marker !== void 0 ? marker : null;
        return () => {
            markerElementRef.current = null;
            marker === null || marker === void 0 ? void 0 : marker.remove();
        };
    }, [location, map, group, isReadOnly]);
    useEffect(() => {
        var _a, _b;
        if (!markerElementRef.current)
            return;
        (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.setDraggable(((_b = modalContext.meta) === null || _b === void 0 ? void 0 : _b.type) == 'waypoint' && modalContext.meta.id == (waypoint === null || waypoint === void 0 ? void 0 : waypoint.id));
    }, [modalContext.meta]);
    const action = {
        onEdit: () => {
            if (!waypoint)
                return;
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: { type: 'waypoint', id: waypoint.id, additional: { groupIndex, waypointIndex } },
                node: React.createElement(WaypointDetailIndex, Object.assign({}, props)),
            });
        },
        onDragEnd: () => __awaiter(void 0, void 0, void 0, function* () {
            var _e;
            if (!markerElementRef.current || !waypoint)
                return;
            const location = {
                type: 'Point',
                coordinates: (_e = markerElementRef.current) === null || _e === void 0 ? void 0 : _e.getLngLat().toArray(),
            };
            const address = yield reverseGeocode(location);
            if (!address || !location)
                return;
            const draft = createDraft(waypoint);
            draft.address = address;
            draft.location = location;
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: groupIndex, waypoint: finishDraft(draft) });
        }),
    };
    return (React.createElement(React.Fragment, null, waypoint && group && (React.createElement("div", { className: "hidden" },
        React.createElement("div", { className: 'relative', ref: markerRef },
            React.createElement("div", { onClick: action.onEdit },
                React.createElement(WaypointMapMarker, { index: (_b = globalPosition === null || globalPosition === void 0 ? void 0 : globalPosition.index) !== null && _b !== void 0 ? _b : 0, indexInGroup: waypointIndex, isLastItem: (_c = globalPosition === null || globalPosition === void 0 ? void 0 : globalPosition.isLast) !== null && _c !== void 0 ? _c : false, isLastInGroup: context.tour.groups[groupIndex].waypoints.length - 1 == waypointIndex, group: group, waypoint: waypoint, loading: waypoint.lineString == null &&
                        ((_d = globalPosition === null || globalPosition === void 0 ? void 0 : globalPosition.index) !== null && _d !== void 0 ? _d : 0) > 0 &&
                        waypoint.config.travelMode != 'other' &&
                        waypoint.config.travelMode != 'unknownTravelMode' })))))));
});
