var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useApplicationCtx } from '../../../../context/context';
import { deleteTour, getTours } from '../../../../api/internal/tour';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '../../../touring-ui/components/typography/text';
import { Button } from '../../../touring-ui/components/buttons';
import { Alert } from '../../../touring-ui/components/alerts';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Divider } from '../../../touring-ui/components/general/divider';
import { Card, CardFooter, CardHeader } from '../../../touring-ui/components/cards';
import { Input } from '../../../touring-ui/components/form/input';
import { ActivityIndicator } from '../../../touring-ui/components/acitivityIndicator';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../touring-ui/components/tooltip';
import { Download01Icon, Edit03Icon, EyeIcon, Trash01Icon, } from '../../../touring-ui/icons/_export';
import { SummaryLengthInMeters, SummaryTravelTimeInSeconds } from '../route/summary';
import { Anchor } from '../../../touring-ui/components/anchor';
import { useDialogDispatchCtx } from '../../atoms/dialog/context';
import { TourDownload } from './private/download';
import { PurchaseBanner } from '../purchase/purchaseBanner';
export const MeToursPage = (props) => {
    var _a;
    const { user } = useApplicationCtx();
    const [loading, setLoading] = useState(true);
    const [tours, setTours] = useState([]);
    const [filter, setFilter] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dialogDispatch = useDialogDispatchCtx();
    const location = useLocation();
    const filteredTours = (_a = tours.filter((t) => { var _a; return (_a = t.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(filter.toLowerCase()); })) !== null && _a !== void 0 ? _a : [];
    const reload = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        getTours().then((tours) => {
            setTours(tours);
            setLoading(false);
        });
    });
    useEffect(() => {
        if (!user)
            return;
        reload();
    }, [user]);
    const actions = {
        onRemove: (id) => {
            if (!id)
                return;
            if (confirm(t('deleteConfirm'))) {
                deleteTour(id).then(() => {
                    reload();
                    if (location.pathname.includes(id)) {
                        navigate('/');
                    }
                });
            }
        },
        onDownload: (id, title) => {
            if (!id || !title)
                return;
            dialogDispatch({ type: 'SET_NODE', node: React.createElement(TourDownload, { id: id, title: title }), maxWidth: 450 });
        },
    };
    return (React.createElement("div", { className: 'text-sm' },
        React.createElement(Flexbox, { direction: 'vertical', gap: 'xl' },
            tours.length == 0 && !loading && (React.createElement(Flexbox, { direction: 'vertical', className: 'w-full' },
                React.createElement(Alert, { type: 'warning' },
                    t('noToursFound'),
                    " ",
                    React.createElement("br", null),
                    !props.phoneMode && (React.createElement(NavLink, { to: "/", className: "underline underline-offset-2" }, t('createTour')))))),
            React.createElement(Card, null,
                React.createElement(CardHeader, null,
                    React.createElement(Input, { className: 'font-normal', placeholder: t('filterTitle'), onChange: (e) => setFilter(e.currentTarget.value) })),
                filteredTours.length > 0 && (React.createElement(Flexbox, { direction: 'vertical', gap: 'none', className: 'divide-y dark:divide-neutral-800' }, filteredTours.map((tour) => {
                    var _a, _b, _c, _d;
                    return (React.createElement("div", { key: tour.id, className: 'grid grid-cols-1 gap-1 md:grid-cols-2 p-2 hover:bg-slate-50 dark:hover:bg-neutral-900 place-content-start items-center' },
                        React.createElement(Flexbox, { direction: 'vertical', gap: 'none' },
                            React.createElement(Anchor, { onClick: () => {
                                    if (props.phoneMode) {
                                        navigate(`/tour/${tour.id}/guide`, { preventScrollReset: false });
                                        return;
                                    }
                                    dialogDispatch({ type: 'REMOVE_NODE' });
                                    navigate(`/tour/${tour.id}`);
                                }, type: 'body', className: `font-semibold cursor-pointer hover:underline underline-offset-2 hover:opacity-70 ${props.phoneMode ? 'text-lg' : ''}` }, tour.title),
                            React.createElement(Flexbox, { direction: 'horizontal' },
                                React.createElement(SummaryTravelTimeInSeconds, { className: 'text-slate-400', travelTimeInSeconds: (_b = (_a = tour.summary) === null || _a === void 0 ? void 0 : _a.travelTimeInSeconds) !== null && _b !== void 0 ? _b : 0 }),
                                React.createElement(SummaryLengthInMeters, { className: 'text-slate-400', lengthInMeters: (_d = (_c = tour.summary) === null || _c === void 0 ? void 0 : _c.lengthInMeters) !== null && _d !== void 0 ? _d : 0 }))),
                        React.createElement(Flexbox, { direction: 'horizontal', className: 'place-content-start md:place-content-end flex-grow' },
                            !props.phoneMode && (React.createElement(Tooltip, { node: t('showTour') },
                                React.createElement(Button, { size: !props.phoneMode ? 'sm' : 'md', type: 'primary', onClick: () => {
                                        dialogDispatch({ type: 'REMOVE_NODE' });
                                        navigate(`/tour/${tour.id}`);
                                    } },
                                    React.createElement(Edit03Icon, { className: 'w-4 h-4' })))),
                            React.createElement(Tooltip, { node: t('tourGuide') },
                                React.createElement(Button, { size: !props.phoneMode ? 'sm' : 'md', type: 'secondary', onClick: () => {
                                        dialogDispatch({ type: 'REMOVE_NODE' });
                                        navigate(`/tour/${tour.id}/guide`);
                                    } },
                                    React.createElement(EyeIcon, { className: 'w-4 h-4' }))),
                            React.createElement(Tooltip, { node: t('downloadGpx') },
                                React.createElement(Button, { size: !props.phoneMode ? 'sm' : 'md', type: 'secondary', onClick: () => actions.onDownload(tour.id, tour.title) },
                                    React.createElement(Download01Icon, { className: 'w-4 h-4' }))),
                            React.createElement(Tooltip, { node: t('delete') },
                                React.createElement(Button, { size: !props.phoneMode ? 'sm' : 'md', type: 'secondary', onClick: () => actions.onRemove(tour.id) },
                                    React.createElement(Trash01Icon, { className: 'w-4 h-4' }))))));
                }))),
                React.createElement(CardFooter, null,
                    loading && React.createElement(ActivityIndicator, null, "Laden"),
                    !loading && (React.createElement(Text, { type: 'footnote' }, t('xOfxTours', { from: filteredTours.length, to: tours.length })))))),
        user && !user.subscription && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'py-2' },
                React.createElement(Divider, null)),
            React.createElement(PurchaseBanner, null)))));
};
