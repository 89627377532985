import React, { useEffect, useState } from 'react';
import { Flexbox } from '../flexbox';
import { nanoid } from 'nanoid';
import { Text } from '../typography/text';
export const TabTitle = (props) => React.createElement("div", null, props.children);
export const Tab = (props) => React.createElement(React.Fragment, null, props.children);
export const Tabs = (props) => {
    const [selected, setSelected] = useState(0);
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
        React.createElement(Flexbox, { className: 'rounded-md border p-2 border-slate-300 dark:border-neutral-700 bg-slate-100 dark:bg-neutral-900 text-sm', nowrap: true, gap: 'md' }, props.children &&
            props.children.map((child, index) => {
                useEffect(() => {
                    if (index != 0)
                        return;
                    setSelected(index);
                }, []);
                return (React.createElement("div", { key: nanoid(), onClick: () => {
                        setSelected(index);
                    }, className: `font-semibold flex basis-full items-center justify-center p-2 rounded-md cursor-pointer ${selected == index
                        ? 'bg-indigo-600 text-white'
                        : 'bg-slate-200 text-black dark:text-white dark:bg-neutral-800 dark:hover:dark:bg-neutral-700 hover:bg-white'}` }, child.props.title));
            })),
        React.createElement("div", { className: 'max-h-full overflow-auto' }, props.children[selected].props.children)));
};
export const TabsPreview = () => (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'mb-8' },
    React.createElement("a", { id: 'tabs' }),
    React.createElement(Text, { type: 'subheadline' }, "Tabs"),
    React.createElement("hr", null),
    React.createElement(Tabs, null,
        React.createElement(Tab, { title: 'Test' }, "Content 1"),
        React.createElement(Tab, { title: 'Test' }, "Content 2"),
        React.createElement(Tab, { title: 'Test' }, "Content 3"))));
