import * as turf from '@turf/turf';
// Define a class with a method for calculating flight time
export class OtherTransportationTimeCalculator {
    static generateSineWave(startCoords, endCoords) {
        return turf.lineString([startCoords, endCoords]);
    }
    static calculate(startCoords, endCoords) {
        // Calculate distance between the two locations in kilometers
        const distanceInKilometers = turf.distance(turf.point(startCoords), turf.point(endCoords), {
            units: 'kilometers',
        });
        const distanceInMeters = turf.convertLength(distanceInKilometers, 'kilometers', 'meters');
        // Calculate the approximate flight time in hours
        const lineString = turf.lineString([startCoords, endCoords]);
        return {
            coordinates: lineString.geometry.coordinates,
            properties: {
                arrivalTime: 0,
                departureTime: 0,
                lengthInMeters: Math.ceil(distanceInMeters),
                travelTimeInSeconds: 0,
                breakInSeconds: 0,
            },
            type: 'LineString',
        };
    }
}
