import React from 'react';
import { useApplicationDispatchCtx } from '../../../../../../context/context';
import { WaypointGenericSearchAddress } from '../../../waypoint/generic/searchAddress';
import { newWaypoint } from '../../../../../../context/slices/waypoints';
import { useDialogDispatchCtx } from '../../../../atoms/dialog/context';
import { Text } from '../../../../../touring-ui/components/typography/text';
export const AddOrigin = () => {
    const dispatch = useApplicationDispatchCtx();
    const dialogDispatch = useDialogDispatchCtx();
    return (React.createElement("div", { className: 'w-[360px] flex flex-col gap-3' },
        React.createElement(Text, { type: 'title', className: 'font-semibold' }, "Startpunkt setzen"),
        React.createElement(Text, { type: 'body' }, "Gib deine Adresse oder den Ort ein von dem aus du diese Tour startest."),
        React.createElement(WaypointGenericSearchAddress, { type: 'form', selected: undefined, renderSelected: () => '', onSetAddress: (selected) => {
                if (selected.location) {
                    dispatch({
                        type: 'ADD_WAYPOINT',
                        groupIndex: 0,
                        atIndex: 0,
                        newWaypoint: newWaypoint({
                            travelMode: 'car',
                            address: selected.address,
                            location: selected.location,
                        }),
                    });
                    dialogDispatch({ type: 'REMOVE_NODE' });
                }
            } })));
};
