import { nanoid } from 'nanoid';
import { createDraft, finishDraft, original, produce } from 'immer';
import { Middleware } from '../middlewares/middleware';
import { findGroupById } from './groups';
import { lastWaypointOfPreviousGroup } from '../effects/groups/useOrigin';
export const findPreviousWaypoint = (tour, currentWaypointId) => {
    const allWaypoints = tour.groups.flatMap((group, groupIndex) => {
        return group.waypoints.flatMap((waypoint, waypointIndex) => {
            return { id: waypoint.id, groupIndex, waypointIndex };
        });
    });
    const currentWaypointIndex = allWaypoints.findIndex((wp) => wp.id == currentWaypointId);
    if (currentWaypointIndex == -1)
        return null;
    const previousIndex = currentWaypointIndex - 1;
    if (previousIndex == -1)
        return null;
    return allWaypoints[previousIndex];
};
export const findNextWaypoint = (tour, currentWaypointId) => {
    const allWaypoints = tour.groups.flatMap((group, groupIndex) => {
        return group.waypoints.flatMap((waypoint, waypointIndex) => {
            return { id: waypoint.id, groupIndex, waypointIndex };
        });
    });
    const currentWaypointIndex = allWaypoints.findIndex((wp) => wp.id == currentWaypointId);
    if (currentWaypointIndex == -1)
        return null;
    const nextIndex = currentWaypointIndex + 1;
    if (nextIndex > allWaypoints.length)
        return null;
    return allWaypoints[nextIndex];
};
export const getAllWaypoints = (tour) => {
    const tmp = [];
    tour.groups.forEach((group, groupIndex) => group.waypoints.forEach((waypoint, waypointIndex) => {
        tmp.push({
            type: tmp.length == 0 ? 'origin' : 'destination',
            id: waypoint.id,
            index: tmp.length,
            waypointIndex: waypointIndex,
            groupIndex: groupIndex,
            location: waypoint.location,
            isLastInGroup: group.waypoints.length - 1 == waypointIndex,
            previousWaypoint: findPreviousWaypoint(tour, waypoint.id),
            nextWaypoint: findNextWaypoint(tour, waypoint.id),
        });
    }));
    return tmp;
};
export const getVisibleAllWaypoints = (context, hideInActiveGroups) => {
    const tmp = [];
    const isOneCollapsed = context.tour.groups.findIndex((group) => group.isCollapsed);
    if (hideInActiveGroups && isOneCollapsed != -1) {
        const waypointDetails = lastWaypointOfPreviousGroup(isOneCollapsed, context.tour);
        if (waypointDetails) {
            const origin = context.tour.groups[waypointDetails.groupIndex].waypoints[waypointDetails.waypointIndex];
            tmp.push({
                type: 'origin',
                id: origin.id,
                index: tmp.length,
                waypointIndex: waypointDetails.waypointIndex,
                groupIndex: waypointDetails.groupIndex,
                location: origin.location,
                isLastInGroup: false,
                previousWaypoint: findPreviousWaypoint(context.tour, origin.id),
                nextWaypoint: findNextWaypoint(context.tour, origin.id),
            });
        }
    }
    context.tour.groups.forEach((group, groupIndex) => {
        if (hideInActiveGroups && isOneCollapsed != -1) {
            if (group.isCollapsed) {
                group.waypoints.forEach((waypoint, waypointIndex) => {
                    tmp.push({
                        type: group.waypoints.length - 1 == waypointIndex ? 'origin' : 'destination',
                        id: waypoint.id,
                        index: tmp.length,
                        waypointIndex: waypointIndex,
                        groupIndex: groupIndex,
                        location: waypoint.location,
                        isLastInGroup: group.waypoints.length - 1 == waypointIndex,
                        previousWaypoint: findPreviousWaypoint(context.tour, waypoint.id),
                        nextWaypoint: findNextWaypoint(context.tour, waypoint.id),
                    });
                });
            }
        }
        else {
            group.waypoints.forEach((waypoint, waypointIndex) => {
                tmp.push({
                    type: group.waypoints.length - 1 == waypointIndex ? 'origin' : 'destination',
                    id: waypoint.id,
                    index: tmp.length,
                    waypointIndex: waypointIndex,
                    groupIndex: groupIndex,
                    location: waypoint.location,
                    isLastInGroup: group.waypoints.length - 1 == waypointIndex,
                    previousWaypoint: findPreviousWaypoint(context.tour, waypoint.id),
                    nextWaypoint: findNextWaypoint(context.tour, waypoint.id),
                });
            });
        }
    });
    return tmp;
};
export const findWaypointById = (waypoints, id) => waypoints.find((waypoint) => waypoint.id === id);
export const findWaypointIndexById = (waypoints, id) => waypoints.findIndex((waypoint) => waypoint.id === id);
/**
 * Adds a new waypoint to a specified group within the application context.
 *
 * @param appContext - The application context object containing the current state of the tour.
 * @param action - An action object specifying the details of the waypoint addition.
 * @returns An updated `AppContext` object reflecting the changes made to the tour by adding the new waypoint.
 */
export const addWaypoint = (appContext, action) => {
    // Extracting relevant properties from the action object for easier reference
    const { atIndex, groupIndex, afterWaypointId, beforeWaypointId, newWaypoint, shouldReplace } = action;
    // Finding the target group based on the provided groupId
    const targetGroup = appContext.tour.groups[groupIndex];
    // If the target group is not found, return the current appContext unchanged
    if (!targetGroup)
        return appContext;
    // Creating a draft of the target group to enable modifications
    const draftTargetGroup = createDraft(targetGroup);
    // Determining the index at which the new waypoint should be inserted
    let insertionIndex = atIndex !== null && atIndex !== void 0 ? atIndex : draftTargetGroup.waypoints.length;
    let originalIndex = null;
    let replace = false;
    // Adjusting insertion index based on afterWaypointId or beforeWaypointId if provided
    if (afterWaypointId !== undefined) {
        originalIndex = draftTargetGroup.waypoints.findIndex((waypoint) => waypoint.id === afterWaypointId);
        replace = draftTargetGroup.waypoints[originalIndex].location == null;
        insertionIndex = originalIndex + 1;
    }
    else if (beforeWaypointId !== undefined) {
        originalIndex = draftTargetGroup.waypoints.findIndex((waypoint) => waypoint.id === beforeWaypointId);
        replace = draftTargetGroup.waypoints[originalIndex].location == null;
        insertionIndex = draftTargetGroup.waypoints.findIndex((waypoint) => waypoint.id === beforeWaypointId);
    }
    if (shouldReplace) {
        draftTargetGroup.waypoints[insertionIndex] = newWaypoint;
    }
    else if (replace && originalIndex) {
        draftTargetGroup.waypoints[originalIndex] = newWaypoint;
    }
    else {
        // Inserting the new waypoint into the waypoints array of the draft group
        draftTargetGroup.waypoints.splice(insertionIndex, 0, newWaypoint);
    }
    // Creating a draft of the entire tour to facilitate updating the target group
    const draftTour = createDraft(appContext.tour);
    // Updating the target group within the draft tour
    draftTour.groups[groupIndex] = finishDraft(draftTargetGroup);
    // Applying middleware logic (assumed to be defined elsewhere)
    Middleware(draftTour, original(draftTour));
    // Producing the final appContext with the updated tour and UI state
    return produce(appContext, (draft) => {
        draft.ui.inSync = false;
        draft.tour = finishDraft(draftTour);
    });
};
/**
 * Adds a waypoint to a new position and removes it from the original position within the application context.
 *
 * @param appContext - The application context object containing the current state of the tour.
 * @param action - An action object specifying the details of adding and removing the waypoint.
 * @returns An updated `AppContext` object reflecting the changes made to the tour.
 */
export const addAndRemoveWaypoint = (appContext, action) => {
    // Creating drafts for the tour
    const draftTour = createDraft(appContext.tour);
    // Finding the original group and the target group based on provided groupIds
    const originalGroup = findGroupById(draftTour.groups, action.originalGroupId);
    const targetGroup = findGroupById(draftTour.groups, action.groupId);
    // Finding the current index of the waypoint within the original group
    const currentIndex = findWaypointIndexById(originalGroup.waypoints, action.waypointId);
    // Determining the new index for the waypoint within the target group
    let newIndex;
    if (action.overGroup) {
        // If overGroup is true and addAtIndex is 0, place the waypoint at the end of the target group
        newIndex = targetGroup.isCollapsed ? 0 : targetGroup.waypoints.length;
    }
    else {
        if (action.addAtIndex == undefined || action.addAtIndex == -1)
            return appContext;
        // Otherwise, adjust the new index based on the specified addAtIndex value
        newIndex = currentIndex > action.addAtIndex ? action.addAtIndex + 1 : action.addAtIndex;
    }
    // Removing the waypoint from the original group and inserting it into the target group at the new index
    const movedWaypoint = originalGroup.waypoints.splice(currentIndex, 1)[0];
    targetGroup.waypoints.splice(newIndex, 0, movedWaypoint);
    // Applying middleware logic (assumed to be defined elsewhere)
    Middleware(draftTour, original(draftTour));
    const updatedTour = finishDraft(draftTour);
    // Producing the final appContext with the updated tour and UI state
    return produce(appContext, (draft) => {
        draft.ui.inSync = false;
        draft.tour = updatedTour;
    });
};
/**
 * Updates a waypoint within a group in the application context.
 *
 * @param appContext - The application context object containing the current state of the tour.
 * @param action - An action object specifying the details of updating the waypoint.
 * @returns An updated `AppContext` object reflecting the changes made to the tour.
 */
export const updateWaypoint = (appContext, action) => {
    // Creating a draft of the tour
    const draftTour = createDraft(appContext.tour);
    // Destructuring waypoint properties for easier reference
    const { id, type, address, location, notes, config, lineString, summary, title, image, website, tripadvisor, timezone, } = action.waypoint;
    // Finding the target group and waypoint based on the provided groupIndex and waypoint id
    const group = draftTour.groups[action.groupIndex];
    const waypoint = group === null || group === void 0 ? void 0 : group.waypoints.find((wp) => wp.id === id);
    // If the group or waypoint is not found, return the current appContext unchanged
    if (!group || !waypoint)
        return appContext;
    // Updating the properties of the waypoint
    waypoint.type = type;
    waypoint.address = address;
    waypoint.location = location;
    waypoint.notes = notes;
    waypoint.config = config;
    waypoint.lineString = lineString;
    waypoint.summary = summary;
    waypoint.title = title;
    waypoint.image = image;
    waypoint.website = website;
    waypoint.tripadvisor = tripadvisor;
    waypoint.timezone = timezone;
    // Applying middleware logic if withoutMiddleware is not explicitly set to true
    if (!action.withoutMiddleware) {
        Middleware(draftTour, original(draftTour));
    }
    // Creating the final updated tour
    const updatedTour = finishDraft(draftTour);
    // Producing the final appContext with the updated tour and UI state
    return produce(appContext, (draft) => {
        draft.ui.inSync = false;
        draft.tour = updatedTour;
    });
};
/**
 * Removes a waypoint from a group in the application context.
 *
 * @param appContext - The application context object containing the current state of the tour.
 * @param action - An action object specifying the details of removing the waypoint.
 * @returns An updated `AppContext` object reflecting the changes made to the tour.
 */
export const removeWaypoint = (appContext, action) => {
    // Creating a draft of the tour
    const draftTour = createDraft(appContext.tour);
    const originalTour = original(draftTour);
    // Finding the target group based on the provided groupIndex
    const group = draftTour.groups[action.details.groupIndex];
    // If the group is not found, return the current appContext unchanged
    if (!group)
        return appContext;
    // If the waypoint is found, remove it from the waypoints array
    if (action.details.waypointIndex !== -1) {
        group.waypoints.splice(action.details.waypointIndex, 1);
    }
    // Applying middleware logic
    Middleware(draftTour, originalTour);
    const updatedTour = finishDraft(draftTour);
    // Producing the final appContext with the updated tour and UI state
    return produce(appContext, (draft) => {
        draft.ui.inSync = false;
        draft.tour = updatedTour;
    });
};
/**
 * Creates a new waypoint with the specified properties.
 *
 * @param props - Properties for creating the new waypoint.
 * @returns A new `Waypoint` object.
 */
export const newWaypoint = (props) => {
    // Destructuring properties for easier reference
    const { travelMode, title, type, address, location, summary, image, description, notes } = props;
    // Creating and returning a new waypoint
    return {
        id: nanoid(),
        title: title !== null && title !== void 0 ? title : undefined,
        image: image !== null && image !== void 0 ? image : undefined,
        description: description !== null && description !== void 0 ? description : undefined,
        notes: notes !== null && notes !== void 0 ? notes : undefined,
        type: type !== null && type !== void 0 ? type : 'default',
        address: address !== null && address !== void 0 ? address : null,
        location: location !== null && location !== void 0 ? location : null,
        config: {
            travelMode: travelMode,
            routeType: 'fastest',
            avoid: [],
            hilliness: null,
            windingness: null,
        },
        lineString: null,
        summary: summary !== null && summary !== void 0 ? summary : {
            arrivalTime: 0,
            departureTime: 0,
            lengthInMeters: 0,
            travelTimeInSeconds: 0,
            breakInSeconds: 0,
        },
    };
};
