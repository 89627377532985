import * as turf from '@turf/turf';
import { calculateBBoxForWaypoints } from './utils';
const zoomOrFlyTo = (locations, eventType) => {
    if (locations.length === 1) {
        const location = locations[0];
        if (location && location.location) {
            const buffer = turf.buffer(location.location, 100, { units: 'meters' });
            const bbox = turf.bbox(buffer);
            window.dispatchEvent(new CustomEvent(eventType, { detail: { bbox, pitch: 0 } }));
        }
    }
    else if (locations.length >= 2) {
        const points = locations.flatMap((loc, i) => {
            if (!loc)
                return [];
            if (loc.lineString) {
                return i === 0 ? [] : loc.lineString.coordinates || [];
            }
            else if (loc.location) {
                return [loc.location.coordinates];
            }
            return [];
        });
        const bbox = calculateBBoxForWaypoints(points);
        if (bbox) {
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent(eventType, { detail: { bbox, pitch: 0 } }));
            }, 200);
        }
    }
};
export const zoomTo = (waypoints) => {
    zoomOrFlyTo(waypoints, 'zoomMapTo');
};
export const zoomToPlaces = (places) => {
    zoomOrFlyTo(places, 'zoomMapTo');
};
export const flyTo = (waypoints) => {
    zoomOrFlyTo(waypoints, 'flyMapTo');
};
export const flyToPlaces = (places) => {
    zoomOrFlyTo(places, 'zoomMapTo');
};
