import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon } from '../../../../../touring-ui/icons/_export';
import { TravelModeConfig } from '../../../waypoint/config/travelMode';
export const AddWaypoint = (props) => {
    const { t } = useTranslation();
    const [isMouseOver, setMouseOver] = useState(false);
    return (React.createElement("div", { id: `add-waypoint-button-${props.index}`, className: `${props.isFloating
            ? 'absolute left-0 right-0 pointer-events-none'
            : 'bg-slate-100 border-t border-slate-300 dark:bg-neutral-900 dark:border-neutral-700 py-2'} flex justify-center`, style: { bottom: '-12.5px' } },
        React.createElement("div", { className: 'z-10 flex flex-row gap-2' },
            React.createElement("button", { className: 'px-3 bg-slate-50 border border-slate-300 dark:bg-amber-300 dark:border-amber-500 rounded-xl pointer-events-auto text-sm', onClick: props.onAdd, onMouseEnter: () => setMouseOver(true), onMouseLeave: () => {
                    setMouseOver(false);
                } },
                React.createElement("div", { className: 'flex items-center justify-center h-6' },
                    !isMouseOver && React.createElement(PlusCircleIcon, { className: 'w-4 h-4 animate__animated animate__bounceIn' }),
                    isMouseOver && (React.createElement("div", { className: 'ts_animation_bounceIn' }, props.index == 0 ? t('insertStart') : t('insertDestination'))))),
            props.isFloating && props.nextWaypoint && React.createElement(TravelModeConfig, { waypointDetails: props.nextWaypoint }))));
};
