import React from 'react';
import { downloadTourById } from '../../../../../api/internal/tour';
import { Button } from '../../../../touring-ui/components/buttons';
import { useTranslation } from 'react-i18next';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { OsmAnd } from '../../../../touring-ui/icons/_export';
import { Anchor } from '../../../../touring-ui/components/anchor';
export const TourDownload = (props) => {
    const { t } = useTranslation();
    const { id, title } = props;
    const actions = {
        onDownload: () => {
            if (!id || !title)
                return;
            downloadTourById(id, title);
        },
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'font-semibold pb-3 text-center' }, title),
        React.createElement(Button, { type: 'primary', size: 'lg', onClick: actions.onDownload, className: 'w-full place-content-center' }, t('startDownload')),
        React.createElement("div", { className: 'py-5' },
            React.createElement(Divider, null)),
        React.createElement("div", { className: 'flex flex-row gap-5 items-center bg-slate-100 p-3 rounded-lg' },
            React.createElement("img", { src: OsmAnd, className: 'w-24 rounded-3xl border' }),
            React.createElement("div", { className: 'flex flex-col gap-3 place' },
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'font-semibold' }, t('downloadOsmAnd')),
                    React.createElement("div", null, t('downloadOsmAndDescription'))),
                React.createElement("div", { className: 'flex flex-row gap-3' },
                    React.createElement(Anchor, { target: '_blank', href: 'https://apps.apple.com/us/app/osmand-maps-travel-navigate/id934850257' }, "iOS"),
                    React.createElement(Anchor, { target: '_blank', href: 'https://play.google.com/store/apps/details?id=net.osmand' }, "Android"))))));
};
