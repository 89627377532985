import React from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { useModalCtx, useModalDispatchCtx } from '../../../atoms/modal/context';
import { WaypointDetailIndex } from '../detail';
import { useWaypointGlobalPosition } from '../../../../../context/effects/waypoints/useWaypointGlobalPosition';
import { createDraft, finishDraft } from 'immer';
import { FloatingWaypoint } from '../../tour/floating/waypoint';
import { findNextWaypoint, newWaypoint } from '../../../../../context/slices/waypoints';
import { zoomTo } from '../../../../../lib/zoom';
import { useTranslation } from 'react-i18next';
export const WaypointListItem = (props) => {
    var _a, _b, _c;
    const { groupIndex, waypointIndex } = props;
    const context = useApplicationCtx();
    const group = context.tour.groups[groupIndex];
    const waypoint = group.waypoints[waypointIndex];
    const nextWaypoint = findNextWaypoint(context.tour, waypoint.id);
    const globalPosition = (_a = useWaypointGlobalPosition(context, waypoint.id)) !== null && _a !== void 0 ? _a : 0;
    const { t } = useTranslation();
    const dispatch = useApplicationDispatchCtx();
    const modalContext = useModalCtx();
    const modalDispatch = useModalDispatchCtx();
    const isSelected = ((_b = modalContext.meta) === null || _b === void 0 ? void 0 : _b.type) == 'waypoint' &&
        ((_c = modalContext.meta) === null || _c === void 0 ? void 0 : _c.id) == waypoint.id &&
        modalContext.meta.subType == null;
    const actions = {
        onAdd: () => {
            dispatch({
                type: 'ADD_WAYPOINT',
                groupIndex: props.groupIndex,
                afterWaypointId: waypoint.id,
                newWaypoint: newWaypoint({ travelMode: context.ui.defaultTravelMode }),
            });
        },
        onDelete: () => {
            if (isSelected)
                modalDispatch({ type: 'REMOVE_NODE' });
            dispatch({
                type: 'REMOVE_WAYPOINT',
                details: { groupIndex: props.groupIndex, waypointIndex: props.waypointIndex },
            });
        },
        onEdit: () => {
            if (!waypoint.location)
                return;
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: { type: 'waypoint', id: waypoint.id, additional: { groupIndex, waypointIndex } },
                node: React.createElement(WaypointDetailIndex, { groupIndex: props.groupIndex, waypointIndex: props.waypointIndex }),
            });
        },
        onSetAddress: (selected) => {
            var _a, _b;
            const draft = createDraft(waypoint);
            draft.address = selected.address;
            draft.location = selected.location;
            draft.title = (_b = (_a = selected.poi) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : undefined;
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(draft) });
        },
        zoomToWaypoint: () => {
            zoomTo([waypoint]);
        },
    };
    return (React.createElement(FloatingWaypoint, { globalIndex: globalPosition.index, globalIsLast: globalPosition.isLast, isLast: context.tour.groups[groupIndex].waypoints.length - 1 == waypointIndex, waypointIndex: waypointIndex, groupIndex: groupIndex, onAdd: actions.onAdd, onEdit: actions.onEdit, onDelete: actions.onDelete, onZoom: actions.zoomToWaypoint, onSetAddress: actions.onSetAddress, nextWaypoint: nextWaypoint }));
};
