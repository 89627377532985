import React, { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useApplicationDispatchCtx } from '../context/context';
import { DialogContext } from '../ui/components/atoms/dialog/context';
import { DialogIndex } from '../ui/components/atoms/dialog';
import { useTranslation } from 'react-i18next';
export const PageIndex = (props) => {
    const userLoader = useLoaderData();
    const dispatch = useApplicationDispatchCtx();
    const { i18n } = useTranslation();
    useEffect(() => {
        if (!userLoader)
            return;
        if (!userLoader.user)
            return;
        i18n.changeLanguage(userLoader.user.language);
    }, [userLoader]);
    useEffect(() => {
        if (process.env.ENVIRONMENT == 'production') {
            window.addEventListener('beforeunload', (event) => {
                event.returnValue = `Are you sure you want to leave?`;
            });
        }
        if (!userLoader || !userLoader.user)
            return;
        dispatch({ type: 'SET_USER', user: userLoader.user });
    }, []);
    return (React.createElement(DialogContext, null,
        props.children,
        " ",
        React.createElement(DialogIndex, null)));
};
