var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getTourById } from '../../api/internal/tour';
import { verifyToken } from '../../api/internal/auth';
import { redirect } from 'react-router-dom';
export function loaderUserPrivate(props) {
    return __awaiter(this, void 0, void 0, function* () {
        const user = yield verifyToken();
        if (!user) {
            const params = new URLSearchParams();
            params.set('from', new URL(props.request.url).pathname);
            return redirect('/login?' + params.toString());
        }
        return { user: user !== null && user !== void 0 ? user : null };
    });
}
export function loaderPublic(props) {
    return __awaiter(this, void 0, void 0, function* () {
        const user = yield verifyToken();
        return { user };
    });
}
export function loaderPrivate(props) {
    return __awaiter(this, void 0, void 0, function* () {
        const { id } = props.params;
        const user = yield verifyToken();
        if (!user) {
            const params = new URLSearchParams();
            params.set('from', new URL(props.request.url).pathname);
            return redirect('/login?' + params.toString());
        }
        let tour = null;
        if (id) {
            tour = yield getTourById(id);
        }
        return { tour, user: user };
    });
}
