import { addWaypoint, updateWaypoint, removeWaypoint, addAndRemoveWaypoint, } from './slices/waypoints';
import { addGroup, updateGroup, removeGroup } from './slices/groups';
import { updateTour } from './slices/tour';
import { removeUser, setUser, updateUser } from './slices/user';
import { updateUi } from './slices/ui';
export const appReducer = (ctx, action) => {
    switch (action.type) {
        case 'UPDATE_TOUR':
            const { context, patches, inversePatches } = updateTour(ctx, action);
            return context;
        case 'ADD_WAYPOINT':
            return addWaypoint(ctx, action);
        case 'ADD_AND_REMOVE_WAYPOINT':
            return addAndRemoveWaypoint(ctx, action);
        case 'UPDATE_WAYPOINT':
            return updateWaypoint(ctx, action);
        case 'REMOVE_WAYPOINT':
            return removeWaypoint(ctx, action);
        case 'ADD_GROUP':
            return addGroup(ctx, action);
        case 'UPDATE_GROUP':
            return updateGroup(ctx, action);
        case 'REMOVE_GROUP':
            return removeGroup(ctx, action);
        case 'SET_USER':
            return setUser(ctx, action);
        case 'UPDATE_USER':
            return updateUser(ctx, action);
        case 'REMOVE_USER':
            return removeUser(ctx);
        case 'UPDATE_UI':
            return updateUi(ctx, action);
        default:
            return ctx;
    }
};
