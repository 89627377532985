import React from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { createDraft, finishDraft } from 'immer';
import { Label } from '../../../../touring-ui/components/form/label';
import { Input } from '../../../../touring-ui/components/form/input';
import { InputInfo } from '../../../../touring-ui/components/form/info';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { CardBody, CardFooter } from '../../../../touring-ui/components/cards';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { Textarea } from '../../../../touring-ui/components/form/textarea';
import { useTranslation } from 'react-i18next';
import { TourShare } from './share';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { Alert } from '../../../../touring-ui/components/alerts';
import { Button } from '../../../../touring-ui/components/buttons';
import { ReminderKeys, useReminderCoordinator } from '../../reminders';
export const TourDetail = (props) => {
    const { t } = useTranslation();
    const { tour } = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const context = useApplicationCtx();
    const reminders = useReminderCoordinator();
    const actions = {
        onChangeTitle: (e) => {
            const draft = createDraft(tour);
            draft.title = e.currentTarget.value;
            dispatch({ type: 'UPDATE_TOUR', tour: finishDraft(draft), inSync: false, withoutMiddleWare: true });
        },
        onChangeImage: (e) => {
            const draft = createDraft(tour);
            draft.image = e.currentTarget.value;
            dispatch({ type: 'UPDATE_TOUR', tour: finishDraft(draft), inSync: false, withoutMiddleWare: true });
        },
        onChangeDescription: (e) => {
            const draft = createDraft(tour);
            draft.description = e.currentTarget.value;
            dispatch({ type: 'UPDATE_TOUR', tour: finishDraft(draft), inSync: false, withoutMiddleWare: true });
        },
        onChangePublic: (isEnabled) => {
            const draft = createDraft(tour);
            draft.isPublic = isEnabled;
            dispatch({ type: 'UPDATE_TOUR', tour: finishDraft(draft), inSync: false, withoutMiddleWare: true });
        },
    };
    return (React.createElement("div", { className: 'text-sm' },
        React.createElement(CardBody, null,
            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'text-sm' },
                !context.user && (React.createElement(React.Fragment, null,
                    React.createElement(Alert, { type: 'info' },
                        t('signInForTourGuide'),
                        React.createElement(Button, { type: 'secondary', onClick: () => reminders.showReminder(ReminderKeys.SignUp), className: 'mt-2' }, t('signupToUnlockFeaturesMessage'))),
                    React.createElement("div", { className: 'py-3' },
                        React.createElement(Divider, null)))),
                !tour.id && context.user && (React.createElement(React.Fragment, null,
                    React.createElement(Alert, { type: 'info' }, t('saveTourForTourGuide')),
                    React.createElement("div", { className: 'py-3' },
                        React.createElement(Divider, null)))),
                tour.id && (React.createElement(React.Fragment, null,
                    React.createElement(TourShare, null),
                    React.createElement(Divider, null))),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('title')),
                    React.createElement(InputInfo, null, t('nameYourTour')),
                    React.createElement(Input, { type: 'text', name: 'title', value: tour.title, onChange: actions.onChangeTitle })),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('image')),
                    React.createElement(InputInfo, null, t('addImageGuide')),
                    React.createElement(Input, { type: 'text', name: 'image', value: tour.image, onChange: actions.onChangeImage })),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('description')),
                    React.createElement(InputInfo, null, t('describeYourTour')),
                    React.createElement(Textarea, { name: 'description', value: tour.description, onChange: actions.onChangeDescription })))),
        React.createElement(CardFooter, null, "\u00A0")));
};
