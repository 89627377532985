import React from 'react';
import { useDnDCtx, useDndDispatchCtx } from './context';
import { useOrigin } from '../../../../context/effects/groups/useOrigin';
import { useApplicationCtx } from '../../../../context/context';
export const DNDDropable = (props) => {
    const context = useApplicationCtx();
    const ctx = useDnDCtx();
    const dispatch = useDndDispatchCtx();
    const { origin } = useOrigin(props.groupIndex);
    const originWaypoint = origin ? context.tour.groups[origin === null || origin === void 0 ? void 0 : origin.groupIndex].waypoints[origin.waypointIndex] : null;
    const onDragOver = (e) => {
        e.preventDefault();
        dispatch({
            type: 'UPDATE',
            context: { over: { type: 'group', id: originWaypoint ? originWaypoint.id : null, groupId: props.groupId } },
        });
    };
    const isActive = ctx.over.type == 'group' && ctx.over.groupId == props.groupId;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { onDragOver: onDragOver }, props.children),
        isActive && React.createElement("div", { className: "w-full bg-indigo-600 h-1" })));
};
