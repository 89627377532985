import React from 'react';
import { useTranslation } from 'react-i18next';
import { startCheckout } from '../../../../api/internal/auth';
import { Product } from '../../../../interfaces/products';
import { CrownIcon } from '../../../touring-ui/icons/_export';
import { Anchor } from '../../../touring-ui/components/anchor';
import { Divider } from '../../../touring-ui/components/general/divider';
import { Alert } from '../../../touring-ui/components/alerts';
import { Flexbox } from '../../../touring-ui/components/flexbox';
export const ReminderPurchaseScreen = (props) => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col gap-5 place-items-center' },
            props.reason && (React.createElement(Alert, { type: 'error', className: 'w-full' }, t(props.reason))),
            React.createElement("div", { className: 'w-full relative overflow-hidden' },
                React.createElement("div", { className: 'bg-gradient-to-r from-indigo-600 to-blue-500 w-full place-content-center flex p-5 rounded-2xl' },
                    React.createElement(CrownIcon, { className: 'w-36' })),
                React.createElement("div", null,
                    React.createElement(Anchor, { className: 'text-white/30 text-xs absolute bottom-2 left-5 right-5 text-center', href: 'https://de.freepik.com/vektoren-kostenlos/floating-crown-cartoon-vector-icon-illustration-finance-object-icon-concept-isolated-premium-vector_25726209.htm#page=7&position=11&from_view=author&uuid=127878df-2551-4627-8465-bf0770ef103f', target: '_blank' }, "Image from catalyststuff on Freepik"))),
            React.createElement("div", { className: 'w-full' },
                React.createElement(Divider, null)),
            React.createElement("div", { className: 'grid grid-cols-2 items-center gap-5' },
                React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
                    React.createElement("div", { className: 'text-lg font-semibold' }, t('purchaseScreenTitle')),
                    React.createElement("div", { className: 'text-balance ' }, t('purchaseScreenDescription'))),
                React.createElement(Flexbox, { direction: 'vertical', gap: 'sm', className: 'h-full' },
                    React.createElement("div", { className: 'border bg-slate-50 rounded-lg p-2 w-full h-full' },
                        React.createElement("p", { className: 'font-semibold' }, t('purchaseYourAdvantages')),
                        React.createElement("div", { className: 'py-2' },
                            React.createElement(Divider, null)),
                        React.createElement("ul", { className: 'list-disc pl-5 flex flex-col gap-2' },
                            React.createElement("li", null, t('purchaseAdvantage1')),
                            React.createElement("li", null, t('purchaseAdvantage2')),
                            React.createElement("li", null, t('purchaseAdvantage3')))))),
            React.createElement("div", { className: 'w-full' },
                React.createElement(Divider, null)),
            React.createElement("div", { className: 'grid grid-cols-2 gap-2' },
                React.createElement("button", { onClick: () => startCheckout(Product.MONTHLY), className: "w-full rounded-lg border-2 border-indigo-600 text-indigo-600 px-10 py-2 text-sm font-semibold bg-indigo-600 hover:bg-indigo-500 text-white" }, t('subscribeMonthlyTier1')),
                React.createElement("button", { onClick: () => startCheckout(Product.YEARLY), className: "w-full rounded-lg border-2 border-black px-10 py-2 text-sm font-semibold bg-black hover:bg-black/90 text-white" }, t('subscribeYearlyTier1'))),
            React.createElement("div", { className: 'w-full' },
                React.createElement(Divider, null)),
            React.createElement("div", { className: 'flex gap-5 opacity-75' },
                React.createElement(Anchor, { href: 'https://touring.studio/terms-of-service/', target: '_blank' }, t('termsAndConditions')),
                React.createElement(Anchor, { href: 'https://touring.studio/privacy-policy/', target: '_blank' }, t('privacyPolicy'))))));
};
