import Cookies from 'js-cookie';
import { produce } from 'immer';
import { setAccessToken } from '../../lib/accessToken';
export const setUser = (ctx, action) => {
    if (action.accessToken) {
        setAccessToken(action.accessToken);
    }
    return produce(ctx, (draft) => {
        draft.user = action.user;
    });
};
export const updateUser = (ctx, action) => {
    return produce(ctx, (draft) => {
        if (!draft.user)
            return;
        const { user } = action;
        if (user.email) {
            draft.user.email = user.email;
        }
        if (user.firstname) {
            draft.user.firstname = user.firstname;
        }
        if (user.lastname) {
            draft.user.lastname = user.lastname;
        }
        if (user.language) {
            draft.user.language = user.language;
        }
        if (user.address) {
            draft.user.address = user.address;
        }
        if (user.location) {
            draft.user.location = user.location;
        }
    });
};
export const removeUser = (ctx) => {
    Cookies.remove('accessToken');
    return produce(ctx, (draft) => {
        draft.user = null;
        draft.ui.placesVisible = false;
    });
};
