import React, { useEffect } from 'react';
import { toAddressString } from '../../../../lib/utils';
import { ActivityIndicator } from '../../../touring-ui/components/acitivityIndicator';
import { Alert } from '../../../touring-ui/components/alerts';
import { Card } from '../../../touring-ui/components/cards';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
export const Bookingcom = (props) => {
    return React.createElement(BookingWidget, Object.assign({}, props));
};
const BookingWidget = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        const BookingAffiliateWidget = new window.Booking.AffiliateWidget({
            iframeSettings: {
                selector: 'bookingAffiliateWidget_cc2677e4-87f3-4438-ae49-e20f15c60de6',
                responsive: true,
            },
            widgetSettings: {
                ss: toAddressString(props.address),
                latitude: props.location.coordinates[1],
                longitude: props.location.coordinates[0],
                zoom: 13,
            },
        });
    }, []);
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
        React.createElement(Alert, { type: 'info' }, t('hotelAdditionNote')),
        React.createElement(Card, null,
            React.createElement("div", { className: 'overflow-hidden', style: { aspectRatio: '4/3.05' } },
                React.createElement("div", { style: { transform: 'translateY(-96px)' } },
                    React.createElement("div", { id: "bookingAffiliateWidget_cc2677e4-87f3-4438-ae49-e20f15c60de6" },
                        React.createElement(ActivityIndicator, null)))))));
};
