var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImage } from '../../../../../api/internal/places';
import { useTranslation } from 'react-i18next';
const ImageUpload = (props) => {
    const place = props.place;
    const { t } = useTranslation();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const onDrop = useCallback((acceptedFiles) => __awaiter(void 0, void 0, void 0, function* () {
        if (!place._id)
            return;
        setLoading(true);
        const placeImage = yield uploadImage(place._id, acceptedFiles, setUploadProgress);
        if (placeImage) {
            props.onChange(placeImage);
        }
        setLoading(false);
    }), []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.webp'],
        },
        maxFiles: 1,
        onDrop,
    });
    return (React.createElement("div", null,
        React.createElement("div", Object.assign({}, getRootProps(), { className: 'border-2 border-dashed border-slate-200 rounded-lg p-5 text-center cursor-pointer text-slate-600' }),
            React.createElement("input", Object.assign({}, getInputProps())),
            isDragActive ? React.createElement("p", null, t('dropImageHere')) : React.createElement("p", null, t('dropImage'))),
        loading && (React.createElement("div", { className: 'h-2 w-full bg-slate-300 rounded-full my-2 relative' },
            React.createElement("div", { className: 'h-2 w-full bg-indigo-600 rounded-full indent-0 absolute', style: { width: `${uploadProgress.toFixed(2)}%` } })))));
};
export default ImageUpload;
