import React, { createContext, useContext, useRef } from 'react';
import { produce } from 'immer';
import { useImmerReducer } from 'use-immer';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../context/context';
import { findWaypointIndexById } from '../../../../context/slices/waypoints';
import { findGroupById } from '../../../../context/slices/groups';
const update = (ctx, action) => {
    const { active, over } = action.context;
    if (active)
        ctx.active = active;
    if (over) {
        ctx.over = over;
    }
};
const reset = (ctx) => {
    ctx.active = {
        id: null,
        groupId: null,
    };
    ctx.over = {
        type: null,
        id: null,
        groupId: null,
    };
};
const DnDInitialContext = {
    active: {
        id: null,
        groupId: null,
    },
    over: {
        type: null,
        id: null,
        groupId: null,
    },
};
const dndReducer = produce((ctx, action) => {
    switch (action.type) {
        case 'UPDATE':
            update(ctx, action);
            break;
        case 'RESET':
            reset(ctx);
            break;
    }
});
const DnDContextProvider = createContext(DnDInitialContext);
const DndDispatchCtx = createContext((value) => { });
export const useDnDCtx = () => useContext(DnDContextProvider);
export const useDndDispatchCtx = () => useContext(DndDispatchCtx);
export const DnDContext = (props) => {
    const [ctx, dispatch] = useImmerReducer(dndReducer, DnDInitialContext);
    const applicationCtxDispatch = useApplicationDispatchCtx();
    const context = useApplicationCtx();
    const wrapperRef = useRef(null);
    const handleDragEnter = () => { };
    const handleDragLeave = (e) => {
        if (wrapperRef == null)
            return;
        if (wrapperRef.current == null)
            return;
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const divRect = wrapperRef.current.getBoundingClientRect();
        const isOverDiv = mouseX >= divRect.left && mouseX <= divRect.right && mouseY >= divRect.top && mouseY <= divRect.bottom;
        if (!isOverDiv) {
            dispatch({
                type: 'UPDATE',
                context: { over: { type: null, id: null, groupId: null } },
            });
        }
    };
    const handleDragEnd = (e) => {
        e.preventDefault();
        if (!ctx.over.groupId || !ctx.active.groupId || !ctx.active.id) {
            dispatch({ type: 'RESET' });
            return;
        }
        const group = findGroupById(context.tour.groups, ctx.over.groupId);
        const waypointIndex = ctx.over.type == 'waypoint' && ctx.over.id
            ? findWaypointIndexById(group === null || group === void 0 ? void 0 : group.waypoints, ctx.over.id)
            : undefined;
        const dispatchData = {
            type: 'ADD_AND_REMOVE_WAYPOINT',
            addAtIndex: ctx.over.type == 'group' ? 0 : waypointIndex,
            waypointId: ctx.active.id,
            originalGroupId: ctx.active.groupId,
            groupId: ctx.over.groupId,
            overGroup: ctx.over.type == 'group',
        };
        applicationCtxDispatch(dispatchData);
        dispatch({ type: 'RESET' });
    };
    return (React.createElement(DnDContextProvider.Provider, { value: ctx },
        React.createElement(DndDispatchCtx.Provider, { value: dispatch },
            React.createElement("div", { ref: wrapperRef, className: "divide-y dark:divide-neutral-700", onDragEnter: handleDragEnter, onDragLeave: handleDragLeave, onDragEnd: handleDragEnd }, props.children))));
};
