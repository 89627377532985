var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useApplicationCtx } from '../../../../../context/context';
import { Bookingcom } from '../../bookingcom';
import { ThirdPartyPlacesIndex } from '../../thirdPartyPlaces';
import { Tab, Tabs } from '../../../../touring-ui/components/tabs';
import { Alert } from '../../../../touring-ui/components/alerts';
import { useModalDispatchCtx } from '../../../atoms/modal/context';
import { Button } from '../../../../touring-ui/components/buttons';
import { useTranslation } from 'react-i18next';
export const WaypointActivitiesIndex = (props) => {
    const { t } = useTranslation();
    const context = useApplicationCtx();
    const modalDispatch = useModalDispatchCtx();
    if (!props.location) {
        modalDispatch({ type: 'REMOVE_NODE' });
        return;
    }
    const actions = {
        onLogin: () => __awaiter(void 0, void 0, void 0, function* () {
            window.dispatchEvent(new CustomEvent('showLogin'));
        }),
        onShowPurchaseScreen: () => {
            window.dispatchEvent(new CustomEvent('showPurchaseScreen'));
        },
    };
    return (React.createElement("div", null,
        React.createElement(Tabs, null,
            React.createElement(Tab, { title: t('hotel', { count: 2 }) }, props.location && props.address && (React.createElement(Bookingcom, { location: props.location, address: props.address }))),
            React.createElement(Tab, { title: t('restaurant', { count: 2 }) },
                !context.user && (React.createElement(Alert, { type: 'info' },
                    t('signInForNearbyRestaurants'),
                    React.createElement(Button, { type: 'secondary', onClick: actions.onLogin, className: 'mt-2' }, t('signupNow')))),
                context.user && location && (React.createElement(ThirdPartyPlacesIndex, { title: t('nearbyRestaurants'), location: props.location, type: 'restaurant' }))),
            React.createElement(Tab, { title: t('touristAttraction', { count: 2 }) },
                !context.user && (React.createElement(Alert, { type: 'info' },
                    t('signInForNearbyActivities'),
                    React.createElement(Button, { type: 'secondary', onClick: actions.onLogin, className: 'mt-2' }, t('signupNow')))),
                context.user && location && (React.createElement(ThirdPartyPlacesIndex, { title: t('nearbyAttractions'), location: props.location, type: 'tourist_attraction' }))))));
};
