// localdb.ts
import Dexie from 'dexie';
export var SystemKeys;
(function (SystemKeys) {
    SystemKeys["Onboarding"] = "onboarding";
})(SystemKeys || (SystemKeys = {}));
export class TouringStudioDexie extends Dexie {
    constructor() {
        super('touring_studio');
        this.version(1).stores({
            system: '++id, key, value', // Primary key and indexed props
        });
        this.version(2).stores({
            reminder: '++id, key, timestamp', // Primary key and indexed props
        });
        this.version(3).stores({
            tour: 'id', // Primary key and indexed props
        });
        this.version(4).stores({});
    }
}
export const db = new TouringStudioDexie();
