import React from 'react';
import { addAlpha } from '../../../../../lib/color';
import { SummaryArrivalTime, SummaryDepartureTime, SummaryLengthInMeters, SummaryTravelTimeInSeconds, } from '../../route/summary';
export const GroupSummary = (props) => {
    var _a, _b;
    return (React.createElement("div", { className: "flex flex-row items-center gap-1" },
        React.createElement("div", { className: "relative flex-none rounded-full py-0 px-2", style: { backgroundColor: addAlpha(props.color, 0.5) } },
            React.createElement(SummaryDepartureTime, { departureTime: props.summary.departureTime, hideLabel: true, className: 'text-white font-semibold', timezone: (_a = props.origin) === null || _a === void 0 ? void 0 : _a.timezone })),
        React.createElement("div", { className: "relative h-1 flex-grow rounded-full bg-white" },
            React.createElement("div", { className: "absolute -top-[7px] left-1/2 -translate-x-1/2 transform whitespace-nowrap rounded-full bg-white px-2 py-0 text-slate-600" },
                React.createElement(SummaryLengthInMeters, { lengthInMeters: props.summary.lengthInMeters, hideLabel: true, className: 'text-slate-600' }))),
        React.createElement("div", { className: "relative h-1 flex-grow rounded-full bg-white" },
            React.createElement("div", { className: "absolute -top-[7px] left-1/2 -translate-x-1/2 transform whitespace-nowrap rounded-full bg-white px-2 py- text-slate-600" },
                React.createElement(SummaryTravelTimeInSeconds, { travelTimeInSeconds: props.summary.travelTimeInSeconds, hideLabel: true, className: 'text-slate-600' }))),
        React.createElement("div", { className: "relative flex-none rounded-full py-0 px-2", style: { backgroundColor: addAlpha(props.color, 0.5) } },
            React.createElement(SummaryArrivalTime, { arrivalTime: props.summary.arrivalTime, hideLabel: true, className: 'text-white font-semibold', timezone: (_b = props.origin) === null || _b === void 0 ? void 0 : _b.timezone }))));
};
