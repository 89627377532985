import React from 'react';
import { ActivityIndicator } from '../../../../touring-ui/components/acitivityIndicator';
import { useTranslation } from 'react-i18next';
export const UserLoginLoading = (props) => {
    if (!props.loading)
        return React.createElement(React.Fragment, null);
    const { t } = useTranslation();
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement(ActivityIndicator, null, t('pleaseWaitDataIsBeingLoaded'))));
};
