import React, { useEffect } from 'react';
import { Text } from '../../../../touring-ui/components/typography/text';
import { Button } from '../../../../touring-ui/components/buttons';
import { Code02Icon, HelpCircleIcon, ListIcon, LogoLightMode, LogOut01Icon, Menu01Icon, Moon01Icon, PlusCircleIcon, SunIcon, UserCircleIcon, } from '../../../../touring-ui/icons/_export';
import { Popover } from '@headlessui/react';
import { useFloating } from '@floating-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { createDraft, finishDraft } from 'immer';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { useDialogDispatchCtx } from '../../../atoms/dialog/context';
import { UserLoginIndex } from '../../user/login';
import { useTranslation } from 'react-i18next';
import { initNewTour } from '../../../../../interfaces/tour';
import { EventTrackingTypes, track } from '../../../../../lib/eventTracking';
import { MeToursPage } from '../../tour/tours';
import { ProfilePage } from '../../user/profile';
import { LogoutPage } from '../../user/logout';
const LightDarkSwitch = (props) => {
    const { ui } = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const { t } = useTranslation();
    useEffect(() => {
        if (ui.isDarkModeActive) {
            document.body.classList.add('dark');
        }
        else {
            document.body.classList.remove('dark');
        }
    }, [ui.isDarkModeActive]);
    return (React.createElement(Button, { className: props.className, onClick: () => {
            const draft = createDraft(ui);
            draft.isDarkModeActive = !ui.isDarkModeActive;
            dispatch({ type: 'UPDATE_UI', ui: finishDraft(draft) });
        } },
        !ui.isDarkModeActive && (React.createElement(React.Fragment, null,
            React.createElement(Moon01Icon, { className: 'w-4 h-4' }),
            React.createElement("div", { className: 'inline lg:hidden' }, t('darkMode')))),
        ui.isDarkModeActive && (React.createElement(React.Fragment, null,
            React.createElement(SunIcon, { className: 'w-4 h-4' }),
            React.createElement("div", { className: 'inline lg:hidden' }, t('lightMode'))))));
};
const DebugSwitch = (props) => {
    const key = 'ts.debug';
    const actions = {
        debugOn: !!localStorage.getItem(key),
        setDebug: (value) => {
            value ? localStorage.setItem(key, '1') : localStorage.removeItem(key);
            location.reload();
        },
    };
    return (React.createElement(React.Fragment, null, process.env.ENVIRONMENT == 'development' && (React.createElement("li", null,
        React.createElement(Button, { className: props.className, onClick: () => actions.setDebug(!actions.debugOn) },
            !actions.debugOn && (React.createElement(React.Fragment, null,
                React.createElement(Code02Icon, { className: 'w-4 h-4' }),
                React.createElement("div", { className: 'inline ' }, "Debug: OFF"))),
            actions.debugOn && (React.createElement(React.Fragment, null,
                React.createElement(Code02Icon, { className: 'w-4 h-4' }),
                React.createElement("div", { className: 'inline ' }, "Debug: ON"))))))));
};
export const NavbarItemsPrivate = (props) => {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const { t } = i18n;
    const dialogDispatch = useDialogDispatchCtx();
    const actions = {
        onShowTours: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(MeToursPage, null),
                title: t('tour', { count: 2 }),
            });
        },
        onShowProfile: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(ProfilePage, null),
                title: t('editProfile'),
            });
        },
        onShowLogout: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(LogoutPage, null),
                onClose: () => {
                    navigate('/');
                    dialogDispatch({ type: 'REMOVE_NODE' });
                },
            });
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("li", null,
            React.createElement(Button, { onClick: actions.onShowProfile, size: 'xs', className: 'py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal' },
                React.createElement(UserCircleIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote', className: 'font-semibold' },
                    ' ',
                    props.user.firstname ? t('helloName', { name: props.user.firstname }) : t('helloYou')))),
        !props.isMobileOrTablet && (React.createElement("li", null,
            React.createElement(Button, { className: `${location.pathname == '/'
                    ? 'bg-slate-200 dark:bg-neutral-700 block lg:rounded-lg hover:opacity-75'
                    : ''} py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal`, size: 'xs', onClick: () => {
                    if (location.pathname == '/') {
                        dispatch({
                            type: 'UPDATE_TOUR',
                            tour: initNewTour(i18n.t, context.user, context.ui.defaultTravelMode),
                            withoutMiddleWare: true,
                        });
                    }
                    else {
                        navigate('/');
                    }
                } },
                React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('createTour'))))),
        React.createElement("li", null,
            React.createElement(Button, { onClick: !props.isMobileOrTablet ? actions.onShowTours : () => navigate('/'), size: 'xs', className: 'py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal' },
                React.createElement(ListIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('myTours')))),
        React.createElement("li", null,
            React.createElement(Button, { onClick: actions.onShowLogout, size: 'xs', className: 'py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal' },
                React.createElement(LogOut01Icon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('logout')))),
        !props.isMobileOrTablet && (location.pathname == '/' || location.pathname.includes('/tour/')) && (React.createElement("li", { onClick: () => track(EventTrackingTypes.Support) },
            React.createElement(Button, { className: 'py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal', onClick: () => window.dispatchEvent(new CustomEvent('showTutorial')) },
                React.createElement(HelpCircleIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('support'))))),
        React.createElement(DebugSwitch, { className: props.className })));
};
export const NavbarItemsPublic = (props) => {
    const i18n = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dialogDispatch = useDialogDispatchCtx();
    const { t } = i18n;
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const actions = {
        onLogin: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: (React.createElement(UserLoginIndex, { onDone: () => {
                        dialogDispatch({ type: 'REMOVE_NODE' });
                    } })),
            });
        },
    };
    return (React.createElement(React.Fragment, null,
        !props.isMobileOrTablet && (React.createElement("li", null,
            React.createElement(Button, { className: `${location.pathname == '/'
                    ? 'bg-slate-200 dark:bg-neutral-700 block lg:rounded-lg hover:opacity-75'
                    : ''} py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal`, size: 'xs', onClick: () => {
                    if (location.pathname == '/') {
                        dispatch({
                            type: 'UPDATE_TOUR',
                            tour: initNewTour(i18n.t, context.user, context.ui.defaultTravelMode),
                            withoutMiddleWare: true,
                        });
                    }
                    else {
                        navigate('/');
                    }
                } },
                React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('createTour'))))),
        React.createElement("li", { className: 'flex' },
            React.createElement(Button, { className: `${location.pathname == '/login'
                    ? 'bg-slate-200 dark:bg-neutral-700 block lg:rounded-lg hover:opacity-75'
                    : ''} py-3 lg:py-1 px-3 text-black dark:text-white block w-full lg:w-auto`, size: 'xs', onClick: () => {
                    if (location.pathname != '/logout' && location.pathname != '/login') {
                        actions.onLogin();
                    }
                    else {
                        navigate('/login');
                    }
                } },
                React.createElement(UserCircleIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('login')))),
        !props.isMobileOrTablet && (location.pathname == '/' || location.pathname.includes('/tour/')) && (React.createElement("li", { onClick: () => track(EventTrackingTypes.Support) },
            React.createElement(Button, { className: 'py-3 lg:py-1 px-3 text-black dark:text-white w-full lg:w-auto !font-normal', onClick: () => window.dispatchEvent(new CustomEvent('showTutorial')) },
                React.createElement(HelpCircleIcon, { className: 'w-4 h-4' }),
                React.createElement(Text, { type: 'footnote' }, t('support'))))),
        React.createElement(DebugSwitch, { className: props.className })));
};
export const DesktopNavigation = (props) => (React.createElement("ul", { className: 'hidden lg:flex items-center' },
    props.user && React.createElement(NavbarItemsPrivate, { user: props.user }),
    !props.user && React.createElement(NavbarItemsPublic, null)));
export const MobileNavigationItems = (props) => (React.createElement("ul", { className: 'shadow-xl w-72 bg-white dark:bg-neutral-950 rounded-md font-semibold border border-slate-200 dark:border-neutral-700 divide-y divide-slate-200 dark:divide-neutral-700 flex flex-col' },
    props.user && (React.createElement(NavbarItemsPrivate, { user: props.user, className: '!py-3 w-full', isMobileOrTablet: props.isMobileOrTablet })),
    !props.user && React.createElement(NavbarItemsPublic, { className: '!py-3 w-full', isMobileOrTablet: props.isMobileOrTablet })));
export const MobileNavigation = (props) => {
    const { refs, floatingStyles } = useFloating({
        placement: 'bottom-end',
    });
    return (React.createElement(Popover, { className: 'relative visible lg:hidden' },
        React.createElement(Popover.Button, { ref: refs.setReference, className: 'outline-none' },
            React.createElement(Text, null,
                React.createElement(Menu01Icon, { className: 'w-4 h-4' }))),
        React.createElement(Popover.Panel, { className: "absolute z-10", ref: refs.setFloating, style: floatingStyles },
            React.createElement(MobileNavigationItems, { user: props.user, isMobileOrTablet: props.isMobileOrTablet }))));
};
export const Navbar = (props) => {
    const navigate = useNavigate();
    const context = useApplicationCtx();
    return (React.createElement("div", { className: `${props.notSticky ? '' : 'sticky top-0 z-50'} w-full bg-slate-50 dark:bg-neutral-900 border-b border-neutral-200 dark:border-neutral-800 text-sm shadow-lg` },
        React.createElement("nav", { className: 'flex items-center h-14 px-5' },
            React.createElement("div", { className: 'flex flex-row gap-1', onClick: () => (window.location.href = 'https://touring.studio') },
                React.createElement(Text, null,
                    React.createElement(LogoLightMode, { className: 'w-6 h-6' })),
                React.createElement(Text, { className: 'text-md font-semibold cursor-pointer relative', onClick: () => navigate('/') }, "touring.studio")),
            props.searchComponent && (React.createElement("div", { className: 'ml-8 hidden lg:block w-[350px]' }, props.searchComponent)),
            React.createElement("div", { className: 'flex-grow' }),
            React.createElement(DesktopNavigation, { user: context.user }),
            React.createElement(MobileNavigation, { user: context.user, isMobileOrTablet: props.isMobileOrTablet }))));
};
export const NavbarPreview = () => {
    const user = {
        _id: '6582ce179aeb38a13c1ea24b',
        email: 'info@appbackstube.com',
        firstname: 'Andreas',
        isCurated: true,
        lastname: 'Gruber',
        image: '//www.gravatar.com/avatar/9328f83f6d5b1703547e6e71383a2204',
        language: 'de',
        address: {
            streetName: 'Mela-Spira-Straße',
            streetNumber: '40',
            postalCode: '8054',
            municipality: 'Graz',
            country: 'Österreich',
            freeformAddress: 'Mela-Spira-Straße 40, 8054 Graz',
        },
        location: {
            type: 'Point',
            coordinates: [15.393518, 47.03328],
        },
        hasPassword: true,
    };
    return (React.createElement(Flexbox, { direction: 'vertical', className: 'mb-8', gap: 'lg' },
        React.createElement("a", { id: 'navbar' }),
        React.createElement(Text, { type: 'subheadline' }, "Navbar"),
        React.createElement("hr", null),
        React.createElement(Flexbox, null,
            React.createElement("div", { className: 'border border-slate-200 dark:border-slate-950 w-full' },
                React.createElement(Navbar, { notSticky: true }))),
        React.createElement(Flexbox, null,
            React.createElement("div", { className: 'border border-slate-200 dark:border-slate-950 w-full' },
                React.createElement(Navbar, { notSticky: true }))),
        React.createElement(Flexbox, { gap: 'lg' },
            React.createElement(MobileNavigationItems, { user: null }),
            React.createElement(MobileNavigationItems, { user: user }))));
};
