var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { Label } from '../../../../touring-ui/components/form/label';
import { Input } from '../../../../touring-ui/components/form/input';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
import { CardBody } from '../../../../touring-ui/components/cards';
import { deletePlaceImage, getPlaceImageURL, updatePlace } from '../../../../../api/internal/places';
import { Button } from '../../../../touring-ui/components/buttons';
import { Alert } from '../../../../touring-ui/components/alerts';
import { useDialogDispatchCtx } from '../../../atoms/dialog/context';
import { Textarea } from '../../../../touring-ui/components/form/textarea';
import { useModalCtx, useModalDispatchCtx } from '../../../atoms/modal/context';
import { WaypointGenericSearchAddress } from '../../waypoint/generic/searchAddress';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { PlaceDetailIndex } from './detail';
import { Switch } from '../../../../touring-ui/components/form/switch';
import { createDraft, finishDraft } from 'immer';
import { Anchor } from '../../../../touring-ui/components/anchor';
import { parseTripadvisorIDFromURL, toAddressString } from '../../../../../lib/utils';
import { ListBox } from '../../../../touring-ui/components/form/listbox';
import { usePlacesDispatchCtx } from '../context';
import ImageUpload from './imageUpload';
import { Debug } from '../../debug';
export const PlaceEdit = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    const dialogDispatch = useDialogDispatchCtx();
    const modalDispatch = useModalDispatchCtx();
    const modalCtx = useModalCtx();
    const placeDispatch = usePlacesDispatchCtx();
    const { t } = useTranslation();
    const [place, setPlace] = useState(props.place);
    const placeRef = useRef(props.place);
    const [isExtendedAddressOpen, openExtendedAddress] = useState(false);
    const tripadvisor = (_a = place.relations) === null || _a === void 0 ? void 0 : _a.find((item) => item.name == 'tripadvisor');
    const imageReference = (_b = place.relations) === null || _b === void 0 ? void 0 : _b.find((item) => item.name == 'image');
    useEffect(() => {
        placeRef.current = place;
    }, [place]);
    const actions = {
        onBack: (place) => {
            var _a;
            modalDispatch({
                type: 'SET_NODE',
                title: t('place'),
                nodeType: { type: 'place', id: (_a = props.place._id) !== null && _a !== void 0 ? _a : '' },
                node: React.createElement(PlaceDetailIndex, { place: place }),
            });
        },
        onUpdate: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield updatePlace(place);
                if (!response._id)
                    throw new Error('Place not found');
                placeDispatch({ type: 'UPDATE_PLACE', id: response._id, place: response });
                actions.onBack(response);
            }
            catch (error) {
                dialogDispatch({ type: 'SET_NODE', node: React.createElement(Alert, null, error.message) });
            }
        }),
        onDeleteImage: () => __awaiter(void 0, void 0, void 0, function* () {
            if (confirm(t('deleteImageConfirm'))) {
                try {
                    if (!props.place._id)
                        throw new Error('Place not found');
                    const response = yield deletePlaceImage(props.place._id);
                    if (response) {
                        const draftPlace = createDraft(place);
                        delete draftPlace.image;
                        setPlace(finishDraft(draftPlace));
                    }
                }
                catch (error) {
                    dialogDispatch({ type: 'SET_NODE', node: React.createElement(Alert, { type: 'error' }, error.message) });
                }
            }
        }),
        onUpdatePosition: () => {
            if (!modalCtx.markerRef)
                return;
            const { lng, lat } = modalCtx.markerRef.getLngLat();
            const draftPlace = createDraft(placeRef.current);
            if (!draftPlace.location || !draftPlace.location.coordinates)
                return;
            draftPlace.location.coordinates[0] = lng;
            draftPlace.location.coordinates[1] = lat;
            setPlace(finishDraft(draftPlace));
        },
    };
    useEffect(() => {
        if (!modalCtx.markerRef)
            return;
        modalCtx.markerRef.setDraggable(true);
        modalCtx.markerRef.on('dragend', actions.onUpdatePosition);
        return () => {
            if (!modalCtx.markerRef)
                return;
            modalCtx.markerRef.setDraggable(false);
            modalCtx.markerRef.off('dragend', actions.onUpdatePosition);
        };
    }, [modalCtx.markerRef]);
    const placeTypeOptions = [
        ['default', t('place', { count: 1 })],
        ['hotel', t('hotel', { count: 1 })],
        ['restaurant', t('restaurant', { count: 1 })],
        ['bar', t('bar', { count: 1 })],
        ['tourist_attraction', t('touristAttraction', { count: 1 })],
        ['parking_lot', t('parking_lot')],
        ['mountain_pass', t('mountain_pass')],
        ['mountain_pass', t('mountain_pass')],
    ];
    return (React.createElement("div", { className: 'text-sm' },
        React.createElement("div", { className: 'px-4 py-3' },
            React.createElement(Flexbox, { direction: 'horizontal', position: 'center' },
                React.createElement(Switch, { enabled: (_c = place.state) === null || _c === void 0 ? void 0 : _c.public, onChange: (value) => {
                        const draft = createDraft(place);
                        if (!draft.state)
                            draft.state = {};
                        draft.state.public = value;
                        setPlace(finishDraft(draft));
                    } }),
                React.createElement(Label, null, t('isPublic')),
                React.createElement("div", { className: 'flex-grow' }),
                React.createElement(Button, { type: 'primary', onClick: actions.onUpdate }, t('save')))),
        React.createElement(Divider, null),
        React.createElement(CardBody, null,
            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
                React.createElement("div", { className: 'grid grid-cols-2 gap-5 items-center' },
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('title')),
                        React.createElement(Input, { type: 'text', value: (_d = place.title) !== null && _d !== void 0 ? _d : '', onChange: (e) => setPlace(Object.assign(Object.assign({}, place), { title: e.currentTarget.value })) })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('type')),
                        React.createElement(ListBox, { type: 'form', renderSelected: (option) => option[1], selected: placeTypeOptions.find((option) => option[0] == place.type), setSelected: (newValue) => {
                                if (!newValue)
                                    return;
                                setPlace(Object.assign(Object.assign({}, place), { type: newValue[0] }));
                            }, options: placeTypeOptions, renderOption: (option) => option[1] }))),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('address')),
                    React.createElement(WaypointGenericSearchAddress, { type: 'relative', onSetAddress: (selected) => {
                            var _a, _b, _c, _d, _e, _f;
                            const draft = createDraft(place);
                            const address = {
                                streetName: (_a = selected.address.streetName) !== null && _a !== void 0 ? _a : '',
                                streetNumber: (_b = selected.address.streetNumber) !== null && _b !== void 0 ? _b : '',
                                city: (_c = selected.address.municipality) !== null && _c !== void 0 ? _c : '',
                                postalCode: (_d = selected.address.postalCode) !== null && _d !== void 0 ? _d : '',
                                country: (_e = selected.address.country) !== null && _e !== void 0 ? _e : '',
                                freeformAddress: (_f = selected.address.freeformAddress) !== null && _f !== void 0 ? _f : '',
                            };
                            if (selected.location) {
                                draft.location = selected.location;
                            }
                            draft.address = address;
                            setPlace(finishDraft(draft));
                        }, selected: {
                            address: place.address,
                            location: place.location,
                        } })),
                React.createElement(Divider, null),
                React.createElement(Label, null, t('image')),
                React.createElement(ImageUpload, { place: placeRef.current, onChange: (image) => {
                        const draftPlace = createDraft(placeRef.current);
                        draftPlace.image = image;
                        setPlace(finishDraft(draftPlace));
                    } }),
                React.createElement(Divider, null),
                place.image && (React.createElement(React.Fragment, null,
                    React.createElement("img", { src: getPlaceImageURL(place), className: 'w-full', alt: place.title }),
                    React.createElement(Anchor, { onClick: actions.onDeleteImage }, t('delete')),
                    React.createElement(Divider, null))),
                React.createElement("div", { className: 'grid grid-cols-2 gap-3' },
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('website')),
                        React.createElement(Input, { type: 'text', value: (_f = (_e = place.contact) === null || _e === void 0 ? void 0 : _e.website) !== null && _f !== void 0 ? _f : '', onChange: (e) => {
                                const draft = createDraft(place);
                                if (!draft.contact)
                                    draft.contact = {};
                                draft.contact.website = e.currentTarget.value;
                                setPlace(finishDraft(draft));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('phone')),
                        React.createElement(Input, { type: 'phone', value: (_h = (_g = place.contact) === null || _g === void 0 ? void 0 : _g.phone) !== null && _h !== void 0 ? _h : '', onChange: (e) => {
                                const draft = createDraft(place);
                                if (!draft.contact)
                                    draft.contact = {};
                                draft.contact.phone = e.currentTarget.value;
                                setPlace(finishDraft(draft));
                            } }))),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('description')),
                    React.createElement(Textarea, { name: 'description', value: place.description, onChange: (e) => setPlace(Object.assign(Object.assign({}, place), { description: e.currentTarget.value })) })),
                React.createElement(Divider, null),
                isExtendedAddressOpen && (React.createElement("div", { className: 'grid grid-cols-2 gap-3 bg-slate-100 px-2 py-3 rounded-lg' },
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('imageBy')),
                        React.createElement(Input, { type: 'text', value: (_j = imageReference === null || imageReference === void 0 ? void 0 : imageReference.value) !== null && _j !== void 0 ? _j : '', onChange: (e) => {
                                const value = e.currentTarget.value;
                                const draft = createDraft(place);
                                if (!draft.relations)
                                    draft.relations = [];
                                if (value == '') {
                                    const relation = draft.relations.findIndex((item) => item.name == 'image');
                                    if (relation >= 0) {
                                        draft.relations.splice(relation, 1);
                                    }
                                }
                                else {
                                    const relation = draft.relations.find((item) => item.name == 'image');
                                    if (!relation)
                                        draft.relations.push({ name: 'image', value: value });
                                    if (relation)
                                        relation.value = value;
                                }
                                setPlace(finishDraft(draft));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, "Tripadvisor ID"),
                        React.createElement(Input, { type: 'text', value: (_k = tripadvisor === null || tripadvisor === void 0 ? void 0 : tripadvisor.value) !== null && _k !== void 0 ? _k : '', onChange: (e) => {
                                const value = parseTripadvisorIDFromURL(e.currentTarget.value);
                                const draft = createDraft(place);
                                if (!draft.relations)
                                    draft.relations = [];
                                if (value == '') {
                                    const relation = draft.relations.findIndex((item) => item.name == 'tripadvisor');
                                    if (relation >= 0) {
                                        draft.relations.splice(relation, 1);
                                    }
                                }
                                else {
                                    const relation = draft.relations.find((item) => item.name == 'tripadvisor');
                                    if (!relation)
                                        draft.relations.push({ name: 'tripadvisor', value: value });
                                    if (relation)
                                        relation.value = value;
                                }
                                setPlace(finishDraft(draft));
                            } })),
                    React.createElement("div", { className: 'col-span-2' },
                        React.createElement(Divider, null)),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('latitude')),
                        React.createElement(Input, { type: 'text', value: (_m = (_l = place.location) === null || _l === void 0 ? void 0 : _l.coordinates[1]) !== null && _m !== void 0 ? _m : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.location || !draftPlace.location.coordinates)
                                    return;
                                draftPlace.location.coordinates[1] = parseFloat(e.currentTarget.value);
                                setPlace(finishDraft(draftPlace));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('longitude')),
                        React.createElement(Input, { type: 'text', value: (_p = (_o = place.location) === null || _o === void 0 ? void 0 : _o.coordinates[0]) !== null && _p !== void 0 ? _p : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.location)
                                    return;
                                if (!draftPlace.location || !draftPlace.location.coordinates)
                                    return;
                                draftPlace.location.coordinates[0] = parseFloat(e.currentTarget.value);
                                setPlace(finishDraft(draftPlace));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('streetName')),
                        React.createElement(Input, { type: 'text', value: (_r = (_q = place.address) === null || _q === void 0 ? void 0 : _q.streetName) !== null && _r !== void 0 ? _r : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.address)
                                    return;
                                draftPlace.address.streetName = e.currentTarget.value;
                                draftPlace.address.freeformAddress = toAddressString(draftPlace.address);
                                setPlace(finishDraft(draftPlace));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('streetNumber')),
                        React.createElement(Input, { type: 'text', value: (_t = (_s = place.address) === null || _s === void 0 ? void 0 : _s.streetNumber) !== null && _t !== void 0 ? _t : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.address)
                                    return;
                                draftPlace.address.streetNumber = e.currentTarget.value;
                                draftPlace.address.freeformAddress = toAddressString(draftPlace.address);
                                setPlace(finishDraft(draftPlace));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('postalCode')),
                        React.createElement(Input, { type: 'text', value: (_v = (_u = place.address) === null || _u === void 0 ? void 0 : _u.postalCode) !== null && _v !== void 0 ? _v : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.address)
                                    return;
                                draftPlace.address.postalCode = e.currentTarget.value;
                                draftPlace.address.freeformAddress = toAddressString(draftPlace.address);
                                setPlace(finishDraft(draftPlace));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('municipality')),
                        React.createElement(Input, { type: 'text', value: (_x = (_w = place.address) === null || _w === void 0 ? void 0 : _w.city) !== null && _x !== void 0 ? _x : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.address)
                                    return;
                                draftPlace.address.city = e.currentTarget.value;
                                draftPlace.address.freeformAddress = toAddressString(draftPlace.address);
                                setPlace(finishDraft(draftPlace));
                            } })),
                    React.createElement(InputSection, null,
                        React.createElement(Label, null, t('country')),
                        React.createElement(Input, { type: 'text', value: (_z = (_y = place.address) === null || _y === void 0 ? void 0 : _y.country) !== null && _z !== void 0 ? _z : '', onChange: (e) => {
                                const draftPlace = createDraft(place);
                                if (!draftPlace.address)
                                    return;
                                draftPlace.address.country = e.currentTarget.value;
                                draftPlace.address.freeformAddress = toAddressString(draftPlace.address);
                                setPlace(finishDraft(draftPlace));
                            } })))),
                React.createElement(Anchor, { onClick: () => openExtendedAddress(!isExtendedAddressOpen) }, t('extendedSettings'))),
            React.createElement(Debug, { data: place }))));
};
