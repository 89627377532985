var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { FlightTimeCalculator } from '../../../../lib/flightTimeCalculator';
import { produce } from 'immer';
import { NoRouteFound } from '../waypoint/map/dialogs/noRouteFound';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../context/context';
import { useDialogDispatchCtx } from '../../atoms/dialog/context';
import PromiseQueue from '../../../../lib/promiseQueue';
import { routeRequest } from '../../../../api/tomtom';
import { OtherTransportationTimeCalculator } from '../../../../lib/otherTransportationTimeCalculator';
import { getTimeZone } from '../../../../api/internal/tour';
const promiseQueue = PromiseQueue.getInstance();
export const WaypointRoute = (props) => {
    const { waypointIndex, groupIndex, map, previousWaypointDetails } = props;
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const group = context.tour.groups[groupIndex];
    const waypoint = context.tour.groups[groupIndex].waypoints[waypointIndex];
    const previousWaypoint = previousWaypointDetails
        ? context.tour.groups[previousWaypointDetails.groupIndex].waypoints[previousWaypointDetails.waypointIndex]
        : null;
    const { ui } = useApplicationCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const loadTimeZoneForWaypoint = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const tz = yield getTimeZone((_a = waypoint.location) !== null && _a !== void 0 ? _a : undefined);
        if (tz) {
            return tz;
        }
        return undefined;
    });
    const calculateFlight = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        if (!previousWaypoint || !waypoint.location)
            return;
        if (!previousWaypoint.location || !waypoint.location)
            return;
        const timezone = yield loadTimeZoneForWaypoint();
        const lineString = FlightTimeCalculator.calculateFlightTime(previousWaypoint.location.coordinates, (_b = waypoint.location) === null || _b === void 0 ? void 0 : _b.coordinates);
        const updatedWaypoint = produce(waypoint, (draft) => {
            draft.type = 'flight';
            draft.lineString = lineString;
            draft.timezone = timezone;
        });
        dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: groupIndex, waypoint: updatedWaypoint });
    });
    const calculateOther = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!previousWaypoint || !waypoint.location)
            return;
        if (!previousWaypoint.location || !waypoint.location)
            return;
        const timezone = yield loadTimeZoneForWaypoint();
        const lineString = OtherTransportationTimeCalculator.calculate(previousWaypoint.location.coordinates, (_c = waypoint.location) === null || _c === void 0 ? void 0 : _c.coordinates);
        const updatedWaypoint = produce(waypoint, (draft) => {
            draft.type = 'default';
            draft.lineString = lineString;
            draft.timezone = timezone;
        });
        dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: groupIndex, waypoint: updatedWaypoint });
    });
    const calculateRoute = () => __awaiter(void 0, void 0, void 0, function* () {
        if (previousWaypoint == null)
            return;
        props.setLoading(true);
        dispatch({ type: 'UPDATE_UI', ui: Object.assign(Object.assign({}, ui), { isLoading: true }) });
        try {
            const timezone = yield promiseQueue.add(loadTimeZoneForWaypoint);
            const response = yield promiseQueue.add(() => routeRequest(previousWaypoint, waypoint, group.summary.departureTime));
            if (!response)
                return;
            const { lineString } = response;
            const updatedWaypoint = produce(waypoint, (draft) => {
                draft.lineString = lineString;
                draft.timezone = timezone;
            });
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: groupIndex, waypoint: updatedWaypoint });
        }
        catch (error) {
            console.error(error);
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(NoRouteFound, { origin: previousWaypointDetails, destination: props }),
            });
        }
        props.setLoading(false);
        dispatch({ type: 'UPDATE_UI', ui: Object.assign(Object.assign({}, ui), { isLoading: false }) });
    });
    useEffect(() => {
        if (!waypoint.location || !(previousWaypoint === null || previousWaypoint === void 0 ? void 0 : previousWaypoint.location) || waypoint.lineString != null)
            return;
        if (waypoint.config.travelMode == 'unknownTravelMode')
            return;
        if (waypoint.config.travelMode == 'other') {
            calculateOther();
        }
        else if (waypoint.config.travelMode != 'airplane') {
            calculateRoute();
        }
        else {
            calculateFlight();
        }
    }, [waypoint.location, waypoint.lineString, previousWaypoint === null || previousWaypoint === void 0 ? void 0 : previousWaypoint.location, waypoint.config]);
    const initRouteLayerOnMap = () => {
        if (!map || waypoint.lineString == undefined)
            return;
        const { id, lineString } = waypoint;
        if (map.getLayer(`touring-studio-${id}-layer`)) {
            map.removeLayer(`touring-studio-${id}-layer`);
        }
        if (map.getSource(`touring-studio-${id}-source`)) {
            map.removeSource(`touring-studio-${id}-source`);
        }
        map.addSource(`touring-studio-${id}-source`, {
            type: 'geojson',
            data: lineString,
        });
        map.addLayer({
            id: `touring-studio-${id}-layer`,
            type: 'line',
            source: `touring-studio-${id}-source`,
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': group === null || group === void 0 ? void 0 : group.color,
                'line-width': 8,
                'line-dasharray': waypoint.config.travelMode == 'pedestrian' ? [0.1, 1.5] : [1, 0],
                'line-opacity': waypoint.config.travelMode == 'other' ? 0.2 : 1,
            },
        });
    };
    const deinitRouteLayerOnMap = () => {
        if (!map)
            return;
        if (map.getLayer(`touring-studio-${waypoint.id}-layer`)) {
            map.removeLayer(`touring-studio-${waypoint.id}-layer`);
        }
        if (map.getSource(`touring-studio-${waypoint.id}-source`)) {
            map.removeSource(`touring-studio-${waypoint.id}-source`);
        }
    };
    useEffect(() => {
        if (!map)
            return;
        if (!context.ui.tourVisible) {
            deinitRouteLayerOnMap();
            return;
        }
        if (waypoint.lineString) {
            initRouteLayerOnMap();
        }
        else {
            deinitRouteLayerOnMap();
        }
        return () => {
            deinitRouteLayerOnMap();
        };
    }, [waypoint.lineString, map, waypoint.type, context.ui.tourVisible, group.color]);
    return React.createElement(React.Fragment, null);
};
