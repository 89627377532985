import React, { useEffect, useRef, useState } from 'react';
import { ModalIndex } from '../atoms/modal';
import { TourPrivateIndex } from '../molecules/tour/private';
import { Flexbox } from '../../touring-ui/components/flexbox';
export const TourManager = () => {
    const ref = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 300 });
    useEffect(() => {
        if (!ref || !ref.current)
            return;
        const item = ref.current;
        setSize({ width: item.clientWidth, height: item.clientHeight });
    }, [ref]);
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                setSize({ width, height });
            }
        });
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current);
            }
        };
    }, []);
    return (React.createElement("div", { className: 'absolute max-h-full h-full pointer-events-none z-40 left-0 right-0 bottom-0 top-0 p-3', style: { paddingTop: '65px' } },
        React.createElement("div", { ref: ref, className: 'max-h-full h-full' },
            React.createElement(Flexbox, { direction: 'horizontal', className: 'max-h-full h-full', gap: 'lg' },
                React.createElement(TourPrivateIndex, { maxHeight: size.height }),
                React.createElement(ModalIndex, { maxHeight: size.height })))));
};
