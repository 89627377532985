var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialogDispatchCtx } from '../atoms/dialog/context';
import { useLiveQuery } from 'dexie-react-hooks';
import { db, SystemKeys } from '../../../lib/localDB';
import { MascotImage } from '../../touring-ui/icons/_export';
import { TutorialDialogBody } from '../molecules/tutorial';
import { ReminderKeys, useReminderCoordinator } from '../molecules/reminders';
import { ReminderPurchaseScreen } from '../molecules/purchase/purchaseScreen';
import { UserLoginIndex } from '../molecules/user/login';
export const DialogCoordinator = () => {
    const { t } = useTranslation();
    const dialogDispatch = useDialogDispatchCtx();
    const isDBReady = useLiveQuery(() => db.system.where({ key: SystemKeys.Onboarding }).toArray());
    const reminderCoordinator = useReminderCoordinator();
    const shouldShowOnboarding = () => __awaiter(void 0, void 0, void 0, function* () {
        const isSeen = yield db.system.where({ key: SystemKeys.Onboarding }).first();
        if (!isSeen || !isSeen.value) {
            setSeen(true);
            actions.onShowWelcomeMessage();
        }
    });
    const setSeen = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value) {
            yield db.system.add({ key: SystemKeys.Onboarding, value: true });
        }
        else {
            yield db.system.where({ key: SystemKeys.Onboarding }).modify({ value: false });
        }
    });
    const actions = {
        onShowWelcomeMessage: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(TutorialDialogBody, null),
                maxWidth: '1024px',
                title: (React.createElement("div", { className: 'flex flex-row items-center gap-1' },
                    React.createElement(MascotImage, { className: 'w-4 h-4' }),
                    " ",
                    t('tutorial'))),
            });
        },
        onShowPurchaseScreen: (event) => {
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(ReminderPurchaseScreen, { reason: event.detail }),
                maxWidth: '460px',
            });
        },
        onShowLogin: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: (React.createElement(UserLoginIndex, { onDone: () => {
                        dialogDispatch({ type: 'REMOVE_NODE' });
                    } })),
            });
        },
    };
    useEffect(() => {
        if (!isDBReady)
            return;
        shouldShowOnboarding();
        setTimeout(() => {
            reminderCoordinator.addReminderToQueue(ReminderKeys.SignUp);
        }, 500);
        window.addEventListener('showTutorial', actions.onShowWelcomeMessage);
        window.addEventListener('showPurchaseScreen', actions.onShowPurchaseScreen);
        window.addEventListener('showLogin', actions.onShowLogin);
        return () => {
            window.removeEventListener('showTutorial', actions.onShowWelcomeMessage);
            window.removeEventListener('onShowPurchaseScreen', actions.onShowPurchaseScreen);
            window.removeEventListener('showLogin', actions.onShowLogin);
        };
    }, [isDBReady]);
    return React.createElement(React.Fragment, null);
};
