import React from 'react';
import { AlertTriangleIcon, AnnotationInfoIcon, CheckCircleIcon, InfoCircleIcon } from '../../icons/_export';
import { Text } from '../typography/text';
import { Flexbox } from '../flexbox';
export const Alert = (props) => {
    const { children, className } = props;
    const size = (() => {
        var _a;
        const selectedSize = (_a = props.size) !== null && _a !== void 0 ? _a : 'sm';
        switch (selectedSize) {
            case 'sm':
                return `p-3 pr-4 text-sm`;
            case 'md':
                return `p-3 pr-4 text-md`;
            case 'lg':
                return `p-3 pr-4 tex-xl`;
        }
    })();
    const color = (() => {
        var _a;
        const selectedColor = (_a = props.type) !== null && _a !== void 0 ? _a : 'transparent';
        switch (selectedColor) {
            case 'announcement':
                return `border border-slate-300 dark:border-neutral-700 text-black dark:text-white `;
            case 'info':
                return `bg-indigo-600 text-white shadow-md`;
            case 'success':
                return `bg-emerald-600 text-white shadow-md`;
            case 'warning':
                return `bg-amber-600 text-white shadow-md`;
            case 'error':
                return `bg-rose-600 text-white shadow-md`;
        }
    })();
    const icon = (() => {
        var _a;
        const selectedColor = (_a = props.type) !== null && _a !== void 0 ? _a : 'transparent';
        switch (selectedColor) {
            case 'announcement':
                return React.createElement(AnnotationInfoIcon, { className: 'w-6 h-6 flex-none' });
            case 'info':
                return React.createElement(AnnotationInfoIcon, { className: 'w-6 h-6 flex-none' });
            case 'success':
                return React.createElement(CheckCircleIcon, { className: 'w-6 h-6 flex-none' });
            case 'warning':
                return React.createElement(AlertTriangleIcon, { className: 'w-6 h-6 flex-none' });
            case 'error':
                return React.createElement(InfoCircleIcon, { className: 'w-6 h-6 flex-none' });
        }
    })();
    return (React.createElement("div", { className: `${size} ${color} ${className} flex gap-3 rounded-md font-semibold` },
        React.createElement("div", { className: 'pt-[2px]' }, icon),
        React.createElement("div", null, children)));
};
export const AlertPreview = () => (React.createElement(Flexbox, { direction: 'vertical', className: 'mb-8', gap: 'lg' },
    React.createElement("a", { id: 'alerts' }),
    React.createElement(Text, { type: 'subheadline' }, "Alerts"),
    React.createElement("hr", null),
    React.createElement(Alert, { type: 'info', size: 'sm' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus nisl tincidunt eget nullam non. Cras fermentum odio eu feugiat pretium nibh."),
    React.createElement(Alert, { type: 'success', size: 'sm' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus nisl tincidunt eget nullam non. Cras fermentum odio eu feugiat pretium nibh."),
    React.createElement(Alert, { type: 'warning', size: 'sm' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus nisl tincidunt eget nullam non. Cras fermentum odio eu feugiat pretium nibh."),
    React.createElement(Alert, { type: 'error', size: 'sm' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus nisl tincidunt eget nullam non. Cras fermentum odio eu feugiat pretium nibh.")));
