import React from 'react';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { Label } from '../../../../touring-ui/components/form/label';
import { Input } from '../../../../touring-ui/components/form/input';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { InputInfo } from '../../../../touring-ui/components/form/info';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import QRCode from 'react-qr-code';
import { Text } from '../../../../touring-ui/components/typography/text';
import { createDraft, finishDraft } from 'immer';
import { Button } from '../../../../touring-ui/components/buttons';
import { Share03Icon } from '../../../../touring-ui/icons/_export';
import { useTranslation } from 'react-i18next';
export const TourShare = () => {
    const { t } = useTranslation();
    const { tour } = useApplicationCtx();
    const tourLink = `${location.protocol}//${location.host}/tour/${tour.id}/public`;
    const tourGuideLink = `${location.protocol}//${location.host}/tour/${tour.id}/public/guide`;
    const tourGuideLinkPrivate = `${location.protocol}//${location.host}/tour/${tour.id}/guide`;
    const dispatch = useApplicationDispatchCtx();
    const actions = {
        onChangePublic: (isEnabled) => {
            const draft = createDraft(tour);
            draft.isPublic = isEnabled;
            dispatch({ type: 'UPDATE_TOUR', tour: finishDraft(draft), inSync: false });
        },
    };
    return (React.createElement(React.Fragment, null,
        !tour.isPublic && (React.createElement(Flexbox, { gap: 'md' },
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('tourGuide')),
                React.createElement(InputInfo, null, t('copyLinkInstructions')),
                React.createElement(Flexbox, { direction: 'horizontal', gap: 'md', nowrap: true },
                    React.createElement(Input, { value: tourGuideLinkPrivate, disabled: true }),
                    React.createElement(Button, { type: 'secondary', onClick: () => window.open(tourGuideLinkPrivate, '_blank') },
                        React.createElement(Share03Icon, { className: 'w-4 h-4' })))),
            React.createElement(Flexbox, { direction: 'horizontal', gap: 'xl', nowrap: true, position: 'center' },
                React.createElement(QRCode, { value: tourGuideLinkPrivate, className: 'w-32 h-32 border-8 border-white flex-none' }),
                React.createElement(Flexbox, null,
                    React.createElement(Text, { className: 'font-semibold' }, "QR-Code"),
                    React.createElement(Text, null, t('scanQRCodeInstructions')))))),
        tour.isPublic && (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'text-sm' },
            React.createElement(Flexbox, { gap: 'md' },
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('tourGuide')),
                    React.createElement(InputInfo, null, t('copyLinkInstructions')),
                    React.createElement(Flexbox, { direction: 'horizontal', gap: 'md', nowrap: true },
                        React.createElement(Input, { value: tourGuideLink, disabled: true }),
                        React.createElement(Button, { type: 'secondary', onClick: () => window.open(tourGuideLink, '_blank') },
                            React.createElement(Share03Icon, { className: 'w-4 h-4' })))),
                React.createElement(Flexbox, { direction: 'horizontal', gap: 'xl', nowrap: true, position: 'center' },
                    React.createElement(QRCode, { value: tourGuideLink, className: 'w-32 h-32 border-8 border-white flex-none' }),
                    React.createElement(Flexbox, null,
                        React.createElement(Text, { className: 'font-semibold' }, "QR-Code"),
                        React.createElement(Text, null, t('scanQRCodeInstructions')))))))));
};
