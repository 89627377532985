var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class PromiseQueue {
    constructor() {
        this.queue = [];
        this.running = false;
    }
    static getInstance() {
        return PromiseQueue.instance;
    }
    add(promiseFunction) {
        return new Promise((resolve, reject) => {
            const task = () => __awaiter(this, void 0, void 0, function* () {
                try {
                    const result = yield promiseFunction();
                    resolve(result);
                }
                catch (error) {
                    reject(error);
                }
                finally {
                    this.runNext();
                }
            });
            this.queue.push(task);
            if (!this.running) {
                this.runNext();
            }
        });
    }
    runNext() {
        if (this.queue.length > 0) {
            const nextTask = this.queue.shift();
            this.running = true;
            nextTask();
        }
        else {
            this.running = false;
        }
    }
}
PromiseQueue.instance = new PromiseQueue();
export default PromiseQueue;
