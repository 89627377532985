import React, { useState } from 'react';
import { Card } from '../../../../../touring-ui/components/cards';
import { useApplicationCtx } from '../../../../../../context/context';
import { getAllWaypoints } from '../../../../../../context/slices/waypoints';
import { AddToGroupSelectGroup } from './group';
import { useTranslation } from 'react-i18next';
export const AddToGroupSelect = (props) => {
    const context = useApplicationCtx();
    const allWaypoints = getAllWaypoints(context.tour);
    const [isOpen, setOpen] = useState(null);
    const { t } = useTranslation();
    return (React.createElement("div", { className: 'text-sm' },
        React.createElement(Card, { className: 'overflow-hidden' },
            React.createElement("ul", { className: 'divide-y min-h-200 max-h-full overflow-scroll' }, context.tour.groups.map((group, groupIndex) => (React.createElement(AddToGroupSelectGroup, { key: group.id, groupIndex: groupIndex, group: group, place: props.place, allWaypoints: allWaypoints, isOpen: isOpen, setOpen: setOpen })))))));
};
