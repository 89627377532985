class LoadDate {
    constructor() {
        this.timestamp = new Date().getTime();
    }
    static getInstance() {
        if (!LoadDate.instance) {
            LoadDate.instance = new LoadDate();
        }
        return LoadDate.instance;
    }
    getInitialTimestamp() {
        return this.timestamp;
    }
}
export default LoadDate;
