import React, { useState } from 'react';
import { ActivitiesIcon, BarIcon, BedIcon, BoxIcon, CheckVerified03Icon, FoodIcon, Glasses02Icon, Image05Icon, MarkerPin01Icon, ParkingIcon, } from '../../../touring-ui/icons/_export';
export const PlaceIcon = (props) => {
    const sizeWrapper = (() => {
        var _a;
        switch ((_a = props.size) !== null && _a !== void 0 ? _a : 'md') {
            case 'xs':
                return 'w-6 h-6';
            case 'sm':
                return 'w-8 h-8';
            case 'md':
                return 'w-10 h-10';
            case 'lg':
                return 'w-12 h-12';
        }
    })();
    const size = (() => {
        var _a;
        switch ((_a = props.size) !== null && _a !== void 0 ? _a : 'md') {
            case 'xs':
                return 'w-3 h-3';
            case 'sm':
                return 'w-4 h-4';
            case 'md':
                return 'w-4 h-4';
            case 'lg':
                return 'w-8 h-8';
        }
    })();
    const [seen, setSeen] = useState(false);
    const [hover, setHover] = useState(false);
    const isSelected = props.isSelected;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `relative flex place-content-center items-center ${sizeWrapper}`, onMouseOver: () => {
                if (!props.canEdit)
                    return;
                setHover(true);
            }, onMouseOut: () => {
                if (!props.canEdit)
                    return;
                setHover(false);
            }, onClick: () => {
                setSeen(true);
                if (props.onClick)
                    props.onClick();
            } },
            React.createElement(React.Fragment, null,
                isSelected && (React.createElement("div", { className: `absolute w-7 h-7 animate-ping bg-rose-600 rounded-full z-40` })),
                React.createElement("div", { className: `${sizeWrapper} ${seen
                        ? 'border-slate-500 bg-slate-300 text-slate-700'
                        : props.color
                            ? props.color
                            : 'border-blue-500 bg-blue-300 text-blue-700'} border-2  flex flex-none items-center justify-center rounded-full cursor-pointer relative dark:bg-neutral-800 z-50` },
                    !hover && (React.createElement(React.Fragment, null,
                        props.type == 'default' && React.createElement(MarkerPin01Icon, { className: `${size}` }),
                        props.type == 'parking_lot' && React.createElement(ParkingIcon, { className: `${size}` }),
                        props.type == 'hotel' && React.createElement(BedIcon, { className: `${size}` }),
                        props.type == 'restaurant' && React.createElement(FoodIcon, { className: `${size}` }),
                        props.type == 'bar' && React.createElement(BarIcon, { className: `${size}` }),
                        props.type == 'tourist_attraction' && React.createElement(ActivitiesIcon, { className: `${size}` }),
                        props.type == 'cluster' && React.createElement(BoxIcon, { className: `${size}` }),
                        props.type == 'mountain_pass' && React.createElement(Image05Icon, { className: `${size}` }))),
                    hover && React.createElement(Glasses02Icon, { className: `${size}` })),
                props.curated && (React.createElement("div", { className: 'absolute -bottom-2 -right-2 z-60' },
                    React.createElement("div", { className: 'bg-white text-lime-600 p-0 rounded-full flex items-center' },
                        React.createElement(CheckVerified03Icon, { className: 'w-5 h-5' }))))))));
};
