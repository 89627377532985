import axios from 'axios';
import { nanoid } from 'nanoid';
import { getAccessToken } from './accessToken';
export var EventTrackingTypes;
(function (EventTrackingTypes) {
    EventTrackingTypes["RouteRequest"] = "request_route";
    EventTrackingTypes["SearchAddressRequest"] = "search_address";
    EventTrackingTypes["ReverseGeoCodeRequest"] = "request_address_reverse_geocode";
    EventTrackingTypes["Support"] = "support";
    EventTrackingTypes["LoadWebApp"] = "load_web_app";
})(EventTrackingTypes || (EventTrackingTypes = {}));
function parseJwt(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window
            .atob(base64)
            .split('')
            .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
            .join(''));
        return JSON.parse(jsonPayload);
    }
    catch (err) {
        return null;
    }
}
export const track = (event) => {
    let userId = nanoid();
    const token = getAccessToken();
    if (token) {
        const jwtDecoded = parseJwt(token);
        if (jwtDecoded && jwtDecoded.sub) {
            userId = jwtDecoded.sub;
        }
    }
    axios.post('https://tracking.api.touring.studio/events', { event_name: event, identifier: userId });
};
