import { getAllWaypoints } from '../../slices/waypoints';
export const useWaypointGlobalPosition = (context, id) => {
    const all = getAllWaypoints(context.tour);
    const total = all.length;
    const index = all.findIndex((item) => item.id == id);
    const isLast = total - 1 == index;
    return {
        total,
        isLast,
        index,
    };
};
