var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { services } from '@tomtom-international/web-sdk-services';
import { EventTrackingTypes, track } from '../lib/eventTracking';
import { DateTime } from 'luxon';
import axios from 'axios';
import qs from 'qs';
const API_KEY = process.env.TOMTOM_API_KEY;
export const fuzzySearch = (value) => __awaiter(void 0, void 0, void 0, function* () {
    track(EventTrackingTypes.SearchAddressRequest);
    const response = yield services.fuzzySearch({
        key: API_KEY,
        query: value,
        language: navigator.language,
        typeahead: true,
        limit: 5,
    });
    if (response.results == undefined)
        return null;
    return response.results.map((item) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        return {
            poi: item.poi,
            address: {
                streetName: (_b = (_a = item.address) === null || _a === void 0 ? void 0 : _a.streetName) !== null && _b !== void 0 ? _b : '',
                streetNumber: (_d = (_c = item.address) === null || _c === void 0 ? void 0 : _c.streetNumber) !== null && _d !== void 0 ? _d : '',
                postalCode: (_f = (_e = item.address) === null || _e === void 0 ? void 0 : _e.postalCode) !== null && _f !== void 0 ? _f : '',
                municipality: (_h = (_g = item.address) === null || _g === void 0 ? void 0 : _g.municipality) !== null && _h !== void 0 ? _h : '',
                country: (_k = (_j = item.address) === null || _j === void 0 ? void 0 : _j.country) !== null && _k !== void 0 ? _k : '',
                freeformAddress: (_m = (_l = item.address) === null || _l === void 0 ? void 0 : _l.freeformAddress) !== null && _m !== void 0 ? _m : '',
            },
            location: {
                type: 'Point',
                coordinates: [(_p = (_o = item.position) === null || _o === void 0 ? void 0 : _o.lng) !== null && _p !== void 0 ? _p : 0, (_r = (_q = item.position) === null || _q === void 0 ? void 0 : _q.lat) !== null && _r !== void 0 ? _r : 0],
            },
        };
    });
});
export const reverseGeocode = (location) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    track(EventTrackingTypes.ReverseGeoCodeRequest);
    const response = yield services.reverseGeocode({
        key: API_KEY,
        position: location.coordinates.join(','),
    });
    if (response.addresses == undefined)
        return null;
    const [item] = response.addresses;
    return {
        streetName: (_b = (_a = item.address) === null || _a === void 0 ? void 0 : _a.streetName) !== null && _b !== void 0 ? _b : '',
        streetNumber: (_d = (_c = item.address) === null || _c === void 0 ? void 0 : _c.streetNumber) !== null && _d !== void 0 ? _d : '',
        postalCode: (_f = (_e = item.address) === null || _e === void 0 ? void 0 : _e.postalCode) !== null && _f !== void 0 ? _f : '',
        municipality: (_h = (_g = item.address) === null || _g === void 0 ? void 0 : _g.municipality) !== null && _h !== void 0 ? _h : '',
        country: (_k = (_j = item.address) === null || _j === void 0 ? void 0 : _j.country) !== null && _k !== void 0 ? _k : '',
        freeformAddress: (_m = (_l = item.address) === null || _l === void 0 ? void 0 : _l.freeformAddress) !== null && _m !== void 0 ? _m : '',
    };
});
export const routeRequest = (origin, current, departureAt) => __awaiter(void 0, void 0, void 0, function* () {
    if (origin == undefined)
        return null;
    if (origin.location == undefined)
        return null;
    if (current.location == undefined)
        return null;
    track(EventTrackingTypes.RouteRequest);
    const requestOptions = {
        maxAlternatives: 0,
        computeBestOrder: false,
        travelMode: current.config.travelMode,
        routeType: current.config.routeType,
        avoid: current.config.avoid,
        hilliness: current.config.routeType == 'thrilling' ? current.config.hilliness : null,
        windingness: current.config.routeType == 'thrilling' ? current.config.windingness : null,
        departAt: DateTime.fromMillis(departureAt).toUTC().toJSDate(),
        key: API_KEY,
    };
    /*const routeData = await services.calculateRoute({
        key: API_KEY,
        ...requestOptions,
    }) */
    const originLatLng = [...origin.location.coordinates].reverse();
    const destinationLatLng = [...current.location.coordinates].reverse();
    const url = `https://api.tomtom.com/routing/1/calculateRoute/${originLatLng.join(',')}:${destinationLatLng.join(',')}/json`;
    const response = yield axios.get(url, {
        params: requestOptions,
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
    const routeData = response.data;
    const { summary, legs } = routeData.routes[0];
    if (!legs[0])
        return null;
    const coordinates = legs[0].points.flatMap((p) => p.latitude !== undefined && p.longitude !== undefined ? [[p.longitude, p.latitude]] : []);
    const lineString = {
        type: 'LineString',
        coordinates,
        properties: {
            arrivalTime: 0,
            departureTime: 0,
            travelTimeInSeconds: summary.travelTimeInSeconds,
            lengthInMeters: summary.lengthInMeters,
            breakInSeconds: 0,
        },
    };
    const route = {
        lineString: lineString,
    };
    return route;
});
