import * as turf from '@turf/turf';
// Define a class with a method for calculating flight time
export class FlightTimeCalculator {
    static generateSineWave(startCoords, endCoords) {
        const numPoints = 100;
        const amplitude = 0.02;
        const frequency = 2;
        // Calculate the distance between start and end coordinates
        const distance = turf.distance(turf.point(startCoords), turf.point(endCoords));
        // Generate points along the line with sine wave elevation
        const points = Array.from({ length: numPoints }, (_, index) => {
            const fraction = index / (numPoints - 1);
            const alongPoint = turf.along(turf.lineString([startCoords, endCoords]), fraction * distance);
            // Create a new array to avoid modifying the Turf.js-generated array directly
            const interpolatedCoords = [...alongPoint.geometry.coordinates];
            // Calculate sine wave elevation
            const elevation = amplitude * Math.sin(2 * Math.PI * frequency * fraction);
            // Add elevation to the Y-coordinate
            interpolatedCoords[1] += elevation;
            return interpolatedCoords;
        });
        // Create a Turf.js LineString from the generated points
        return turf.lineString(points);
    }
    static calculateFlightTime(startCoords, endCoords) {
        // Calculate distance between the two locations in kilometers
        const distanceInKilometers = turf.distance(turf.point(startCoords), turf.point(endCoords), {
            units: 'kilometers',
        });
        const distanceInMeters = turf.convertLength(distanceInKilometers, 'kilometers', 'meters');
        // Calculate the approximate flight time in hours
        const flightTime = distanceInKilometers / 800;
        const flightTimeSeconds = flightTime * 3600;
        const lineString = turf.lineString([startCoords, endCoords]);
        return {
            coordinates: lineString.geometry.coordinates,
            properties: {
                arrivalTime: 0,
                departureTime: 0,
                lengthInMeters: Math.ceil(distanceInMeters),
                travelTimeInSeconds: Math.ceil(flightTimeSeconds),
                breakInSeconds: 0,
            },
            type: 'LineString',
        };
    }
}
