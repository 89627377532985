import React from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { createDraft, finishDraft } from 'immer';
import { CardBody, CardFooter } from '../../../../touring-ui/components/cards';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { Label } from '../../../../touring-ui/components/form/label';
import { Input } from '../../../../touring-ui/components/form/input';
import { InputInfo } from '../../../../touring-ui/components/form/info';
import { Textarea } from '../../../../touring-ui/components/form/textarea';
import { Text } from '../../../../touring-ui/components/typography/text';
import { useTranslation } from 'react-i18next';
import { CalendarIndex, TimePicker } from '../../calendar';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { colorArray } from '../../../../../lib/color';
import { nanoid } from 'nanoid';
import { useOrigin } from '../../../../../context/effects/groups/useOrigin';
export const GroupsListDetail = (props) => {
    const { t } = useTranslation();
    const { index } = props;
    const { tour: { groups }, } = useApplicationCtx();
    const group = groups[index];
    const { origin } = useOrigin(index);
    const originWaypoint = origin
        ? groups[origin === null || origin === void 0 ? void 0 : origin.groupIndex].waypoints[origin === null || origin === void 0 ? void 0 : origin.waypointIndex]
        : group.waypoints.length > 0
            ? group.waypoints[0]
            : null;
    const dispatch = useApplicationDispatchCtx();
    const actions = {
        onChangeColor: (color) => {
            const draft = createDraft(group);
            draft.color = color;
            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft), withoutMiddleware: true });
        },
        onChangeTitle: (e) => {
            const draft = createDraft(group);
            draft.title = e.currentTarget.value;
            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft), withoutMiddleware: true });
        },
        onChangeImage: (e) => {
            const draft = createDraft(group);
            draft.image = e.currentTarget.value;
            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft), withoutMiddleware: true });
        },
        onChangeDescription: (e) => {
            const draft = createDraft(group);
            draft.description = e.currentTarget.value;
            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft), withoutMiddleware: true });
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CardBody, { className: 'text-sm' },
            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
                React.createElement("div", { className: 'flex flex-row gap-3' },
                    React.createElement(Text, { className: 'font-semibold' }, t('groupColor')),
                    colorArray.map((color) => (React.createElement("div", { key: nanoid(), className: `w-5 h-5 rounded-full cursor-pointer ring-2 ${group.color == color ? 'ring-slate-300' : 'ring-white'}`, style: { backgroundColor: color }, onClick: () => actions.onChangeColor(color) })))),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('date')),
                    React.createElement(CalendarIndex, { initialValue: group.summary.departureTime, onChange: (timestampInMilliseconds) => {
                            const draft = createDraft(group);
                            draft.summary.departureTime = timestampInMilliseconds;
                            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft) });
                        }, zone: originWaypoint === null || originWaypoint === void 0 ? void 0 : originWaypoint.timezone })),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('time')),
                    React.createElement(TimePicker, { selectedTime: group.summary.departureTime, onChange: (timestampInMilliseconds) => {
                            const draft = createDraft(group);
                            draft.summary.departureTime = timestampInMilliseconds;
                            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft) });
                        }, zone: originWaypoint === null || originWaypoint === void 0 ? void 0 : originWaypoint.timezone })),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('title')),
                    React.createElement(InputInfo, null, t('nameYourDay')),
                    React.createElement(Input, { type: 'text', name: 'title', value: group.title, onChange: actions.onChangeTitle })),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('image')),
                    React.createElement(InputInfo, null, t('addImageGuide')),
                    React.createElement(Input, { type: 'text', name: 'image', value: group.image, onChange: actions.onChangeImage })),
                React.createElement(Divider, null),
                React.createElement(InputSection, null,
                    React.createElement(Label, null, t('description')),
                    React.createElement(InputInfo, null, t('describeYourDay')),
                    React.createElement(Textarea, { name: 'description', value: group.description, onChange: actions.onChangeDescription })))),
        React.createElement(CardFooter, null, "\u00A0")));
};
