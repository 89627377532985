var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { toAddressString } from '../../../../../lib/utils';
import { parseTSMarkdown } from '../../../../../lib/formatters';
import { CardBody } from '../../../../touring-ui/components/cards';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { AddToGroupButton } from './addToGroup';
import { useApplicationCtx } from '../../../../../context/context';
import { deletePlace, getPlaceImageURL, savePlace } from '../../../../../api/internal/places';
import { useDialogDispatchCtx } from '../../../atoms/dialog/context';
import { Alert } from '../../../../touring-ui/components/alerts';
import { useModalDispatchCtx } from '../../../atoms/modal/context';
import { PlaceEdit } from './edit';
import { ChevronLeftIcon, Edit03Icon, GoogleSmallIcon, HeartIcon, LinkExternal01Icon, PlusIcon, Trash01Icon, TripAdvisorLogoIcon, Umbrella03Icon, } from '../../../../touring-ui/icons/_export';
import { Anchor } from '../../../../touring-ui/components/anchor';
import { useTranslation } from 'react-i18next';
import { WaypointActivitiesIndex } from '../../waypoint/detail/activities';
import { Button } from '../../../../touring-ui/components/buttons';
import { Tooltip } from '../../../../touring-ui/components/tooltip';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { Text } from '../../../../touring-ui/components/typography/text';
import { usePlacesDispatchCtx } from '../context';
import { Debug } from '../../debug';
import { ReminderKeys, useReminderCoordinator } from '../../reminders';
export const PlaceDetailIndex = (props) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const context = useApplicationCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const modalDispatch = useModalDispatchCtx();
    const [place, setPlace] = useState(props.place);
    const [show, setShow] = useState({ type: 'general' });
    const placeDispatch = usePlacesDispatchCtx();
    const reminders = useReminderCoordinator();
    const tripadvisor = (_a = place.relations) === null || _a === void 0 ? void 0 : _a.find((item) => item.name == 'tripadvisor');
    const imageReference = (_b = place.relations) === null || _b === void 0 ? void 0 : _b.find((item) => item.name == 'image');
    const actions = {
        onDelete: () => __awaiter(void 0, void 0, void 0, function* () {
            if (confirm(t('deleteConfirm'))) {
                try {
                    if (!place._id)
                        throw new Error('Place not found');
                    const response = yield deletePlace(place._id);
                    if (response) {
                        placeDispatch({ type: 'REMOVE_PLACE', id: place._id });
                        modalDispatch({ type: 'REMOVE_NODE' });
                    }
                }
                catch (error) {
                    dialogDispatch({ type: 'SET_NODE', node: React.createElement(Alert, { type: 'error' }, error.message) });
                }
            }
        }),
        onEdit: () => {
            if (!place.location || !place._id)
                return;
            modalDispatch({
                type: 'SET_NODE',
                title: 'Bearbeiten',
                nodeType: { type: 'place', id: place._id, subType: 'info' },
                node: React.createElement(PlaceEdit, { place: place }),
            });
        },
        onSave: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                placeDispatch({ type: 'REMOVE_TMP_PLACE' });
                const response = yield savePlace(place);
                placeDispatch({ type: 'ADD_PLACE', place: response });
                setPlace(response);
            }
            catch (error) {
                dialogDispatch({ type: 'SET_NODE', node: React.createElement(Alert, { type: 'error' }, error.message) });
            }
        }),
        onDeleteTmp: () => __awaiter(void 0, void 0, void 0, function* () {
            placeDispatch({ type: 'REMOVE_TMP_PLACE' });
            modalDispatch({ type: 'REMOVE_NODE' });
        }),
    };
    return (React.createElement("div", { className: 'text-sm' },
        React.createElement("div", { className: 'px-4 py-3' },
            show.type == 'general' && (React.createElement(Flexbox, { direction: 'horizontal', gap: 'lg' },
                React.createElement(Tooltip, { node: t('addToTour') },
                    React.createElement(AddToGroupButton, { place: props.place },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-indigo-600' },
                            React.createElement(PlusIcon, { className: 'w-4 h-4' })))),
                React.createElement(Tooltip, { node: t('nearbyActivities') },
                    React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => setShow({ type: 'activities' }) },
                        React.createElement(Umbrella03Icon, { className: 'w-4 h-4' }))),
                ((_c = props.place.contact) === null || _c === void 0 ? void 0 : _c.website) && (React.createElement(Tooltip, { node: t('website') },
                    React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => {
                            var _a;
                            window.open((_a = props.place.contact) === null || _a === void 0 ? void 0 : _a.website, '_blank');
                        } },
                        React.createElement(LinkExternal01Icon, { className: 'w-4 h-4' })))),
                React.createElement(Tooltip, { node: t('googleSearch') },
                    React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => {
                            window.open(`https://www.google.com/search?q=${props.place.title}${props.place.address ? ', ' + toAddressString(props.place.address) : ''}`, '_blank');
                        } },
                        React.createElement(GoogleSmallIcon, { className: 'w-4 h-4' }))),
                tripadvisor && (React.createElement(Tooltip, { node: 'Tripadvisor' },
                    React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => window.open(`https://tripadvisor.de/${tripadvisor.value}`, '_blank') },
                        React.createElement(TripAdvisorLogoIcon, { className: 'h-4' })))),
                React.createElement("div", { className: 'flex-grow' }),
                context.user && context.user._id == place.user && (React.createElement(React.Fragment, null,
                    React.createElement(Tooltip, { node: t('edit') },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: actions.onEdit },
                            React.createElement(Edit03Icon, { className: 'w-4 h-4' }))),
                    React.createElement(Tooltip, { node: t('delete') },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: actions.onDelete },
                            React.createElement(Trash01Icon, { className: 'w-4 h-4' }))))),
                !place.user && (React.createElement(React.Fragment, null,
                    React.createElement(Tooltip, { node: t('addToMyPlaces') },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => context.user
                                ? actions.onSave()
                                : reminders.showReminder(ReminderKeys.SignUp) },
                            React.createElement(HeartIcon, { className: 'w-4 h-4' }))))))),
            show.type == 'activities' && (React.createElement(Anchor, { onClick: () => setShow({ type: 'general' }) },
                React.createElement(Flexbox, { direction: 'horizontal', gap: 'sm', position: 'center' },
                    React.createElement(ChevronLeftIcon, { className: 'w-4 h-4' }),
                    React.createElement("div", { className: 'text-indigo-600' }, t('goBack')))))),
        React.createElement(Divider, null),
        React.createElement(CardBody, null,
            show.type == 'general' && (React.createElement("div", { className: 'text-black dark:text-white' },
                place.image && React.createElement("img", { src: getPlaceImageURL(place), className: "w-full rounded-md" }),
                imageReference && imageReference.value && (React.createElement(Text, { className: 'text-xs text-slate-300 pt-1' },
                    t('imageBy'),
                    ": ",
                    imageReference.value)),
                React.createElement("div", { className: "py-2 text-sm flex flex-col gap-1" },
                    place.title && React.createElement("div", { className: "text-lg font-semibold" }, place.title),
                    place.address && (React.createElement("div", { className: `${!place.title ? 'text-lg font-semibold' : ''}` }, toAddressString(place.address))),
                    ((_d = place.contact) === null || _d === void 0 ? void 0 : _d.phone) && (React.createElement("div", null,
                        React.createElement("b", null, "Phone: "),
                        place.contact.phone)),
                    place.location && (React.createElement("div", null,
                        React.createElement("b", null, "Coordinates: "),
                        place.location.coordinates[1].toFixed(6),
                        ",",
                        ' ',
                        place.location.coordinates[0].toFixed(6)))),
                place.description && (React.createElement("div", { className: "py-2 text-sm" },
                    React.createElement("div", { className: "whitespace-pre-wrap", dangerouslySetInnerHTML: {
                            __html: parseTSMarkdown(place.description),
                        } }))),
                React.createElement(Debug, { data: place }))),
            show.type == 'activities' && (React.createElement("div", null, place.location && place.address && (React.createElement(WaypointActivitiesIndex, { location: place.location, address: place.address })))))));
};
