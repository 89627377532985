import React from 'react';
import { Text } from '../../../../touring-ui/components/typography/text';
import { PasscodeIcon } from '../../../../touring-ui/icons/_export';
import { Form } from '../../../../touring-ui/components/form';
import { Input } from '../../../../touring-ui/components/form/input';
import { Button } from '../../../../touring-ui/components/buttons';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
export const UserLoginVerifyCode = (props) => {
    if (props.state != 'verifyCode')
        return React.createElement(React.Fragment, null);
    const { t } = useTranslation();
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
        React.createElement(Text, { type: 'headline' },
            React.createElement(PasscodeIcon, { className: "w-8 h-8" }),
            t('enterCode')),
        React.createElement(Text, null, t('enterCodeInstructions')),
        React.createElement(Form, { onSubmit: (e) => {
                e.preventDefault();
                props.onVerifyCode();
            } },
            React.createElement(Input, { value: props.code, placeholder: "Code", onChange: (e) => props.onSetCode(e.currentTarget.value) }),
            React.createElement(Button, { type: 'primary', size: 'md', className: 'justify-center', onClick: props.onVerifyCode }, t('confirmCode')))));
};
