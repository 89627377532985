import React, { Fragment } from 'react';
import { Combobox } from '@headlessui/react';
import { nanoid } from 'nanoid';
import { useFloating } from '@floating-ui/react';
import { Flexbox } from '../flexbox';
import { ActivityIndicator } from '../acitivityIndicator';
export const Select = (props) => {
    var _a;
    const { refs, floatingStyles } = useFloating({
        placement: 'bottom-start',
    });
    const wrapperClasses = (() => {
        var _a;
        const selectedType = (_a = props.type) !== null && _a !== void 0 ? _a : 'form';
        switch (selectedType) {
            case 'form':
            case 'relative':
                return 'box-border border border-slate-300 focus:!border-indigo-600 w-full rounded-md p-2 outline-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-md disabled:!text-neutral-500 dark:disabled:!text-gray-600';
            case 'search':
                return 'text-sm w-full bg-slate-200 outline-none text-black dark:text-white px-3 py-2 rounded-lg';
            default:
                return 'font-semibold w-full bg-transparent outline-none text-black dark:text-white';
        }
    })();
    const optionClasses = (() => {
        var _a;
        const selectedType = (_a = props.type) !== null && _a !== void 0 ? _a : 'form';
        switch (selectedType) {
            case 'form':
                return 'absolute z-10 shadow-xl';
            case 'search':
                return 'absolute z-10 shadow-xl';
            default:
                return 'mb-2 shadow-md';
        }
    })();
    return (React.createElement("div", { className: 'relative flex-grow' },
        React.createElement(Combobox, { value: props.selected, onChange: props.setSelected },
            React.createElement(Flexbox, { direction: 'horizontal', nowrap: true },
                React.createElement(Combobox.Input, { ref: props.type == 'form' ? refs.setReference : undefined, displayValue: props.renderSelected, onChange: (event) => props.onFilter(event.target.value), className: wrapperClasses, placeholder: props.placeholder, autoFocus: (_a = !props.disableAutoFocus) !== null && _a !== void 0 ? _a : true }),
                props.isLoading && React.createElement(ActivityIndicator, null)),
            !props.isLoading && props.options.length > 0 && (React.createElement(Combobox.Options, { className: `${optionClasses} mt-2 w-full overflow-hidden bg-white dark:bg-neutral-950 rounded-md text-sm font-semibold border border-slate-200 dark:border-neutral-700 divide-y divide-slate-200 dark:divide-neutral-700 flex flex-col`, ref: props.type == 'form' ? refs.setFloating : undefined, style: props.type == 'form' ? floatingStyles : undefined }, props.options.map((option) => (React.createElement(Combobox.Option, { key: nanoid(), value: option, as: Fragment }, ({ active }) => (React.createElement("li", { className: `${active
                    ? 'bg-indigo-600 text-white'
                    : 'bg-white dark:bg-neutral-800 text-black dark:text-white'} p-2 cursor-pointer` }, props.renderOption(option)))))))))));
};
