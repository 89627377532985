import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useApplicationCtx } from '../../../../../context/context';
import { WaypointItemMap } from './item';
import * as turf from '@turf/turf';
import Supercluster from 'supercluster';
import { WaypointCluster } from './cluster';
import { getAllWaypoints, getVisibleAllWaypoints } from '../../../../../context/slices/waypoints';
import { enablePatches } from 'immer';
enablePatches();
export const WaypointMapIndex = (props) => {
    const { map } = props;
    const context = useApplicationCtx();
    if (!context.ui.tourVisible)
        return React.createElement(React.Fragment, null);
    const allWaypoints = getAllWaypoints(context.tour);
    const waypoints = useMemo(() => getVisibleAllWaypoints(context, true), [context.tour.groups]);
    const superclusterRef = useRef();
    const clusters = useRef([]);
    const [activeWaypoints, setActiveWaypoints] = useState([]);
    const getAllMarkers = () => {
        const markers = [];
        waypoints.forEach((waypointDetail) => {
            const waypoint = context.tour.groups[waypointDetail.groupIndex].waypoints[waypointDetail.waypointIndex];
            if (!waypoint.location)
                return;
            markers.push(turf.point(waypoint.location.coordinates, {
                type: waypointDetail.type,
                groupIndex: waypointDetail.groupIndex,
                waypointIndex: waypointDetail.waypointIndex,
                id: waypointDetail.id,
                index: waypointDetail.index,
                location: waypoint.location,
                isLastInGroup: waypointDetail.isLastInGroup,
                previousWaypoint: waypointDetail.previousWaypoint,
                nextWaypoint: waypointDetail.nextWaypoint,
            }));
        });
        return markers;
    };
    const processClustersAndMarkers = () => {
        if (!superclusterRef.current)
            return;
        const zoom = map.getZoom();
        const mapBounds = map.getBounds();
        const turfPolygon = turf.polygon([
            [
                [mapBounds.getWest(), mapBounds.getSouth()],
                [mapBounds.getWest(), mapBounds.getNorth()],
                [mapBounds.getEast(), mapBounds.getNorth()],
                [mapBounds.getEast(), mapBounds.getSouth()],
                [mapBounds.getWest(), mapBounds.getSouth()],
            ],
        ]);
        const bbox = turf.bbox(turfPolygon);
        const response = superclusterRef.current.getClusters(bbox, zoom);
        const newClusters = [];
        const waypointsInClusters = [];
        response.forEach((cluster) => {
            if (!superclusterRef.current)
                return;
            if (cluster.properties.cluster) {
                if (cluster.id) {
                    superclusterRef.current.getLeaves(cluster.id, Infinity).map((leave) => {
                        waypointsInClusters.push(leave.properties.id);
                    });
                    newClusters.push(cluster);
                }
            }
        });
        const existingClusters = clusters.current.map((cluster) => cluster.id);
        const newClusters123 = newClusters.map((cluster) => cluster.id);
        setActiveWaypoints(waypoints.filter((wp) => !waypointsInClusters.includes(wp.id)));
        if (JSON.stringify(existingClusters) != JSON.stringify(newClusters123)) {
            clusters.current = newClusters;
        }
    };
    useEffect(() => {
        if (!map)
            return;
        map.on('zoom', processClustersAndMarkers);
        map.on('move', processClustersAndMarkers);
        return () => {
            map.off('zoom', processClustersAndMarkers);
            map.off('move', processClustersAndMarkers);
        };
    }, [map, context.tour.groups]);
    useEffect(() => {
        superclusterRef.current = new Supercluster({
            radius: 28,
            minZoom: 0,
            maxZoom: 22,
        });
        const markers = getAllMarkers();
        superclusterRef.current.load(markers);
        processClustersAndMarkers();
    }, [context.tour.groups]);
    return (React.createElement(React.Fragment, null,
        clusters.current.map((cluster) => {
            if (!superclusterRef.current)
                return;
            return (React.createElement(WaypointCluster, { key: `cluster-${cluster.id}`, allWaypoints: allWaypoints, cluster: cluster, superCluster: superclusterRef.current, map: map }));
        }),
        activeWaypoints.map((waypointDetails) => {
            return (React.createElement("div", { key: waypointDetails.id },
                React.createElement(WaypointItemMap, { type: waypointDetails.type, waypointIndex: waypointDetails.waypointIndex, groupIndex: waypointDetails.groupIndex, previousWaypoint: waypointDetails.index == 0 ? null : waypointDetails.previousWaypoint, nextWaypoint: waypointDetails.nextWaypoint, isLastInGroup: waypointDetails.isLastInGroup, map: map })));
        })));
};
