var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { useApplicationCtx } from '../../../../context/context';
import { Place } from './item';
import { getPlaces } from '../../../../api/internal/places';
import { getAllWaypoints } from '../../../../context/slices/waypoints';
import Supercluster from 'supercluster';
import * as turf from '@turf/turf';
import { PlaceCluster } from './cluster';
import { usePlacesCtx, usePlacesDispatchCtx } from './context';
import { useModalCtx } from '../../atoms/modal/context';
import { useMapCtx } from '../../atoms/map/context';
export const Places = (props) => {
    const context = useApplicationCtx();
    const waypoints = getAllWaypoints(context.tour);
    const placesCtx = usePlacesCtx();
    const placesDispatch = usePlacesDispatchCtx();
    const mapContext = useMapCtx();
    const modalCtx = useModalCtx();
    const loadPlace = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const mapBounds = props.map.getBounds();
            const response = yield getPlaces([
                [mapBounds.getWest(), mapBounds.getSouth()],
                [mapBounds.getEast(), mapBounds.getNorth()],
            ]);
            placesDispatch({ type: 'SET_PLACES', places: response });
        }
        catch (error) {
            //console.log('Places can not be loaded.', error.message)
        }
    });
    useEffect(() => {
        loadPlace();
    }, []);
    const superclusterRef = useRef();
    const [inCluster, setInCluster] = useState([]);
    const [blacklist, setBlacklist] = useState([]);
    const [clusters, setClusters] = useState([]);
    const getAllMarkers = () => {
        const markers = [];
        const tmpBlackList = [];
        placesCtx.places.forEach((place) => {
            if (!place.location)
                return;
            const result = waypoints.find((waypoint) => {
                var _a, _b;
                if (!place.location || !waypoint.location)
                    return;
                return (waypoint.location.coordinates[0] == ((_a = place.location) === null || _a === void 0 ? void 0 : _a.coordinates[0]) &&
                    waypoint.location.coordinates[1] == ((_b = place.location) === null || _b === void 0 ? void 0 : _b.coordinates[1]));
            });
            if (!result) {
                markers.push(turf.point(place.location.coordinates, place));
            }
            else {
                if (place._id)
                    tmpBlackList.push(place._id);
            }
        });
        setBlacklist(tmpBlackList);
        return markers;
    };
    const processClustersAndMarkers = () => {
        if (!superclusterRef.current)
            return;
        const zoom = props.map.getZoom();
        const mapBounds = props.map.getBounds();
        const turfPolygon = turf.polygon([
            [
                [mapBounds.getWest(), mapBounds.getSouth()],
                [mapBounds.getWest(), mapBounds.getNorth()],
                [mapBounds.getEast(), mapBounds.getNorth()],
                [mapBounds.getEast(), mapBounds.getSouth()],
                [mapBounds.getWest(), mapBounds.getSouth()],
            ],
        ]);
        const bbox = turf.bbox(turfPolygon);
        const response = superclusterRef.current.getClusters(bbox, zoom);
        const newClusters = [];
        const placesInClusters = [];
        response.forEach((cluster) => {
            if (!superclusterRef.current)
                return;
            if (cluster.properties.cluster) {
                if (cluster.id) {
                    superclusterRef.current.getLeaves(cluster.id, Infinity).map((leave) => {
                        if (leave.properties._id) {
                            placesInClusters.push(leave.properties._id);
                        }
                    });
                    newClusters.push(cluster);
                }
            }
        });
        setInCluster(placesInClusters);
        setClusters(newClusters);
    };
    const onZoom = () => {
        var _a;
        if (modalCtx.meta && ((_a = modalCtx.meta) === null || _a === void 0 ? void 0 : _a.type) == 'place')
            return;
        loadPlace();
        return true;
    };
    useEffect(() => {
        if (!props.map)
            return;
        props.map.on('drag', onZoom);
        props.map.on('zoom', onZoom);
        return () => {
            props.map.off('drag', onZoom);
            props.map.off('zoom', onZoom);
        };
    }, [props.map]);
    useEffect(() => {
        if (!props.map)
            return;
        superclusterRef.current = new Supercluster({
            radius: 30,
            minZoom: 0,
            maxZoom: 16,
        });
        const markers = getAllMarkers();
        superclusterRef.current.load(markers);
        processClustersAndMarkers();
    }, [mapContext.zoom, placesCtx.places]);
    return (React.createElement(React.Fragment, null,
        context.ui.placesVisible &&
            superclusterRef.current &&
            clusters.map((cluster) => {
                return (React.createElement(PlaceCluster, { key: cluster.properties._id, cluster: cluster, superCluster: superclusterRef.current, map: props.map }));
            }),
        context.ui.placesVisible &&
            placesCtx.places.map((place) => {
                if (place._id && !inCluster.includes(place._id) && !blacklist.includes(place._id)) {
                    return React.createElement(Place, { key: place._id, map: props.map, place: place });
                }
            })));
};
