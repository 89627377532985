import { useEffect, useState } from 'react';
import { useApplicationCtx } from '../../context';
export const lastWaypointOfPreviousGroup = (groupIndex, tour) => {
    const originGroupIndex = groupIndex - 1;
    if (originGroupIndex != -1) {
        const group = tour.groups[originGroupIndex];
        const lastWaypointIndex = group.waypoints.length - 1;
        if (lastWaypointIndex > -1) {
            return { groupIndex: originGroupIndex, waypointIndex: lastWaypointIndex };
        }
        else {
            return null;
        }
    }
    return null;
};
export const useOrigin = (groupIndex) => {
    const context = useApplicationCtx();
    const [origin, setOrigin] = useState();
    useEffect(() => {
        setOrigin(lastWaypointOfPreviousGroup(groupIndex, context.tour));
    }, [groupIndex, context.tour.groups]);
    return { origin };
};
