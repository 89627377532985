import React, { useState } from 'react';
import { createDraft, finishDraft } from 'immer';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { Label } from '../../../../touring-ui/components/form/label';
import { InputInfo } from '../../../../touring-ui/components/form/info';
import { Textarea } from '../../../../touring-ui/components/form/textarea';
import { InputSection } from '../../../../touring-ui/components/form/inputSection';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
import { toAddressString } from '../../../../../lib/utils';
import { Input } from '../../../../touring-ui/components/form/input';
import { ListBox } from '../../../../touring-ui/components/form/listbox';
import { WaypointGenericSearchAddress } from '../generic/searchAddress';
import { RouteSummary } from '../../route/summary';
import { parseTSMarkdown } from '../../../../../lib/formatters';
import { Anchor } from '../../../../touring-ui/components/anchor';
import { useWaypointGlobalPosition } from '../../../../../context/effects/waypoints/useWaypointGlobalPosition';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { findNextWaypoint, findPreviousWaypoint } from '../../../../../context/slices/waypoints';
import { Coins04Icon, EcoIcon, FastestIcon, FerryIcon, ShortestIcon, ThrillingIcon, TollroadIcon, UnpavedIcon, } from '../../../../touring-ui/icons/_export';
import { Tooltip } from '../../../../touring-ui/components/tooltip';
import { Text } from '../../../../touring-ui/components/typography/text';
import { TimePicker } from '../../calendar';
import { WaypointExtendedAddress } from './extended';
export const WaypointDetailGeneral = (props) => {
    var _a, _b;
    const { t } = useTranslation();
    const context = useApplicationCtx();
    const group = context.tour.groups[props.groupIndex];
    const waypoint = context.tour.groups[props.groupIndex].waypoints[props.waypointIndex];
    const globalWaypointIndex = (_a = useWaypointGlobalPosition(context, waypoint.id)) !== null && _a !== void 0 ? _a : 0;
    const previousWaypointDetails = findPreviousWaypoint(context.tour, waypoint.id);
    const previousWaypoint = previousWaypointDetails
        ? context.tour.groups[previousWaypointDetails.groupIndex].waypoints[previousWaypointDetails.waypointIndex]
        : null;
    const nextWaypointDetails = findNextWaypoint(context.tour, waypoint.id);
    const nextWaypoint = nextWaypointDetails
        ? context.tour.groups[nextWaypointDetails.groupIndex].waypoints[nextWaypointDetails.waypointIndex]
        : null;
    const dispatch = useApplicationDispatchCtx();
    const actions = {
        onUpdate: (updatedWaypoint) => {
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: props.groupIndex,
                waypoint: updatedWaypoint,
                withoutMiddleware: false,
            });
        },
        onChangeTitle: (e) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.title = e.currentTarget.value;
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: props.groupIndex,
                waypoint: finishDraft(updatedWaypoint),
                withoutMiddleware: true,
            });
        },
        onChangeWebsite: (e) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.website = e.currentTarget.value;
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: props.groupIndex,
                waypoint: finishDraft(updatedWaypoint),
                withoutMiddleware: true,
            });
        },
        onChangeTripadvisor: (e) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.tripadvisor = e.currentTarget.value;
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: props.groupIndex,
                waypoint: finishDraft(updatedWaypoint),
                withoutMiddleware: true,
            });
        },
        onChangeImage: (e) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.image = e.currentTarget.value;
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: props.groupIndex,
                waypoint: finishDraft(updatedWaypoint),
                withoutMiddleware: true,
            });
        },
        onChangeNote: (e) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.notes = e.currentTarget.value;
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: props.groupIndex,
                waypoint: finishDraft(updatedWaypoint),
                withoutMiddleware: true,
            });
        },
        onChangeStay: (seconds) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.summary.breakInSeconds = seconds;
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(updatedWaypoint) });
        },
        onChangeTravelTime: (seconds) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.summary.travelTimeInSeconds = seconds;
            if (previousWaypoint) {
                const prevDepartureTime = previousWaypoint.summary.departureTime;
                const arrivalTime = prevDepartureTime + seconds * 1000;
                const departureTime = arrivalTime + updatedWaypoint.summary.breakInSeconds * 1000;
                updatedWaypoint.summary.arrivalTime = arrivalTime;
                updatedWaypoint.summary.departureTime = departureTime;
                if (updatedWaypoint.lineString) {
                    updatedWaypoint.lineString.properties.arrivalTime = arrivalTime;
                    updatedWaypoint.lineString.properties.departureTime = departureTime;
                    updatedWaypoint.lineString.properties.travelTimeInSeconds = seconds;
                    updatedWaypoint.lineString.properties.breakInSeconds = 0;
                }
            }
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(updatedWaypoint) });
        },
        onSetWaypointType: (option) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.type = option[0];
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(updatedWaypoint) });
        },
        onSetTravelMode: (option) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.config.travelMode = option[0];
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(updatedWaypoint) });
        },
        onSetRouteType: (key) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.config.routeType = key;
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(updatedWaypoint) });
        },
        onToggleAvoidOption: (key) => {
            const avoid = [...waypoint.config.avoid];
            const index = avoid.indexOf(key);
            if (index === -1) {
                avoid.push(key);
            }
            else {
                avoid.splice(index, 1);
            }
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.config.avoid = avoid;
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(updatedWaypoint) });
        },
        onSetAddress: (selected) => {
            var _a, _b;
            const draft = createDraft(waypoint);
            draft.address = selected.address;
            draft.location = selected.location;
            draft.title = (_b = (_a = selected.poi) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : undefined;
            dispatch({ type: 'UPDATE_WAYPOINT', groupIndex: props.groupIndex, waypoint: finishDraft(draft) });
        },
    };
    const waypointTypeOptions = [
        ['default', t('default_waypoint_type')],
        ['hotel', t('hotel', { count: 1 })],
        ['restaurant', t('restaurant', { count: 1 })],
        ['tourist_attraction', t('touristAttraction', { count: 1 })],
        ['parking_lot', t('parking_lot')],
        ['flight', t('flight')],
    ];
    const [editNotes, setEditNotes] = useState(!waypoint.notes);
    const [editImage, setEditImage] = useState(!waypoint.image);
    const canEditTravelTime = waypoint.config.travelMode == 'airplane' || waypoint.config.travelMode == 'other';
    const canEditStay = globalWaypointIndex.index > 0;
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'text-sm' },
        !props.hideExtendedRouteOptions &&
            waypoint.config.travelMode != 'airplane' &&
            globalWaypointIndex.index > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'relative' },
                React.createElement("div", { className: 'flex flex-row gap-5' },
                    React.createElement("div", { className: 'flex flex-row gap-3' },
                        React.createElement(Text, { className: 'font-semibold' }, t('calculate')),
                        React.createElement(Tooltip, { node: t('fastest') },
                            React.createElement(FastestIcon, { className: `w-5 h-5 cursor-pointer ${'fastest' == waypoint.config.routeType
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onSetRouteType('fastest') })),
                        React.createElement(Tooltip, { node: t('shortest') },
                            React.createElement(ShortestIcon, { className: `w-5 h-5 cursor-pointer ${'shortest' == waypoint.config.routeType
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onSetRouteType('shortest') })),
                        React.createElement(Tooltip, { node: t('eco') },
                            React.createElement(EcoIcon, { className: `w-5 h-5 cursor-pointer ${'eco' == waypoint.config.routeType
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onSetRouteType('eco') })),
                        React.createElement(Tooltip, { node: t('thrilling') },
                            React.createElement(ThrillingIcon, { className: `w-5 h-5 cursor-pointer ${'thrilling' == waypoint.config.routeType
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onSetRouteType('thrilling') }))),
                    React.createElement("div", { className: 'flex-grow' }),
                    React.createElement("div", { className: 'flex flex-row gap-3' },
                        React.createElement(Text, { className: 'font-semibold' }, t('avoid')),
                        React.createElement(Tooltip, { node: t('tollRoads') },
                            React.createElement(Coins04Icon, { className: `w-5 h-5 cursor-pointer ${waypoint.config.avoid.includes('tollRoads')
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onToggleAvoidOption('tollRoads') })),
                        React.createElement(Tooltip, { node: t('motorways') },
                            React.createElement(TollroadIcon, { className: `w-5 h-5 cursor-pointer ${waypoint.config.avoid.includes('motorways')
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onToggleAvoidOption('motorways') })),
                        React.createElement(Tooltip, { node: t('ferries') },
                            React.createElement(FerryIcon, { className: `w-5 h-5 cursor-pointer ${waypoint.config.avoid.includes('ferries')
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onToggleAvoidOption('ferries') })),
                        React.createElement(Tooltip, { node: t('unpavedRoads') },
                            React.createElement(UnpavedIcon, { className: `w-5 h-5 cursor-pointer ${waypoint.config.avoid.includes('unpavedRoads')
                                    ? 'text-indigo-600'
                                    : 'text-slate-300'}`, onClick: () => actions.onToggleAvoidOption('unpavedRoads') })))),
                waypoint.lineString == null && (React.createElement("div", { className: 'absolute top-0 right-0 bottom-0 left-0 flex place-content-center place-items-center bg-white/90' },
                    React.createElement(Text, { className: 'font-semibold' }, t('loadingRoute'))))),
            React.createElement(Divider, null))),
        React.createElement(React.Fragment, null,
            React.createElement(RouteSummary, Object.assign({ timezone: waypoint.timezone, waypointIndex: props.waypointIndex, groupDepartureTime: group.summary.departureTime, previousWaypoint: previousWaypoint, nextWaypoint: nextWaypoint, isLastInGroup: group.waypoints.length - 1 == props.waypointIndex }, waypoint.summary))),
        React.createElement(Divider, null),
        React.createElement(InputSection, null,
            React.createElement("div", { className: 'flex flex-row' },
                React.createElement(Label, null, t('address')),
                React.createElement("div", { className: 'flex-grow' }),
                React.createElement(Anchor, { onClick: () => {
                        window.open(`https://www.google.com/search?q=${waypoint.title ? waypoint.title + ', ' : ''}${waypoint.address ? toAddressString(waypoint.address) : ''}`, '_blank');
                    } }, t('learnMore'))),
            React.createElement(WaypointGenericSearchAddress, { disableAutoFocus: true, type: 'relative', onSetAddress: (selected) => actions.onSetAddress(selected), selected: {
                    address: waypoint.address,
                    location: waypoint.location,
                } })),
        React.createElement(Divider, null),
        React.createElement(WaypointExtendedAddress, { waypoint: waypoint, onUpdate: actions.onUpdate }),
        React.createElement(Divider, null),
        React.createElement("div", { className: `grid gap-5 grid-cols-2` },
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('title')),
                React.createElement(Input, { type: 'text', value: (_b = waypoint.title) !== null && _b !== void 0 ? _b : '', onChange: actions.onChangeTitle })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, t('type')),
                React.createElement(ListBox, { type: 'form', renderSelected: (option) => option[1], selected: waypointTypeOptions.find((option) => option[0] == waypoint.type), setSelected: actions.onSetWaypointType, options: waypointTypeOptions, renderOption: (option) => option[1] }))),
        React.createElement(Divider, null),
        (canEditStay || canEditTravelTime) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `grid gap-5 ${canEditTravelTime ? 'grid-cols-2' : 'grid-cols-1'}` },
                canEditTravelTime && (React.createElement(InputSection, null,
                    React.createElement(Label, null, t('travelTime')),
                    React.createElement(TimePicker, { selectedTime: waypoint.summary.travelTimeInSeconds * 1000, onChange: (newTime) => {
                            actions.onChangeTravelTime(newTime / 1000);
                        }, zone: 'UTC' }))),
                canEditStay && (React.createElement(InputSection, null,
                    React.createElement(Label, null, t('stay')),
                    React.createElement(TimePicker, { selectedTime: waypoint.summary.breakInSeconds * 1000, onChange: (newTime) => {
                            actions.onChangeStay(newTime / 1000);
                        }, zone: 'UTC' })))),
            React.createElement(Divider, null))),
        React.createElement(InputSection, null,
            React.createElement("div", { className: 'grid grid-cols-2' },
                React.createElement("div", null,
                    React.createElement(Label, null, t('notes'))),
                React.createElement("div", { className: 'text-right' },
                    React.createElement(Anchor, { onClick: () => setEditNotes(!editNotes) }, editNotes ? t('preview') : t('edit')))),
            !editNotes && waypoint.notes && (React.createElement("div", { className: 'text-sm text-black text-balance whitespace-pre-wrap hyphens-auto border border-slate-300  dark:border-neutral-700 dark:text-white p-2 rounded-md', dangerouslySetInnerHTML: {
                    __html: parseTSMarkdown(waypoint.notes),
                } })),
            editNotes && (React.createElement(React.Fragment, null,
                React.createElement(InputInfo, null, t('addHelpfulNotes')),
                React.createElement(Textarea, { name: 'description', value: waypoint.notes, onChange: actions.onChangeNote })))),
        React.createElement(Divider, null),
        React.createElement(InputSection, null,
            React.createElement("div", { className: 'grid grid-cols-2' },
                React.createElement("div", null,
                    React.createElement(Label, null, t('image'))),
                React.createElement("div", { className: 'text-right' },
                    React.createElement(Anchor, { onClick: () => setEditImage(!editImage) }, editImage ? t('preview') : t('edit')))),
            editImage && (React.createElement(React.Fragment, null,
                React.createElement(InputInfo, null, t('addImageGuide')),
                React.createElement(Input, { type: 'text', value: waypoint.image, onChange: actions.onChangeImage }))),
            !editImage && waypoint.image && React.createElement("img", { src: waypoint.image, className: 'w-full rounded-md' }))));
};
