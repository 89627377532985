import React, { useEffect, useState } from 'react';
import { autoPlacement, offset, useClick, useDismiss, useFloating, useHover, useInteractions, } from '@floating-ui/react';
export const Tooltip = (props) => {
    var _a, _b;
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            autoPlacement({
                allowedPlacements: (_a = props.allowedPlacements) !== null && _a !== void 0 ? _a : [
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'top',
                    'top-start',
                    'top-end',
                ],
            }),
            offset((_b = props.offset) !== null && _b !== void 0 ? _b : 5),
        ],
    });
    const hover = useHover(context, { delay: 200, enabled: !props.showOnClick });
    const click = useClick(context, { enabled: props.showOnClick });
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, hover]);
    useEffect(() => {
        if (props.onClick)
            props.onClick(isOpen);
    }, [isOpen]);
    return (React.createElement("div", { className: `relative` },
        React.createElement("div", Object.assign({ ref: refs.setReference }, getReferenceProps()), props.children),
        isOpen && (React.createElement("div", Object.assign({ ref: refs.setFloating, style: floatingStyles, className: 'p-2 bg-white shadow rounded-md font-medium drop-shadow-xl z-50 pointer-events-auto' }, getFloatingProps()), props.node))));
};
