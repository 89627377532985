import React, { useEffect } from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../context/context';
import { initNewTour } from '../../interfaces/tour';
import { useLoaderData, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../ui/components/atoms/modal/context';
import { LayoutMap } from '../../ui/components/molecules/layout/map';
import { TourManager } from '../../ui/components/organisms/tourManager';
import { Map } from '../../ui/components/organisms/map';
import { PlaceSearch } from '../../ui/components/molecules/place/search';
import { UAParser } from 'ua-parser-js';
import { LayoutDefault } from '../../ui/components/molecules/layout/default';
import { useDialogDispatchCtx } from '../../ui/components/atoms/dialog/context';
import { AddOrigin } from '../../ui/components/molecules/tour/floating/components/addOrigin';
import { MapContext } from '../../ui/components/atoms/map/context';
import { ThirdPartyPlacesContext } from '../../ui/components/molecules/thirdPartyPlaces/context';
import { PlacesContext } from '../../ui/components/molecules/place/context';
import { UserLoginIndex } from '../../ui/components/molecules/user/login';
import { MeToursPage } from '../../ui/components/molecules/tour/tours';
import { DialogCoordinator } from '../../ui/components/organisms/dialogCoordinator';
import { Alert } from '../../ui/touring-ui/components/alerts';
import { MascotImage } from '../../ui/touring-ui/icons/_export';
import { EventTrackingTypes, track } from '../../lib/eventTracking';
export const TourPage = () => {
    const i18n = useTranslation();
    const { t } = i18n;
    const context = useApplicationCtx();
    const loader = useLoaderData();
    const { tour, user } = loader !== null && loader !== void 0 ? loader : { tour: null };
    const { ui } = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const location = useLocation();
    const dialogDispatch = useDialogDispatchCtx();
    const parser = new UAParser();
    const isMobileOrTablet = parser.getDevice().type == 'mobile' || parser.getDevice().type == 'tablet';
    const actions = {
        isPublic: /^\/tour\/([^\/]+)\/public$/.exec(location.pathname),
        loadExisitingTour: (tour) => {
            if (actions.isPublic) {
                dialogDispatch({ type: 'SET_NODE', node: React.createElement(AddOrigin, null) });
            }
            dispatch({
                type: 'UPDATE_TOUR',
                tour,
                withoutMiddleWare: true,
            });
        },
        loadNewTour: () => {
            dispatch({
                type: 'UPDATE_TOUR',
                tour: initNewTour(i18n.t, user, ui.defaultTravelMode),
                withoutMiddleWare: false,
            });
        },
    };
    useEffect(() => {
        track(EventTrackingTypes.LoadWebApp);
    }, []);
    useEffect(() => {
        dispatch({ type: 'UPDATE_UI', ui: Object.assign(Object.assign({}, ui), { inSync: true }) });
        if (context.tour.id == (tour === null || tour === void 0 ? void 0 : tour.id))
            return;
        if (tour) {
            actions.loadExisitingTour(tour);
        }
        else {
            actions.loadNewTour();
        }
    }, [tour]);
    if (isMobileOrTablet) {
        return (React.createElement(LayoutDefault, { isMobileOrTablet: true },
            React.createElement("div", null,
                !context.user && (React.createElement("div", null,
                    React.createElement(Alert, { type: 'warning', className: 'items-center mb-6' }, "Melde dich an um deine Touren zu sehen."),
                    React.createElement(UserLoginIndex, { onDone: () => { } }))),
                context.user && (React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement("div", { className: 'text-lg font-semibold' }, t('tour', { count: 2 })),
                    React.createElement("div", { className: 'flex flex-row gap-5 border border-amber-500 p-3 rounded-lg items-center bg-amber-100' },
                        React.createElement(MascotImage, { className: 'w-20 h-20 flex-none' }),
                        React.createElement("div", { className: 'text-sm' },
                            React.createElement("b", null, "Zurzeit kannst du Touren nur auf deinem Computer oder Laptop planen."),
                            " Nutze den Reisef\u00FChrer um eine Tour die du bereits geplant hast zu starten.")),
                    React.createElement(MeToursPage, { phoneMode: true }))))));
    }
    else {
        return (React.createElement(MapContext, null,
            React.createElement(PlacesContext, null,
                React.createElement(ThirdPartyPlacesContext, null,
                    React.createElement(ModalContext, null,
                        React.createElement(LayoutMap, { searchComponent: React.createElement(PlaceSearch, null) },
                            React.createElement(TourManager, null),
                            React.createElement(Map, null),
                            React.createElement(DialogCoordinator, null)))))));
    }
};
