import React, { createContext, useContext } from 'react';
import { produce } from 'immer';
import { useImmerReducer } from 'use-immer';
const setNode = (ctx, action) => {
    ctx.node = action.node;
    ctx.title = action.title;
    ctx.maxWidth = action.maxWidth;
    ctx.onClose = action.onClose;
};
const removeNode = (ctx) => {
    ctx.node = null;
    ctx.title = undefined;
    ctx.maxWidth = undefined;
    ctx.onClose = undefined;
};
const DialogInitialContext = {
    node: null,
};
const reducer = produce((ctx, action) => {
    switch (action.type) {
        case 'SET_NODE':
            setNode(ctx, action);
            break;
        case 'REMOVE_NODE':
            removeNode(ctx);
            break;
    }
});
const DialogContextProvider = createContext(DialogInitialContext);
const DialogDispatchCtx = createContext(() => { });
export const useDialogCtx = () => useContext(DialogContextProvider);
export const useDialogDispatchCtx = () => useContext(DialogDispatchCtx);
export const DialogContext = (props) => {
    const [ctx, dispatch] = useImmerReducer(reducer, DialogInitialContext);
    return (React.createElement(DialogContextProvider.Provider, { value: ctx },
        React.createElement(DialogDispatchCtx.Provider, { value: dispatch }, props.children)));
};
