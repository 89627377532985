/* @refresh overlay */

import React from 'react'
import { createRoot } from 'react-dom/client'
import '../public/assets/styles.css'
import '../public/assets/fonts/inter-tight/fonts.css'
import '../public/assets/fonts/inter/fonts.css'
import 'simplebar-react/dist/simplebar.min.css'
import { ApplicationContextProvider } from './context/context'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { NotFound } from './pages/notFound'
import './lib/i18n'
import { TourPage } from './pages/tour'
import { loaderPublic, loaderPrivate, loaderUserPrivate } from './pages/loaders/tours'
import { TourGuidePage } from './pages/tour/guide'
import { PageIndex } from './pages'
import 'animate.css'
import LoadDate from './lib/loadDate'
import { LoginPage } from './pages/user/login'
import { PaymentSuccess } from './pages/payment/success'
import { PaymentCancel } from './pages/payment/cancel'

LoadDate.getInstance()

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: (
                <PageIndex>
                    <TourPage />
                </PageIndex>
            ),
            loader: loaderPublic,
            errorElement: <NotFound />,
        },
        {
            path: '/tour/:id',
            element: (
                <PageIndex>
                    <TourPage />
                </PageIndex>
            ),
            loader: loaderPrivate,
            errorElement: <NotFound />,
        },
        {
            path: '/tour/:id/guide',
            element: (
                <PageIndex>
                    <TourGuidePage />
                </PageIndex>
            ),
            loader: loaderPrivate,
            errorElement: <NotFound />,
        },
        {
            path: '/payment/success',
            loader: loaderUserPrivate,
            element: (
                <PageIndex>
                    <PaymentSuccess />
                </PageIndex>
            ),
            errorElement: <NotFound />,
        },
        {
            path: '/payment/cancel',
            loader: loaderUserPrivate,
            element: (
                <PageIndex>
                    <PaymentCancel />
                </PageIndex>
            ),
            errorElement: <NotFound />,
        },
        {
            path: '/login',
            loader: loaderPublic,
            element: (
                <PageIndex>
                    <LoginPage />
                </PageIndex>
            ),
            errorElement: <NotFound />,
        },
    ],

    { basename: process.env['REACT_ROUTER_BASE_PATH'] }
)

const root = createRoot(document.getElementById('app'))

root.render(
    <ApplicationContextProvider>
        <RouterProvider router={router} />
    </ApplicationContextProvider>
)
