import React from 'react';
import { Loading02Icon } from '../../icons/_export';
import { Text } from '../typography/text';
import { Flexbox } from '../flexbox';
export const ActivityIndicator = (props) => (React.createElement(Text, { type: 'footnote' },
    React.createElement(Loading02Icon, { className: 'w-4 h-4 animate-spin' }),
    " ",
    props.children));
export const AcitivityIndicatorPreview = () => (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'mb-8' },
    React.createElement("a", { id: 'activity' }),
    React.createElement(Text, { type: 'subheadline' }, "Activity Indicator"),
    React.createElement("hr", null),
    React.createElement(ActivityIndicator, null)));
