var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// tourApi.ts
import axios from 'axios';
import Pako from 'pako';
import { getAccessToken } from '../../lib/accessToken';
import FileDownload from 'js-file-download';
import { handleApiErrorResponse } from './apiErrorHandler';
const baseURL = process.env['TOUR_API_BASE_URL'];
export function getTours() {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/tours/`;
        try {
            const response = yield axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                },
            });
            return response.data.map((tour) => mergeRemoteTourToTour(tour));
        }
        catch (error) {
            // Handle error (e.g., log or throw an exception)
            console.error('Error fetching tour details:', error.message);
            throw error;
        }
    });
}
export function saveTour(tour) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/tours`;
        try {
            const compressed = Pako.deflate(JSON.stringify(tour));
            const response = yield axios.post(url, compressed, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Encoding': 'deflate',
                    'Content-Type': 'application/json',
                },
            });
            return mergeRemoteTourToTour(response.data);
        }
        catch (error) {
            handleApiErrorResponse(error);
            return null;
        }
    });
}
export function updateTour(tour) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/tours/${tour.id}`;
        try {
            const compressed = Pako.deflate(JSON.stringify(tour));
            const response = yield axios.put(url, compressed, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Encoding': 'deflate',
                    'Content-Type': 'application/json',
                },
            });
            return mergeRemoteTourToTour(response.data);
        }
        catch (error) {
            handleApiErrorResponse(error);
            return null;
        }
    });
}
// Function to fetch tour details by ID
export function getTourById(tourId) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/tours/${tourId}`;
        try {
            const response = yield axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return mergeRemoteTourToTour(response.data);
        }
        catch (error) {
            handleApiErrorResponse(error);
            return null;
        }
    });
}
// Function to fetch tour details by ID
export function downloadTourById(tourId, title) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/tours/${tourId}/download`;
        try {
            const response = yield axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                responseType: 'blob',
            });
            FileDownload(response.data, `${title}.gpx`);
        }
        catch (error) {
            handleApiErrorResponse(error);
            return;
        }
    });
}
export function deleteTour(tourId) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/tours/${tourId}`;
        try {
            yield axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                },
            });
            return true;
        }
        catch (error) {
            handleApiErrorResponse(error);
            return false;
        }
    });
}
export function mergeRemoteTourToTour(source) {
    const tour = Object.assign({}, source);
    const tourId = tour._id;
    // @ts-ignore
    delete tour._id;
    const converted = tour;
    converted.id = tourId;
    converted.groups.map((group) => {
        group.isCollapsed = false;
        return group;
    });
    return converted;
}
export const getTimeZone = (point) => __awaiter(void 0, void 0, void 0, function* () {
    if (!point)
        return;
    const response = yield axios.get(`https://tours.api.touring.studio/tz?lat=${point.coordinates[1]}&lng=${point.coordinates[0]}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.data.tz[0]) {
        return response.data.tz[0];
    }
    else {
        return;
    }
});
