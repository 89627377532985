import React, { useState } from 'react';
import { BedIcon, CalendarCheck02Icon, Expand01Icon, File05Icon, Flag03Icon, FoodIcon, Home02Icon, MarkerPin01Icon, MarkerPin05Icon, ParkingIcon, PlaneIcon, ZoomInIcon, } from '../../../../../touring-ui/icons/_export';
import { Text } from '../../../../../touring-ui/components/typography/text';
import { Flexbox } from '../../../../../touring-ui/components/flexbox';
import { useModalCtx } from '../../../../atoms/modal/context';
export const WaypointIcon = (props) => {
    var _a, _b;
    const modalContext = useModalCtx();
    const variant = (() => {
        var _a;
        return (_a = props.variant) !== null && _a !== void 0 ? _a : 'stack';
    })();
    const sizeWrapper = (() => {
        var _a;
        switch ((_a = props.size) !== null && _a !== void 0 ? _a : 'md') {
            case 'xs':
                return 'w-6 h-6';
            case 'sm':
                return 'w-8 h-8';
            case 'md':
                return 'w-10 h-10';
            case 'lg':
                return 'w-12 h-12';
        }
    })();
    const size = (() => {
        var _a;
        switch ((_a = props.size) !== null && _a !== void 0 ? _a : 'md') {
            case 'xs':
                return 'w-3 h-3';
            case 'sm':
                return 'w-4 h-4';
            case 'md':
                return 'w-4 h-4';
            case 'lg':
                return 'w-8 h-8';
        }
    })();
    const [hover, setHover] = useState(false);
    const isSelected = ((_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.type) == 'waypoint' && ((_b = modalContext.meta) === null || _b === void 0 ? void 0 : _b.id) == props.id;
    return (React.createElement(React.Fragment, null,
        variant == 'horizontal' && (React.createElement(Flexbox, { nowrap: true },
            React.createElement("div", { className: `${sizeWrapper} flex flex-none items-center justify-center rounded-full text-white border-2 cursor-pointer relative bg-white dark:bg-neutral-800`, style: { borderColor: props.color }, onMouseOver: () => setHover(true), onMouseOut: () => setHover(false), onClick: props.onClick },
                React.createElement(Text, null,
                    props.index == 1 && React.createElement(Home02Icon, { className: `${size}` }),
                    props.index != 1 && (React.createElement(React.Fragment, null,
                        props.type == 'default' && (React.createElement(React.Fragment, null,
                            !props.isLast && !props.isLastInGroup && (React.createElement(MarkerPin01Icon, { className: `${size}` })),
                            !props.isLast && props.isLastInGroup && (React.createElement(MarkerPin05Icon, { className: `${size}` })),
                            props.isLast && React.createElement(Flag03Icon, { className: `${size}` }))),
                        props.type == 'parking_lot' && React.createElement(ParkingIcon, { className: `${size}` }),
                        props.type == 'hotel' && React.createElement(BedIcon, { className: `${size}` }),
                        props.type == 'restaurant' && React.createElement(FoodIcon, { className: `${size}` }),
                        props.type == 'tourist_attraction' && (React.createElement(CalendarCheck02Icon, { className: `${size}` })),
                        props.type == 'flight' && React.createElement(PlaneIcon, { className: `${size}` }))))),
            React.createElement("div", { className: 'relative' },
                isSelected && (React.createElement("div", { className: `absolute ${sizeWrapper} animate-ping bg-rose-600 rounded-full` })),
                React.createElement("div", { className: `${sizeWrapper} relative flex flex-none items-center justify-center rounded-full text-white font-semibold text-xs`, style: { backgroundColor: props.color } }, props.index)))),
        variant == 'stack' && (React.createElement("div", { className: `${sizeWrapper} flex flex-none items-center justify-center rounded-full text-white border-2 cursor-pointer relative bg-white dark:bg-neutral-800 drop-shadow-xl`, style: { borderColor: props.color }, onMouseOver: () => setHover(true), onMouseOut: () => setHover(false), onClick: props.onClick },
            !hover && (React.createElement(Text, null,
                props.index == 1 && React.createElement(Home02Icon, { className: `${size}` }),
                props.index != 1 && (React.createElement(React.Fragment, null,
                    props.type == 'default' && (React.createElement(React.Fragment, null,
                        !props.isLast && !props.isLastInGroup && (React.createElement(MarkerPin01Icon, { className: `${size}` })),
                        !props.isLast && props.isLastInGroup && (React.createElement(MarkerPin05Icon, { className: `${size}` })),
                        props.isLast && React.createElement(Flag03Icon, { className: `${size}` }))),
                    props.type == 'parking_lot' && React.createElement(ParkingIcon, { className: `${size}` }),
                    props.type == 'hotel' && React.createElement(BedIcon, { className: `${size}` }),
                    props.type == 'restaurant' && React.createElement(FoodIcon, { className: `${size}` }),
                    props.type == 'tourist_attraction' && (React.createElement(CalendarCheck02Icon, { className: `${size}` })),
                    props.type == 'flight' && React.createElement(PlaneIcon, { className: `${size}` }))))),
            hover && (React.createElement(Text, { className: 'ts_animation_bounceIn' },
                props.icon == 'more' && React.createElement(Expand01Icon, { className: `${size}` }),
                (props.icon == 'zoom' || !props.icon) && React.createElement(ZoomInIcon, { className: `${size}` }))),
            React.createElement("div", { className: 'absolute -top-2 -right-2 ' },
                isSelected && React.createElement("div", { className: 'absolute w-5 h-5 animate-ping bg-rose-600 rounded-full' }),
                React.createElement("div", { className: 'relative rounded-full w-5 h-5 flex items-center justify-center place-content-center text-xs font-semibold', style: { backgroundColor: props.color } }, props.index)),
            props.hasNotes && (React.createElement("div", { className: 'absolute -bottom-2 -right-2 rounded-full w-5 h-5 flex items-center justify-center place-content-center text-xs font-semibold bg-pink-500 dark:bg-neutral-800' },
                React.createElement(File05Icon, { className: 'w-3 h-3 text-white' })))))));
};
