var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text } from '../../../../touring-ui/components/typography/text';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { Button } from '../../../../touring-ui/components/buttons';
import { Card, CardBody, CardFooter } from '../../../../touring-ui/components/cards';
import { useTranslation } from 'react-i18next';
import { AddToGroupButton } from '../../place/generic/addToGroup';
import { Debug } from '../../debug';
import { savePlace } from '../../../../../api/internal/places';
import { Alert } from '../../../../touring-ui/components/alerts';
import { useDialogDispatchCtx } from '../../../atoms/dialog/context';
import { toAddressString } from '../../../../../lib/utils';
import { Tooltip } from '../../../../touring-ui/components/tooltip';
import { GoogleSmallIcon, HeartIcon, LinkExternal01Icon, PlusIcon } from '../../../../touring-ui/icons/_export';
import { usePlacesDispatchCtx } from '../../place/context';
import { WBK } from 'wikibase-sdk';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { zoomToPlaces } from '../../../../../lib/zoom';
import { useThirdPartyPlaceSelectedDispatchCtx } from '../context';
import { createDraft, finishDraft } from 'immer';
export default function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref]);
    useEffect(() => {
        if (!ref.current)
            return;
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);
    return isIntersecting;
}
export const POI = (props) => {
    var _a, _b, _c;
    const { t, i18n } = useTranslation();
    const dialogDispatch = useDialogDispatchCtx();
    const placeDispatch = usePlacesDispatchCtx();
    const [wikiData, setWikiData] = useState(null);
    const [wikiImage, setWikiImage] = useState(null);
    const wikidataID = (_a = props.place.relations) === null || _a === void 0 ? void 0 : _a.find((item) => item.name == 'wikidata');
    const debounceRef = useRef(debounce(() => zoomToPlaces([props.place]), 500));
    const tpDispatch = useThirdPartyPlaceSelectedDispatchCtx();
    const actions = {
        onAdd: (place) => __awaiter(void 0, void 0, void 0, function* () {
            place.description = undefined;
            return place;
        }),
        onSave: () => __awaiter(void 0, void 0, void 0, function* () {
            var _d;
            try {
                const place = createDraft(props.place);
                delete place._id;
                delete place.user;
                place.address.freeformAddress = toAddressString(props.place.address);
                place.relations = (_d = props.place.relations) === null || _d === void 0 ? void 0 : _d.filter((item) => { var _a; return ['tripadvisor', 'image'].includes((_a = item.name) !== null && _a !== void 0 ? _a : ''); });
                place.state = {
                    public: false,
                };
                const response = yield savePlace(finishDraft(place));
                placeDispatch({ type: 'ADD_PLACE', place: response });
            }
            catch (error) {
                dialogDispatch({ type: 'SET_NODE', node: React.createElement(Alert, { type: 'error' }, error.message) });
            }
        }),
        loadImages: (category) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield axios.get(`https://commons.wikimedia.org/w/api.php?action=query&list=categorymembers&cmtitle=${category}&cmtype=file&format=json&origin=*`);
            if (response.data.query && response.data.query.categorymembers) {
                const images = response.data.query.categorymembers.reverse();
                if (images[0]) {
                    const imageData = yield axios.get(`https://en.wikipedia.org/w/api.php?action=query&titles=${images[0].title}&prop=imageinfo&iiurlwidth=720&iiprop=url%7Csize&format=json&origin=*`);
                    setWikiImage(imageData.data.query.pages['-1'].imageinfo[0].thumburl);
                }
            }
        }),
        loadWikiData: () => __awaiter(void 0, void 0, void 0, function* () {
            if (!wikidataID || !wikidataID.value)
                return;
            const wdk = WBK({
                instance: 'https://www.wikidata.org',
                sparqlEndpoint: 'https://query.wikidata.org/sparql',
            });
            const url = wdk.getEntities({ ids: [wikidataID.value], redirects: false });
            const response = yield axios.get(url);
            const data = response.data.entities[wikidataID.value];
            if (data.sitelinks && data.sitelinks.commonswiki) {
                yield actions.loadImages(data.sitelinks.commonswiki.title);
            }
            setWikiData(data);
        }),
        onMouseEnter: () => {
            if (props.disableZoom)
                return;
            tpDispatch({ type: 'SET_SELECTED', data: { place: props.place } });
            debounceRef.current();
        },
        onMouseLeave: () => {
            if (props.disableZoom)
                return;
            tpDispatch({ type: 'SET_SELECTED', data: { place: null } });
            debounceRef.current.cancel();
        },
    };
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    useEffect(() => {
        actions.loadWikiData();
    }, [isVisible]);
    return (React.createElement("div", { ref: ref, onMouseEnter: actions.onMouseEnter, onMouseLeave: actions.onMouseLeave },
        React.createElement(Card, null,
            React.createElement(CardBody, null,
                React.createElement(Flexbox, { direction: 'horizontal', nowrap: true, gap: 'md' },
                    React.createElement(Flexbox, { direction: 'vertical', gap: 'sm', className: 'flex-grow' },
                        props.place.image && props.place.image.url && (React.createElement("div", { className: 'mt-2 mb-2 rounded-2xl overflow-hidden' },
                            React.createElement("div", { style: { backgroundImage: `url(${props.place.image.url})` }, className: 'h-96 w-full bg-cover' }))),
                        wikiImage && (React.createElement("div", { className: 'mt-2 mb-2 rounded-2xl overflow-hidden' },
                            React.createElement("div", { style: { backgroundImage: `url(${wikiImage})` }, className: 'h-96 w-full bg-cover' }))),
                        React.createElement(Text, { type: 'footnote', className: 'font-semibold' }, props.place.title),
                        props.place.address && (React.createElement(Text, { type: 'footnote' }, toAddressString(props.place.address))),
                        ((_b = props.place.location) === null || _b === void 0 ? void 0 : _b.coordinates) && (React.createElement(Text, { type: 'footnote' },
                            React.createElement("span", { className: 'font-semibold' }, t('coordinates')),
                            props.place.location.coordinates[1],
                            ", ",
                            props.place.location.coordinates[0])),
                        (wikiData === null || wikiData === void 0 ? void 0 : wikiData.descriptions) && (React.createElement(React.Fragment, null,
                            (wikiData === null || wikiData === void 0 ? void 0 : wikiData.descriptions[i18n.language]) && (React.createElement(Text, null, wikiData === null || wikiData === void 0 ? void 0 : wikiData.descriptions[i18n.language].value)),
                            !(wikiData === null || wikiData === void 0 ? void 0 : wikiData.descriptions[i18n.language]) && (wikiData === null || wikiData === void 0 ? void 0 : wikiData.descriptions.en) && (React.createElement(Text, null, wikiData === null || wikiData === void 0 ? void 0 : wikiData.descriptions.en.value))))))),
            React.createElement(CardFooter, null,
                React.createElement(Flexbox, { direction: 'horizontal', position: 'center', gap: 'lg' },
                    React.createElement(Tooltip, { node: t('addToTour') },
                        React.createElement(AddToGroupButton, { place: props.place, middleware: actions.onAdd },
                            React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-indigo-600' },
                                React.createElement(PlusIcon, { className: 'w-4 h-4' })))),
                    React.createElement(Tooltip, { node: t('addToMyPlaces') },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: actions.onSave },
                            React.createElement(HeartIcon, { className: 'w-4 h-4' }))),
                    ((_c = props.place.contact) === null || _c === void 0 ? void 0 : _c.website) && (React.createElement(Tooltip, { node: t('website') },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => {
                                var _a;
                                window.open((_a = props.place.contact) === null || _a === void 0 ? void 0 : _a.website, '_blank');
                            } },
                            React.createElement(LinkExternal01Icon, { className: 'w-4 h-4' })))),
                    React.createElement(Tooltip, { node: t('googleSearch') },
                        React.createElement(Button, { type: 'secondary', size: 'sm', className: 'text-slate-600 dark:text-indigo-300', onClick: () => {
                                var _a, _b;
                                window.open(`https://www.google.com/search?q=${props.place.title}, ${(_a = props.place.location) === null || _a === void 0 ? void 0 : _a.coordinates[1]}:${(_b = props.place.location) === null || _b === void 0 ? void 0 : _b.coordinates[0]}${props.place.address ? ', ' + toAddressString(props.place.address) : ''}`, '_blank');
                            } },
                            React.createElement(GoogleSmallIcon, { className: 'w-4 h-4' })))))),
        React.createElement(Debug, { data: props.place })));
};
