import React from 'react';
import { PlusCircleIcon } from '../../icons/_export';
import { Text } from '../typography/text';
import { Flexbox } from '../flexbox';
export const Button = (props) => {
    const { children, className } = props;
    const size = (() => {
        var _a;
        const selectedSize = (_a = props.size) !== null && _a !== void 0 ? _a : 'sm';
        switch (selectedSize) {
            case 'sm':
                return `py-1 px-3 rounded-md text-sm`;
            case 'md':
                return `py-2 px-6 rounded-md text-md`;
            case 'lg':
                return `py-2 px-9 rounded-md text-md`;
            case 'xs':
                return 'text-sm';
        }
    })();
    const type = (() => {
        var _a;
        const selectedColor = (_a = props.type) !== null && _a !== void 0 ? _a : 'transparent';
        switch (selectedColor) {
            case 'primary':
                return `bg-indigo-600 border border-indigo-500 text-white dark:border-indigo-500`;
            case 'secondary':
                return `bg-slate-50 border border-slate-300 text-black dark:bg-neutral-800 dark:border-neutral-700 dark:text-white`;
            case 'accent':
                return `bg-gradient-to-r from-amber-500 to-yellow-500 border border-amber-500 text-white `;
            case 'transparent':
                return `border-2 border-transparent dark:text-white`;
        }
    })();
    return (React.createElement("button", { className: `${size} ${type} ${className} box-border font-semibold hover:opacity-70 flex flex-initial items-center gap-1 disabled:opacity-20`, onClick: props.onClick, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave, disabled: props.disabled }, children));
};
export const ButtonPreview = () => (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'mb-8' },
    React.createElement("a", { id: 'buttons' }),
    React.createElement(Text, { type: 'subheadline' }, "Buttons"),
    React.createElement("hr", null),
    React.createElement(Flexbox, { direction: 'horizontal', gap: 'lg' },
        React.createElement(Button, { type: 'primary', size: 'sm' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A small button"),
        React.createElement(Button, { type: 'secondary', size: 'sm' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A small button"),
        React.createElement(Button, { type: 'transparent', size: 'sm' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A small button")),
    React.createElement(Flexbox, { direction: 'horizontal', gap: 'lg' },
        React.createElement(Button, { type: 'primary', size: 'md' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A medium button"),
        React.createElement(Button, { type: 'secondary', size: 'md' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A medium button"),
        React.createElement(Button, { type: 'transparent', size: 'md' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A medium button")),
    React.createElement(Flexbox, { direction: 'horizontal', gap: 'lg' },
        React.createElement(Button, { type: 'primary', size: 'lg' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A nice large button"),
        React.createElement(Button, { type: 'secondary', size: 'lg' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A nice large button"),
        React.createElement(Button, { type: 'transparent', size: 'lg' },
            React.createElement(PlusCircleIcon, { className: 'w-4 h-4' }),
            "A nice large button"))));
