export const tutorials = [
    {
        video: '8ZVA0OcFqKw',
        headline: 'Willkommen bei touring.studio',
        description: 'Hallo, ich bin Emmi, deine persönliche Assistentin für deine Tourenplanung. Ich freue mich darauf, dich bei deiner Reise zu unterstützen. Bevor du startest, möchte ich dir zunächst die Grundlagen von touring.studio zeigen, damit du gleich einen Überblick bekommst.\n\n**Lass uns loslegen!**',
    },
    {
        video: 'u_9DQWjJskg',
        headline: 'Wegpunkte und Tage hinzufügen',
        description: 'Bei touring.studio hast du die Möglichkeit deine Tour nach Tagen zu planen.\n' +
            '\n' +
            'Lass uns damit beginnen, einen Tag zu erstellen und diesem Wegpunkte hinzuzufügen.\n' +
            '\n' +
            'Um einen Wegpunkt hinzuzufügen, klicke auf das **„+“-Symbol**. Jetzt kannst du nach einem Ort oder einer bestimmten Adresse suchen und diesen mit einem Klick oder der „Enter“-Taste bestätigen. Wenn du einen nächsten Tag erstellen möchtest, klicke auf **„Tag hinzufügen“**.',
    },
    {
        video: 'N1UtNfhK9No',
        headline: 'Transportmittel',
        description: 'Als nächstes wähle das Transportmittel mit welchem du unterwegs sein wirst. Egal ob „Zu Fuß“, mit dem „Auto“, „Motorrad“, oder „Flugzeug“, je nach Transportmittel passt sich deine Reisedauer an. Wenn du ein anderes Transportmittel nützen möchtest, welches du nicht in der Liste findest, kannst du das „?“-Symbol nutzen. Bedenke, dass du hier dann aber im Bearbeitungsmodus deine Reisezeit selbst anpassen musst, damit du einen Gesamtüberblick über deine Reisedauer bekommen kannst. \n' +
            '\n' +
            'Um in den Bearbeitungsmodus zu gelangen, klicke auf das **„Stift“-Symbol**.' +
            '\n\n' +
            '**Ein Tipp von mir:** Reisezeiten können wichtig sein, um zu verstehen, wie viel du an einem Tag unternehmen willst oder kannst.',
    },
    {
        video: 'ZXSzr3WQQoM',
        headline: 'Wegpunkt bearbeiten',
        description: 'Mit dem **„Stift“-Symbol** kannst du auch deine Wegpunkte bearbeiten. Beispielsweise kannst du den Titel, den Typ, deine Aufenthaltsdauer sowie auch Notizen oder ein Bild hinzufügen.\n' +
            '\n' +
            'Ein Titel ist oft übersichtlicher als nur eine Adresse. Wenn du einen Typ ergänzt, kannst du schnell erkennen, ob es sich um ein Hotel, eine Attraktion oder zum Beispiel um ein Restaurant handelt. \n' +
            '\n' +
            'Das Notizfeld kann dir helfen nützliche Informationen beispielsweise über Öffnungszeiten, Preise oder andere Besonderheiten festzuhalten.',
    },
    {
        video: 'mf_xuGZfXhw',
        headline: 'Aktivitäten in der Nähe',
        description: 'Falls du noch nicht wissen solltest wo es hingeht, oder welches Hotel oder Restaurant du besuchen möchtest, nutze die Funktion **„Aktivitäten in der Nähe“**.' +
            '\n' +
            '\n' +
            'Mit einem Klick auf "Hotels" siehst du alle Unterkünfte, welche es in der Umgebung gibt. Wenn du dich für ein Hotel entschieden hast, leitet dich unsere Seite mit einem Klick auf dieses, direkt zum Anbieter weiter. Füge die Adresse deines gebuchten Hotels danach deiner Tour hinzu. \n' +
            '\n' +
            'Möchtest du ein Restaurant in deiner Nähe suchen, klicke auf "Restaurants". Du hast auch die Möglichkeit den Radius zu erweitern. ' +
            '\n' +
            '\n' +
            'Als nächstes kannst du dir ansehen, welche Attraktionen es in der Nähe gibt. Möchtest du über eine Attraktion mehr erfahren, klicke auf das "Google"-Symbol und sieh dir diese genauer an. Bei einigen Attraktionen gelangst du auch direkt auf deren Webseite oder kannst Tripadvisor öffnen. Wenn du etwas in deine Tour integrieren möchtest, kannst du das ganz einfach mit einem Klick **„Zur Tour hinzufügen“**.',
    },
    {
        video: 'TFKKj7duvQI',
        headline: 'Orte',
        description: 'Mit einem **Doppelklick auf der Karte**, kannst du auch Orte markieren, welche du noch nicht sofort zu deiner Tour hinzufügen willst. Auch hier hast du die Möglichkeit Aktivitäten in der Nähe anzusehen, mit Klick auf das "Google"-Symbol über den Ort mehr zu erfahren und/oder dir den Ort einfach mit einem Klick auf „Zu meinen Orten hinzufügen“ zu merken. Du kannst deine gemerkten Orte auch bearbeiten und ihnen einen Titel geben oder einen Typ zuordnen. Diese Orte sind dann auf deiner Karte ersichtlich.\n' +
            '\n' +
            'Ein Hinweis von mir: Wenn du Orte mit einem grünen Häkchen siehst, werden dir diese von touring.studio empfohlen und wurden verifiziert. Wenn du die Orte nicht mehr sehen möchtest, kannst du diese ausblenden.\n',
    },
    {
        video: 'p6VlxMOuPuY',
        headline: 'Tour speichern',
        description: 'Vergiss nicht deine Tour in regelmäßigen Abständen zu **speichern**, um deinen Fortschritt nicht zu verlieren. Nachdem du diese gespeichert hast, findest du sie unter dem Menüpunkt **„Meine Touren“**.',
    },
    {
        video: 'iFzB8CDkgoE',
        headline: 'Auf Handy übertragen',
        description: 'Wenn du deine Reiseplanung abgeschlossen hast, kannst du dir diese mit dem Reiseführer auf deinem mobilen Gerät ansehen. Klicke hierzu auf den Namen deiner Tour und scanne den **QR-Code** oder kopiere den angeführten Link.' +
            '\n' +
            '\n' +
            'Der **Reiseführer** begleitet dich die ganze Zeit auf deiner Tour und du kannst mit ihm ganz einfach zu deinen geplanten Wegpunkten mittels Google oder Apple Maps navigieren. \n' +
            '\n' +
            'Ebenfalls kannst du auf deine Notizen und Reisedaten zugreifen und während deiner Tour Wegpunkte auf erledigt setzen. Wenn du angemeldet bist, findest du den Reiseführer auch unter „Meine Touren“. Beachte jedoch, dass sich deine Route mit den genannten Navigationssystemen ändern kann. Wenn du deine Route wie geplant navigieren möchtest, lade dir deine Tour als **GPX-Datei** herunter. Gehe dazu auf „Meine Touren“ und klicke auf „Als GPX herunterladen“ und importiere dir diese in ein Navigationssystem deiner Wahl. touring studio empfiehlt dir hierzu, die App OsmAnd.\n',
    },
    {
        video: 'sICJUzM5RRI',
        headline: 'Du bist startklar',
        description: 'Nun hast du einen ersten Einblick in die Funktionen und Möglichkeiten von touring.studio erhalten.\n' +
            '\n' +
            'Falls du Unterstützung benötigst oder diese Einführung nochmals ansehen möchtest, kannst du mich gerne mit einem Klick auf "Hilfe" erneut aufrufen oder eine E-Mail an info@touring.studio schreiben.\n' +
            '\n' +
            'Ich wünschen dir viel Spaß und Inspiration beim Planen deiner Tour!',
    },
];
