import { Card, CardBody } from '../../../touring-ui/components/cards';
import React, { useState } from 'react';
import { startCheckout } from '../../../../api/internal/auth';
import { Product } from '../../../../interfaces/products';
import { Divider } from '../../../touring-ui/components/general/divider';
import { Anchor } from '../../../touring-ui/components/anchor';
import { Input } from '../../../touring-ui/components/form/input';
import { useTranslation } from 'react-i18next';
export const PurchaseBanner = () => {
    const { t } = useTranslation();
    const [coupon, setCoupon] = useState('');
    return (React.createElement(Card, { className: '!bg-indigo-600 !text-white' },
        React.createElement(CardBody, null,
            React.createElement("div", { className: 'flex flex-row gap-5' },
                React.createElement("div", { className: 'flex flex-col gap-3' },
                    React.createElement("div", { className: 'text-lg font-semibold' }, t('purchaseBannerTitle')),
                    React.createElement("div", { className: 'text-balance ' }, t('purchaseBannerDescription')),
                    React.createElement("div", { className: 'text-black' },
                        React.createElement(Input, { placeholder: t('couponCode'), value: coupon, onChange: (item) => setCoupon(item.currentTarget.value) }))),
                React.createElement("div", { className: 'flex flex-col gap-3 ' },
                    React.createElement("button", { onClick: () => startCheckout(Product.MONTHLY, coupon), className: "w-full rounded-lg border-2 border-white px-10 py-2 text-sm font-semibold bg-indigo-600 hover:bg-indigo-500 text-white" }, t('subscribeMonthlyTier1')),
                    React.createElement("button", { onClick: () => startCheckout(Product.YEARLY, coupon), className: "w-full rounded-lg border-2 border-black px-10 py-2 text-sm font-semibold bg-black hover:bg-black/90 text-white" }, t('subscribeYearlyTier1')))),
            React.createElement("div", { className: 'py-2' },
                React.createElement(Divider, null)),
            React.createElement("div", { className: 'flex gap-5 opacity-75' },
                React.createElement(Anchor, { href: 'https://touring.studio/terms-of-service/', target: '_blank', className: '!text-white' }, t('termsAndConditions')),
                React.createElement(Anchor, { href: 'https://touring.studio/privacy-policy/', target: '_blank', className: '!text-white' }, t('privacyPolicy'))))));
};
