import { nanoid } from 'nanoid';
import { getColorForIndex } from '../../lib/color';
import i18n from '../../lib/i18n';
import { DateTime } from 'luxon';
import { createDraft, finishDraft, original, produce } from 'immer';
import { Middleware } from '../middlewares/middleware';
export const findGroupById = (groups, id) => groups.find((group) => group.id === id);
export const findGroupIndexById = (groups, id) => groups.findIndex((group) => group.id === id);
export const newGroup = (id, index, departureDateTime, waypoints) => {
    return {
        id,
        title: `${i18n.t('day')} ${index + 1}`,
        color: getColorForIndex(index),
        summary: {
            arrivalTime: 0,
            departureTime: departureDateTime !== null && departureDateTime !== void 0 ? departureDateTime : DateTime.utc().set({ hour: 7, minute: 0 }).plus({ days: index }).toMillis(),
            lengthInMeters: 0,
            travelTimeInSeconds: 0,
            breakInSeconds: 0,
        },
        isCollapsed: true,
        waypoints: waypoints !== null && waypoints !== void 0 ? waypoints : [],
    };
};
export const addGroup = (ctx, action) => {
    var _a;
    const id = (_a = action.id) !== null && _a !== void 0 ? _a : nanoid();
    const index = ctx.tour.groups.length;
    const previousGroup = ctx.tour.groups[index - 1];
    let departureDateTime = DateTime.utc().set({ hour: 7, minute: 0 }).plus({ days: index }).toMillis();
    if (previousGroup) {
        departureDateTime = DateTime.fromMillis(previousGroup.summary.departureTime)
            .toUTC()
            .plus({ days: 1 })
            .set({ hour: 7, minute: 0 })
            .toMillis();
    }
    const group = newGroup(id, index, departureDateTime);
    return produce(ctx, (draft) => {
        draft.tour.groups.forEach((group, index) => {
            draft.tour.groups[index].isCollapsed = false;
        });
        draft.ui.inSync = false;
        draft.tour.groups.splice(index, 0, group);
    });
};
export const updateGroup = (ctx, action) => {
    var _a;
    const withoutMiddleware = (_a = action.withoutMiddleware) !== null && _a !== void 0 ? _a : false;
    const { id, title, description, color, summary, image, isCollapsed } = action.group;
    const draftTour = createDraft(ctx.tour);
    const originalTour = original(draftTour);
    const group = draftTour.groups.find((gr) => gr.id == id);
    if (isCollapsed) {
        draftTour.groups.forEach((group, index) => {
            draftTour.groups[index].isCollapsed = false;
        });
    }
    if (group) {
        group.title = title;
        group.description = description;
        group.image = image;
        group.color = color;
        group.summary = summary;
        group.isCollapsed = isCollapsed;
    }
    if (!withoutMiddleware) {
        Middleware(draftTour, originalTour);
    }
    const updatedTour = finishDraft(draftTour);
    return produce(ctx, (draft) => {
        draft.ui.inSync = false;
        draft.tour = updatedTour;
    });
};
export const removeGroup = (ctx, action) => {
    const draftTour = createDraft(ctx.tour);
    const originalTour = original(draftTour);
    const rgri = draftTour.groups.findIndex((gr) => gr.id == action.id);
    if (rgri !== -1) {
        draftTour.groups.splice(rgri, 1);
    }
    Middleware(draftTour, originalTour);
    const updatedTour = finishDraft(draftTour);
    return produce(ctx, (draft) => {
        draft.ui.inSync = false;
        draft.tour = updatedTour;
    });
};
