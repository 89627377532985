import React, { createContext, useContext } from 'react';
import { produce } from 'immer';
import { useImmerReducer } from 'use-immer';
const set = (ctx, action) => {
    ctx.places = action.data.places.sort((a, b) => {
        var _a, _b, _c, _d;
        const aDistance = (_a = a.relations) === null || _a === void 0 ? void 0 : _a.find((item) => item.name == 'distance');
        const bDistance = (_b = b.relations) === null || _b === void 0 ? void 0 : _b.find((item) => item.name == 'distance');
        if (!(aDistance === null || aDistance === void 0 ? void 0 : aDistance.value) || !(bDistance === null || bDistance === void 0 ? void 0 : bDistance.value))
            return 0;
        return ((_c = parseFloat(aDistance === null || aDistance === void 0 ? void 0 : aDistance.value)) !== null && _c !== void 0 ? _c : 0) - ((_d = parseFloat(bDistance === null || bDistance === void 0 ? void 0 : bDistance.value)) !== null && _d !== void 0 ? _d : 0);
    });
};
const ThirdPartyPlacesInitialContext = {
    places: [],
};
const dndReducer = produce((ctx, action) => {
    switch (action.type) {
        case 'SET':
            set(ctx, action);
            break;
    }
});
const ThirdPartyPlacesContextProvider = createContext(ThirdPartyPlacesInitialContext);
const ThirdPartyPlacesDispatchCtx = createContext(() => { });
export const useThirdPartyPlacesCtx = () => useContext(ThirdPartyPlacesContextProvider);
export const useThirdPartyPlacesDispatchCtx = () => useContext(ThirdPartyPlacesDispatchCtx);
const setSelected = (ctx, action) => {
    ctx.selected = action.data.place;
};
const ThirdPartyPlaceSelectedInitialContext = {
    selected: null,
};
const ThirdPartyPlaceSelectedReducer = produce((ctx, action) => {
    switch (action.type) {
        case 'SET_SELECTED':
            setSelected(ctx, action);
            break;
    }
});
const ThirdPartyPlaceSelectedContextProvider = createContext(ThirdPartyPlaceSelectedInitialContext);
const ThirdPartyPlaceSelectedDispatchCtx = createContext(() => { });
export const useThirdPartyPlaceSelectedPlacesCtx = () => useContext(ThirdPartyPlaceSelectedContextProvider);
export const useThirdPartyPlaceSelectedDispatchCtx = () => useContext(ThirdPartyPlaceSelectedDispatchCtx);
export const ThirdPartyPlacesContext = (props) => {
    const [ctx, dispatch] = useImmerReducer(dndReducer, ThirdPartyPlacesInitialContext);
    const [ctxSelected, dispatchSelected] = useImmerReducer(ThirdPartyPlaceSelectedReducer, ThirdPartyPlaceSelectedInitialContext);
    return (React.createElement(ThirdPartyPlaceSelectedContextProvider.Provider, { value: ctxSelected },
        React.createElement(ThirdPartyPlaceSelectedDispatchCtx.Provider, { value: dispatchSelected },
            React.createElement(ThirdPartyPlacesContextProvider.Provider, { value: ctx },
                React.createElement(ThirdPartyPlacesDispatchCtx.Provider, { value: dispatch }, props.children)))));
};
