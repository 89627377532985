import React from 'react';
import { WaypointIcon } from '../../tour/floating/waypoint/icon';
import { ActivityIndicator } from '../../../../touring-ui/components/acitivityIndicator';
export const WaypointMapMarker = (props) => {
    var _a, _b;
    return (React.createElement(React.Fragment, null,
        !props.loading && (React.createElement(WaypointIcon, { id: props.waypoint.id, color: (_b = (_a = props.group) === null || _a === void 0 ? void 0 : _a.color) !== null && _b !== void 0 ? _b : '#000', index: props.index + 1, travelModel: props.waypoint.config.travelMode, type: props.waypoint.type, isLast: props.isLastItem, isLastInGroup: props.isLastInGroup, hasNotes: props.waypoint.notes ? props.waypoint.notes.length > 0 : false, icon: 'more' })),
        props.loading && (React.createElement("div", { className: `bg-white rounded-full cursor-pointer p-3 flex !z-30 relative` },
            React.createElement(ActivityIndicator, null)))));
};
