import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../i18n/en.json';
import de from '../i18n/de.json';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: { translation: en },
    de: { translation: de },
};
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
    resources,
    fallbackLng: 'en',
    lng: navigator.language,
    interpolation: {
        escapeValue: false,
    },
    updateMissing: true,
});
export default i18n;
