import React from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { createDraft, finishDraft } from 'immer';
import { Car02Icon, MotorcycleIcon, PlaneIcon, WalkIcon, } from '../../../../touring-ui/icons/_export';
export const TravelModeConfig = (props) => {
    var _a;
    if (!props.waypointDetails)
        return React.createElement(React.Fragment, null);
    const details = props.waypointDetails;
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const waypoint = context.tour.groups[details.groupIndex].waypoints[details.waypointIndex];
    if (!((_a = waypoint.location) === null || _a === void 0 ? void 0 : _a.coordinates))
        return React.createElement(React.Fragment, null);
    const actions = {
        onSetTravelMode: (travelMode) => {
            const updatedWaypoint = createDraft(waypoint);
            updatedWaypoint.config.travelMode = travelMode;
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: details.groupIndex,
                waypoint: finishDraft(updatedWaypoint),
            });
        },
    };
    return (React.createElement("div", { className: 'px-3 bg-slate-50 border border-slate-300 dark:bg-amber-300 dark:border-amber-500 rounded-xl pointer-events-auto text-sm flex flex-row gap-2 items-center' },
        React.createElement("button", { className: `flex items-center ${waypoint.config.travelMode == 'pedestrian'
                ? 'text-indigo-600'
                : 'text-slate-400 hover:text-indigo-600'}`, onClick: () => actions.onSetTravelMode('pedestrian') },
            React.createElement(WalkIcon, { className: 'w-4 h-4' })),
        React.createElement("button", { className: `flex items-center ${waypoint.config.travelMode == 'car' ? 'text-indigo-600' : 'text-slate-400 hover:text-indigo-600'}`, onClick: () => actions.onSetTravelMode('car') },
            React.createElement(Car02Icon, { className: 'w-4 h-4' })),
        React.createElement("button", { className: `flex items-center ${waypoint.config.travelMode == 'motorcycle'
                ? 'text-indigo-600'
                : 'text-slate-400 hover:text-indigo-600'}`, onClick: () => actions.onSetTravelMode('motorcycle') },
            React.createElement(MotorcycleIcon, { className: 'w-4 h-4' })),
        React.createElement("button", { className: `flex items-center ${waypoint.config.travelMode == 'airplane'
                ? 'text-indigo-600'
                : 'text-slate-400 hover:text-indigo-600'}`, onClick: () => actions.onSetTravelMode('airplane') },
            React.createElement(PlaneIcon, { className: 'w-4 h-4' })),
        React.createElement("button", { className: `flex items-center ${waypoint.config.travelMode == 'other' ? 'text-indigo-600' : 'text-slate-400 hover:text-indigo-600'}`, onClick: () => actions.onSetTravelMode('other') },
            React.createElement("div", { className: 'text-sm font-semibold' }, "?"))));
};
