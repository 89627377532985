import React, { useState } from 'react';
import { Flexbox } from '../flexbox';
import { Text } from '../typography/text';
import { Input } from './input';
import { InputSection } from './inputSection';
import { Label } from './label';
import { createDraft, finishDraft } from 'immer';
import { Switch } from './switch';
import { Select } from './select';
import { InputInfo } from './info';
export const Form = (props) => (React.createElement("form", Object.assign({}, props),
    React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' }, props.children)));
export const FormPreview = () => {
    const options = [
        { key: 'at', value: 'Austria' },
        { key: 'de', value: 'Germany' },
    ];
    const [formData, setFormData] = useState({
        field1: '',
        field2: 'Wien, Österreich',
        switch1: false,
        switch2: true,
    });
    const handleChange = (form) => {
        const field = form.currentTarget;
        const draft = createDraft(formData);
        draft[field.name] = field.value;
        setFormData(finishDraft(draft));
    };
    const handleSwitchChange = (name, isEnabled) => {
        const draft = createDraft(formData);
        draft[name] = isEnabled;
        setFormData(finishDraft(draft));
    };
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'mb-14' },
        React.createElement("a", { id: 'forms' }),
        React.createElement(Text, { type: 'subheadline' }, "Forms"),
        React.createElement("hr", null),
        React.createElement(Form, null,
            React.createElement(InputSection, null,
                React.createElement(Label, null, "Placeholder"),
                React.createElement(Input, { type: 'text', name: 'field1', placeholder: 'Placeholder', value: formData['field1'], onChange: handleChange }),
                React.createElement(InputInfo, null, "Inform user about input. Can also have error type.")),
            React.createElement(InputSection, null,
                React.createElement(Label, null, "With Value"),
                React.createElement(Input, { type: 'text', name: 'field2', placeholder: 'Placeholder', value: formData['field2'], onChange: handleChange }),
                React.createElement(InputInfo, { type: 'error' }, "Inform user about input. Can also have error type.")),
            React.createElement(InputSection, null,
                React.createElement(Label, null, "Disabled"),
                React.createElement(Input, { type: 'text', name: 'field2', placeholder: 'Placeholder', value: formData['field2'], onChange: handleChange, disabled: true })),
            React.createElement(InputSection, null,
                React.createElement(Label, null, "Select"),
                React.createElement(Select, { placeholder: 'Nach einem Ort suchen…', renderSelected: (selected) => selected.value, selected: options[0], setSelected: () => { }, options: options, renderOption: (option) => option.value, onFilter: (value) => { } })),
            React.createElement(Flexbox, { direction: 'horizontal', gap: 'xl' },
                React.createElement(InputSection, { direction: 'horizontal' },
                    React.createElement(Label, null, "Toggle Switch"),
                    React.createElement(Switch, { enabled: formData['switch1'], onChange: (isEnabled) => handleSwitchChange('switch1', isEnabled) }, "My Switch")),
                React.createElement(InputSection, { direction: 'horizontal' },
                    React.createElement(Label, null, "Toggle Switch"),
                    React.createElement(Switch, { enabled: formData['switch2'], onChange: (isEnabled) => handleSwitchChange('switch2', isEnabled) }, "My Switch"))))));
};
