import React from 'react';
import { Flexbox } from '../../../../../touring-ui/components/flexbox';
import { WaypointIcon } from '../../../tour/floating/waypoint/icon';
import { Text } from '../../../../../touring-ui/components/typography/text';
import { trimText } from '../../../../../../lib/formatters';
import { toAddressString } from '../../../../../../lib/utils';
import { Edit03Icon } from '../../../../../touring-ui/icons/_export';
import { useApplicationCtx } from '../../../../../../context/context';
import { useWaypointGlobalPosition } from '../../../../../../context/effects/waypoints/useWaypointGlobalPosition';
import { useModalDispatchCtx } from '../../../../atoms/modal/context';
import { WaypointDetailIndex } from '../../detail';
export const ClusterItem = (props) => {
    var _a;
    const { waypointIndex, groupIndex } = props.leave.properties;
    const context = useApplicationCtx();
    const group = context.tour.groups[groupIndex];
    const waypoint = context.tour.groups[groupIndex].waypoints[waypointIndex];
    const globalPosition = useWaypointGlobalPosition(context, waypoint.id);
    const modalDispatch = useModalDispatchCtx();
    const actions = {
        onEdit: () => {
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: { type: 'waypoint', id: waypoint.id, additional: { groupIndex, waypointIndex } },
                node: React.createElement(WaypointDetailIndex, Object.assign({}, props.leave.properties)),
            });
        },
    };
    return (React.createElement("div", { key: waypoint.id },
        React.createElement(Flexbox, { position: 'center', gap: 'md', className: 'py-1' },
            React.createElement(WaypointIcon, { id: waypoint.id, size: 'xs', variant: 'horizontal', color: (_a = group === null || group === void 0 ? void 0 : group.color) !== null && _a !== void 0 ? _a : '#000', index: globalPosition.index + 1, travelModel: waypoint.config.travelMode, type: waypoint.type }),
            React.createElement(Text, { className: 'flex-grow' }, trimText(waypoint.title ? waypoint.title : waypoint.address ? toAddressString(waypoint.address) : '')),
            React.createElement("div", { className: 'relative' },
                React.createElement(Edit03Icon, { className: 'w-4 h-4 cursor-pointer', onClick: actions.onEdit })))));
};
