var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Text } from '../../../../../touring-ui/components/typography/text';
import { Button } from '../../../../../touring-ui/components/buttons';
import { WaypointIcon } from '../../../tour/floating/waypoint/icon';
import { toAddressString } from '../../../../../../lib/utils';
import { newWaypoint } from '../../../../../../context/slices/waypoints';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../../context/context';
import { useDialogDispatchCtx } from '../../../../atoms/dialog/context';
import { ChevronDownIcon, ChevronRightIcon, CornerRightDownIcon, RefreshCcw03Icon, } from '../../../../../touring-ui/icons/_export';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../../touring-ui/components/tooltip';
import { trimText } from '../../../../../../lib/formatters';
import { usePlacesDispatchCtx } from '../../context';
import { getPlaceImageURL } from '../../../../../../api/internal/places';
export const AddToGroupSelectGroup = (props) => {
    const { t } = useTranslation();
    const { group, groupIndex, allWaypoints, isOpen, setOpen } = props;
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const placeDispatch = usePlacesDispatchCtx();
    const actions = {
        onAdd: (groupIndex, place, waypointId) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            placeDispatch({ type: 'REMOVE_TMP_PLACE' });
            dispatch({
                type: 'ADD_WAYPOINT',
                groupIndex: groupIndex,
                afterWaypointId: waypointId,
                atIndex: !waypointId ? 0 : undefined,
                newWaypoint: newWaypoint({
                    travelMode: context.ui.defaultTravelMode,
                    title: place.title,
                    image: getPlaceImageURL(place),
                    notes: place.description,
                    type: 'default',
                    address: (_a = place.address) !== null && _a !== void 0 ? _a : undefined,
                    location: (_b = place.location) !== null && _b !== void 0 ? _b : undefined,
                }),
            });
            dialogDispatch({ type: 'REMOVE_NODE' });
        }),
        onInsert: (groupIndex, place, waypointId) => __awaiter(void 0, void 0, void 0, function* () {
            var _c, _d, _e;
            placeDispatch({ type: 'REMOVE_TMP_PLACE' });
            dispatch({
                type: 'ADD_WAYPOINT',
                groupIndex: groupIndex,
                atIndex: (_c = allWaypoints.findIndex((wp) => waypointId == wp.id)) !== null && _c !== void 0 ? _c : 0,
                shouldReplace: true,
                newWaypoint: newWaypoint({
                    travelMode: context.ui.defaultTravelMode,
                    title: place.title,
                    image: getPlaceImageURL(place),
                    notes: place.description,
                    type: 'default',
                    address: (_d = place.address) !== null && _d !== void 0 ? _d : undefined,
                    location: (_e = place.location) !== null && _e !== void 0 ? _e : undefined,
                }),
            });
            dialogDispatch({ type: 'REMOVE_NODE' });
        }),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("li", { key: group.id, className: 'p-2 bg-slate-100 flex flex-row gap-1 items-center cursor-pointer', onClick: () => setOpen(group.id) },
            isOpen != group.id && React.createElement(ChevronRightIcon, { className: 'w-4 h-4' }),
            isOpen == group.id && React.createElement(ChevronDownIcon, { className: 'w-4 h-4' }),
            React.createElement(Text, { className: 'text-lg font-semibold ' }, group.title)),
        isOpen == group.id && (React.createElement(React.Fragment, null,
            React.createElement("li", { className: 'py-2 px-4 flex place-content-center' },
                React.createElement(Button, { type: 'secondary', onClick: () => actions.onAdd(groupIndex, props.place) }, t('insertHere'))),
            group.waypoints.map((waypoint) => (React.createElement(React.Fragment, { key: waypoint.id },
                React.createElement("li", { className: 'py-2 px-4 font-normal flex items-center gap-2' },
                    React.createElement(Text, { className: 'flex flex-row gap-2 items-start' },
                        React.createElement(WaypointIcon, { variant: 'horizontal', size: 'xs', id: waypoint.id, color: group.color, index: allWaypoints.findIndex((item) => item.id == waypoint.id) + 1, travelModel: waypoint.config.travelMode, type: waypoint.type }),
                        waypoint.title
                            ? waypoint.title
                            : waypoint.address
                                ? trimText(toAddressString(waypoint.address))
                                : t('emptyWaypoint')),
                    React.createElement("div", { className: 'flex-grow' }),
                    !waypoint.title && !waypoint.address && (React.createElement(Tooltip, { node: t('swap') },
                        React.createElement(Button, { type: 'secondary', onClick: () => actions.onInsert(groupIndex, props.place, waypoint.id) },
                            React.createElement(RefreshCcw03Icon, { className: 'w-4 h-4' })))),
                    React.createElement(Tooltip, { node: t('insertAfter') },
                        React.createElement(Button, { type: 'secondary', onClick: () => actions.onAdd(groupIndex, props.place, waypoint.id) },
                            React.createElement(CornerRightDownIcon, { className: 'w-4 h-4' })))))))))));
};
