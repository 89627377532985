import React from 'react';
import { LayoutDefault } from '../../ui/components/molecules/layout/default';
import { AlertTriangleIcon } from '../../ui/touring-ui/icons/_export';
import { PageIndex } from '../index';
import { useTranslation } from 'react-i18next';
export const PaymentCancel = () => {
    const { t } = useTranslation();
    return (React.createElement(PageIndex, null,
        React.createElement(LayoutDefault, null,
            React.createElement("div", { className: "text-center flex flex-col gap-5 items-center" },
                React.createElement(AlertTriangleIcon, { className: "w-14 h-14" }),
                React.createElement("h1", { className: "text-2xl" }, t('paymentCancel')),
                React.createElement("p", null, t('paymentCancelMessage'))))));
};
