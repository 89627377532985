var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { POI } from './poi';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Label } from '../../../touring-ui/components/form/label';
import { Alert } from '../../../touring-ui/components/alerts';
import { ActivityIndicator } from '../../../touring-ui/components/acitivityIndicator';
import { useThirdPartyPlacesCtx, useThirdPartyPlacesDispatchCtx } from './context';
import { Anchor } from '../../../touring-ui/components/anchor';
import { nanoid } from 'nanoid';
import { getPoisNearby } from '../../../../api/internal/places';
export const ThirdPartyPlacesIndex = (props) => {
    const { t, i18n } = useTranslation();
    const { coordinates } = props.location;
    const [lng, lat] = coordinates;
    const [loading, setLoading] = useState(true);
    const [radius, setRadius] = useState(5000);
    const [isOpen, setIsOpen] = useState(true);
    const tpPlacesContext = useThirdPartyPlacesCtx();
    const tpPlacesDispatch = useThirdPartyPlacesDispatchCtx();
    const getPois = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const response = yield getPoisNearby(lat, lng, props.type, i18n.language, radius);
            if (!response || !response) {
                tpPlacesDispatch({ type: 'SET', data: Object.assign(Object.assign({}, tpPlacesContext), { places: [] }) });
            }
            else {
                tpPlacesDispatch({
                    type: 'SET',
                    data: Object.assign(Object.assign({}, tpPlacesContext), { places: response }),
                });
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            switch (error.response.status) {
                case 401:
                    window.dispatchEvent(new CustomEvent('showLogin'));
                    break;
                case 429:
                    window.dispatchEvent(new CustomEvent('showPurchaseScreen', { detail: error.response.data.error }));
                    break;
                default:
                    break;
            }
        }
    });
    useEffect(() => {
        getPois();
    }, [props.location, props.type, radius]);
    useEffect(() => {
        return () => {
            tpPlacesDispatch({ type: 'SET', data: { places: [] } });
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement("div", { className: 'flex justify-center bg-slate-100 p-2' },
            React.createElement(ActivityIndicator, null))),
        !loading && (React.createElement(Flexbox, { direction: 'vertical', gap: 'xl', className: 'text-sm' },
            React.createElement("div", { className: 'px-3 py-2 border rounded-lg grid grid-cols-1 gap-2' },
                React.createElement(Flexbox, { direction: 'horizontal', gap: 'md', position: 'center' },
                    React.createElement(Flexbox, { direction: 'horizontal', gap: 'md', position: 'center' },
                        React.createElement(Label, null, t('radius')),
                        React.createElement(Anchor, { className: `${radius == 1000 ? 'text-indigo-600' : 'text-slate-400'} font-semibold`, onClick: () => setRadius(1000) }, "1 km"),
                        React.createElement(Anchor, { className: `${radius == 5000 ? 'text-indigo-600' : 'text-slate-400'} font-semibold`, onClick: () => setRadius(5000) }, "5 km"),
                        React.createElement(Anchor, { className: `${radius == 10000 ? 'text-indigo-600' : 'text-slate-400'} font-semibold`, onClick: () => setRadius(10000) }, "10 km")),
                    React.createElement("div", { className: 'flex-grow' }),
                    React.createElement("div", null,
                        React.createElement(Anchor, { onClick: () => setIsOpen(!isOpen) }, isOpen ? t('hideList') : t('showList'))))),
            React.createElement("div", { className: 'text-xs text-slate-400' }, "powered by OpenStreetMaps"),
            tpPlacesContext.places.length == 0 && React.createElement(Alert, { type: 'info' }, t('noPlacesFound')),
            isOpen &&
                tpPlacesContext.places.length > 0 &&
                tpPlacesContext.places.map((place) => {
                    return React.createElement(POI, { key: nanoid(), place: place });
                })))));
};
