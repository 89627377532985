import * as turf from '@turf/turf';
export const toAddressString = (address) => {
    var _a;
    const addressString = [];
    if (address.streetName && address.streetName != '') {
        addressString.push(address.streetName);
        if (address.streetNumber && address.streetNumber != '') {
            addressString.push(' ' + address.streetNumber);
        }
    }
    if (address.municipality) {
        if (address.postalCode) {
            addressString.push((addressString.length > 0 ? ', ' : '') + address.postalCode);
        }
        addressString.push(' ' + address.municipality);
    }
    if (address.city) {
        if (address.postalCode) {
            addressString.push((addressString.length > 0 ? ', ' : '') + address.postalCode);
        }
        addressString.push(' ' + address.city);
    }
    if (address.country) {
        addressString.push((addressString.length > 0 ? ', ' : '') + address.country);
    }
    if (addressString.length < 2) {
        return (_a = address.freeformAddress) !== null && _a !== void 0 ? _a : '';
    }
    return addressString.join('').trim();
};
export const calculateBBoxForWaypoints = (points) => {
    if (points.length < 2)
        return null;
    if (points.length > 0) {
        return turf.bbox(turf.lineString(points));
    }
    return null;
};
export const generateGoogleMapsRouteLink = (groupIndex, waypoints, user) => {
    const helpingPoints = [];
    let googleURL = `https://www.google.com/maps/dir/?api=1&travelmode=driving`;
    waypoints.map((waypoint, index) => {
        var _a, _b;
        if (!waypoint.location)
            return;
        const [lng, lat] = waypoint.location.coordinates;
        if (user && index == 0) {
            if (lng == ((_a = user.location) === null || _a === void 0 ? void 0 : _a.coordinates[0]) && lat == ((_b = user.location) === null || _b === void 0 ? void 0 : _b.coordinates[1]))
                return;
        }
        if (waypoints.length - 1 == index) {
            googleURL += `&destination=${lat},${lng}`;
        }
        else {
            helpingPoints.push(`${lat},${lng}`);
        }
    });
    googleURL += `&waypoints=${helpingPoints.join('|')}`;
    return googleURL;
};
export const generateGoogleMapsWaypointLink = (waypoint) => {
    if (!waypoint.location)
        return;
    let googleURL = `https://www.google.com/maps/search/?api=1`;
    const [lng, lat] = waypoint.location.coordinates;
    googleURL += `&query=${lat},${lng}`;
    return googleURL;
};
export const generateAppleMapsWaypointLink = (waypoint) => {
    if (!waypoint.location)
        return;
    const [lng, lat] = waypoint.location.coordinates;
    return `https://maps.apple.com/?q=${lat},${lng}`;
};
export const convertToPlaceAddress = (address) => {
    var _a, _b, _c, _d, _e, _f;
    return {
        streetName: (_a = address.streetName) !== null && _a !== void 0 ? _a : '',
        streetNumber: (_b = address.streetNumber) !== null && _b !== void 0 ? _b : '',
        city: (_c = address.municipality) !== null && _c !== void 0 ? _c : '',
        postalCode: (_d = address.postalCode) !== null && _d !== void 0 ? _d : '',
        country: (_e = address.country) !== null && _e !== void 0 ? _e : '',
        freeformAddress: (_f = address.freeformAddress) !== null && _f !== void 0 ? _f : '',
    };
};
export const parseTripadvisorIDFromURL = (url) => {
    const regex = /(?<=-d)(\d+)(?=-)/;
    const match = url.match(regex);
    if (match) {
        return match[0];
    }
    return url;
};
