import React, { useMemo } from 'react';
import { useThirdPartyPlacesCtx } from './context';
import { ThirdPartyPlaceMapItem } from './mapItem';
import { nanoid } from 'nanoid';
export const ThirdPartyPlacesMapItems = (props) => {
    const context = useThirdPartyPlacesCtx();
    const places = useMemo(() => context.places, [context.places]);
    return (React.createElement(React.Fragment, null, props.map &&
        places.map((place) => {
            var _a;
            return (React.createElement(ThirdPartyPlaceMapItem, { key: nanoid(), type: (_a = place.type) !== null && _a !== void 0 ? _a : 'default', map: props.map, place: place }));
        })));
};
