import React from 'react';
import { Text } from '../typography/text';
export const InputInfo = (props) => {
    const type = (() => {
        var _a;
        const selectedColor = (_a = props.type) !== null && _a !== void 0 ? _a : 'normal';
        switch (selectedColor) {
            case 'normal':
                return `text-slate-600 dark:text-slate-400`;
            case 'error':
                return `text-rose-600 dark:text-rose-400`;
        }
    })();
    return (React.createElement(Text, { type: 'footnote', className: `${type}` }, props.children));
};
