import React from 'react';
import { Text } from '../../../../../touring-ui/components/typography/text';
import { Button } from '../../../../../touring-ui/components/buttons';
import { produce } from 'immer';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../../context/context';
import { Divider } from '../../../../../touring-ui/components/general/divider';
import { Flexbox } from '../../../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
import { useDialogDispatchCtx } from '../../../../atoms/dialog/context';
export const NoRouteFound = (props) => {
    if (!props.origin || !props.destination)
        return React.createElement(React.Fragment, null);
    const context = useApplicationCtx();
    const origin = props.origin
        ? context.tour.groups[props.origin.groupIndex].waypoints[props.origin.waypointIndex]
        : null;
    const destination = props.destination
        ? context.tour.groups[props.destination.groupIndex].waypoints[props.destination.waypointIndex]
        : null;
    const dispatch = useApplicationDispatchCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const { t } = useTranslation();
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'xl' },
        React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
            React.createElement(Text, { type: 'body', className: 'font-semibold' }, t('unableToCalculateRoute')),
            React.createElement(Text, { type: 'body' }, t('possibleReasons')),
            React.createElement(Text, { type: 'body' },
                "\u2022 ",
                t('roadBlocked')),
            React.createElement(Text, { type: 'body' },
                "\u2022 ",
                t('noPathToPoint')),
            React.createElement(Text, { type: 'body' },
                "\u2022 ",
                t('isFlight'))),
        React.createElement(Divider, null),
        React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
            React.createElement(Text, { type: 'body' }, t('changeToFlight')),
            React.createElement("div", null,
                React.createElement(Button, { onClick: () => {
                        var _a;
                        if (!props.destination)
                            return;
                        if (!origin || !destination || ((_a = props.destination) === null || _a === void 0 ? void 0 : _a.groupIndex) == -1)
                            return;
                        if (!origin.location || !destination.location)
                            return;
                        const updatedWaypoint = produce(destination, (draft) => {
                            draft.config.travelMode = 'airplane';
                            draft.type = 'flight';
                        });
                        dispatch({
                            type: 'UPDATE_WAYPOINT',
                            groupIndex: props.destination.groupIndex,
                            waypoint: updatedWaypoint,
                        });
                        dialogDispatch({ type: 'REMOVE_NODE' });
                    }, type: 'primary' }, t('change'))))));
};
