import React, { createContext, useContext, useReducer } from 'react';
import { createDraft, finishDraft, produce } from 'immer';
export const findPlaceById = (places, id) => places.find((place) => place._id === id);
export const findPlaceIndexById = (places, id) => places.findIndex((place) => place._id === id);
export const setPlaces = (ctx, action) => {
    return produce(ctx, (draft) => {
        action.places.map((newPlace) => {
            const existingIndex = draft.places.findIndex((place) => place._id === newPlace._id);
            if (existingIndex !== -1) {
                // Place exists, update it
            }
            else {
                // Place doesn't exist, keep it as is
                draft.places.push(newPlace);
            }
        });
    });
};
export const addPlace = (ctx, action) => {
    const draft = createDraft(ctx.places);
    draft.push(action.place);
    const newPlaces = finishDraft(draft);
    return produce(ctx, (draft) => {
        draft.places = newPlaces;
    });
};
export const updatePlace = (ctx, action) => {
    return produce(ctx, (draft) => {
        const index = findPlaceIndexById(draft.places, action.id);
        draft.places[index] = action.place;
    });
};
export const removePlace = (ctx, action) => {
    return produce(ctx, (draft) => {
        const index = findPlaceIndexById(draft.places, action.id);
        draft.places.splice(index, 1);
    });
};
export const setTMPPlace = (ctx, action) => {
    return produce(ctx, (draft) => {
        draft.temporaryPlace = action.place;
    });
};
export const removeTMPPLace = (ctx) => {
    return produce(ctx, (draft) => {
        draft.temporaryPlace = null;
    });
};
const PlacesInitialContext = {
    places: [],
    temporaryPlace: null,
};
const dndReducer = produce((ctx, action) => {
    switch (action.type) {
        case 'SET_PLACES':
            return setPlaces(ctx, action);
        case 'ADD_PLACE':
            return addPlace(ctx, action);
        case 'UPDATE_PLACE':
            return updatePlace(ctx, action);
        case 'REMOVE_PLACE':
            return removePlace(ctx, action);
        case 'SET_TMP_PLACE':
            return setTMPPlace(ctx, action);
        case 'REMOVE_TMP_PLACE':
            return removeTMPPLace(ctx);
    }
});
const PlacesContextProvider = createContext(PlacesInitialContext);
const PlacesDispatchCtx = createContext(() => { });
export const usePlacesCtx = () => useContext(PlacesContextProvider);
export const usePlacesDispatchCtx = () => useContext(PlacesDispatchCtx);
export const PlacesContext = (props) => {
    const [ctx, dispatch] = useReducer(dndReducer, PlacesInitialContext);
    return (React.createElement(PlacesContextProvider.Provider, { value: ctx },
        React.createElement(PlacesDispatchCtx.Provider, { value: dispatch }, props.children)));
};
