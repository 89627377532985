var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { updateUserRemote } from '../../../../api/internal/auth';
import { useApplicationCtx } from '../../../../context/context';
import { Card, CardBody, CardFooter, CardHeader } from '../../../touring-ui/components/cards';
import { InputSection } from '../../../touring-ui/components/form/inputSection';
import { Label } from '../../../touring-ui/components/form/label';
import { Input } from '../../../touring-ui/components/form/input';
import { InputInfo } from '../../../touring-ui/components/form/info';
import { Form } from '../../../touring-ui/components/form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../touring-ui/components/buttons';
import { Alert } from '../../../touring-ui/components/alerts';
export const ChangePassword = () => {
    const context = useApplicationCtx();
    const { t } = useTranslation();
    const [success, setSuccess] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [errorData, setErrorData] = useState({
        password: null,
        passwordRepeat: null,
    });
    const valid = password != '' && passwordRepeat != '' && password == passwordRepeat;
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'password':
                if (value.length < 8)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('passwordRequirements') }));
                if (value.length >= 8)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                setPassword(value);
                break;
            case 'passwordRepeat':
                if (password != value)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('repeatPasswordNotSame') }));
                if (password == value)
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                setPasswordRepeat(value);
                break;
        }
    };
    const saveRemote = (e) => __awaiter(void 0, void 0, void 0, function* () {
        if (e)
            e.preventDefault();
        setSuccess(false);
        if (!context.user || password != passwordRepeat || password == '' || passwordRepeat == '')
            return;
        const userWithPassword = Object.assign(Object.assign({}, context.user), { password: password });
        const response = yield updateUserRemote(userWithPassword);
        if (response) {
            setSuccess(true);
        }
    });
    return (React.createElement(React.Fragment, null,
        success && React.createElement(Alert, { type: 'success' }, "Password changed successfully"),
        React.createElement(Card, null,
            React.createElement(CardHeader, null, t('changePassword')),
            React.createElement(CardBody, null,
                React.createElement(Form, { onSubmit: saveRemote, className: 'mt-2' },
                    React.createElement("div", { className: 'grid grid-cols-1 lg:grid-cols-2 gap-5' },
                        React.createElement(InputSection, null,
                            React.createElement(Label, null, t('password')),
                            React.createElement(Input, { type: 'password', name: 'password', value: password, onChange: handleChange }),
                            React.createElement(InputInfo, { type: errorData.password ? 'error' : 'normal' },
                                errorData.password,
                                !errorData.password && t('passwordDescription'))),
                        React.createElement(InputSection, null,
                            React.createElement(Label, null, t('passwordRepeat')),
                            React.createElement(Input, { type: 'password', name: 'passwordRepeat', value: passwordRepeat, onChange: handleChange }),
                            React.createElement(InputInfo, { type: errorData.passwordRepeat ? 'error' : 'normal' },
                                errorData.passwordRepeat,
                                !errorData.passwordRepeat && t('passwordRepeatDescription')))))),
            React.createElement(CardFooter, null,
                React.createElement(Button, { type: 'primary', onClick: saveRemote, disabled: !valid }, t('save'))))));
};
