import React, { memo, useEffect, useRef, useState } from 'react';
import MapLibreGL from 'maplibre-gl';
import { Card, CardBody, CardHeader } from '../../../../touring-ui/components/cards';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { Text } from '../../../../touring-ui/components/typography/text';
import { FolderIcon, XIcon, ZoomInIcon } from '../../../../touring-ui/icons/_export';
import * as turf from '@turf/turf';
import { WaypointDetailIndex } from '../detail';
import { useModalCtx, useModalDispatchCtx } from '../../../atoms/modal/context';
import { useTranslation } from 'react-i18next';
import { ClusterItem } from './cluster/item';
import { useApplicationCtx } from '../../../../../context/context';
import { nanoid } from 'nanoid';
import { Anchor } from '../../../../touring-ui/components/anchor';
import { flyTo } from '../../../../../lib/zoom';
export const WaypointCluster = memo((props) => {
    var _a;
    const { t } = useTranslation();
    const context = useApplicationCtx();
    const markerElementRef = useRef(null);
    const modalContext = useModalCtx();
    const modalDispatch = useModalDispatchCtx();
    const markerRef = useRef(null);
    const [leaves, setLeaves] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const isDetailVisible = useRef(false);
    const initMarker = () => {
        if (markerRef.current == null || !props.map)
            return;
        return new MapLibreGL.Marker({
            element: markerRef.current,
            draggable: false,
            className: 'cluster-marker',
        })
            .setLngLat(props.cluster.geometry.coordinates)
            .addTo(props.map);
    };
    useEffect(() => {
        if (!props.superCluster)
            return;
        try {
            setLeaves(props.superCluster.getLeaves(props.cluster.id, Infinity));
        }
        catch (error) { }
        const markerElement = initMarker();
        markerElementRef.current = markerElement !== null && markerElement !== void 0 ? markerElement : null;
        return () => {
            markerElement === null || markerElement === void 0 ? void 0 : markerElement.remove();
        };
    }, [props.cluster, props.superCluster]);
    const actions = {
        onEdit: (details) => {
            var _a;
            const waypoint = context.tour.groups[details.groupIndex].waypoints[details.waypointIndex];
            if (!waypoint)
                return;
            if (markerElementRef.current)
                (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.removeClassName('active');
            setIsVisible(false);
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: {
                    type: 'waypoint',
                    id: waypoint.id,
                    additional: { groupIndex: details.groupIndex, waypointIndex: details.waypointIndex },
                },
                node: React.createElement(WaypointDetailIndex, { waypointIndex: details.waypointIndex, groupIndex: details.groupIndex }),
            });
        },
        onZoom: (waypoint) => {
            setIsVisible(false);
            if (waypoint.location) {
                flyTo([waypoint]);
            }
        },
    };
    const ref = useRef(null);
    /*
    useOutsideClick(ref, () => {
        if (isDetailVisible.current) return
        markerElementRef.current?.removeClassName('active')
        setIsVisible(false)
    })
    */
    const toggle = (open) => {
        var _a, _b;
        setIsVisible(open);
        if (open) {
            (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.addClassName('active');
        }
        else {
            (_b = markerElementRef.current) === null || _b === void 0 ? void 0 : _b.removeClassName('active');
        }
    };
    useEffect(() => {
        return () => {
            var _a;
            props.map.scrollZoom.enable();
            (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.removeClassName('active');
        };
    });
    const isSelectedInCluster = ((_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.type) == 'waypoint' &&
        leaves.find((item) => { var _a; return item.properties.id == ((_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.id); }) &&
        modalContext.meta.subType == null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "hidden" },
            React.createElement("div", { ref: markerRef },
                !isVisible && (React.createElement("div", { className: 'cursor-pointer', onClick: () => {
                        var _a;
                        if (markerElementRef.current)
                            (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.addClassName('active');
                        toggle(!isVisible);
                    } },
                    React.createElement(Flexbox, { direction: 'horizontal', position: 'center', gap: 'none' },
                        React.createElement("div", null,
                            React.createElement("div", { className: 'relative drop-shadow-xl' },
                                isSelectedInCluster && (React.createElement("div", { className: 'absolute top-0 left-0 bottom-0 right-0 animate-ping bg-rose-600 rounded-full' })),
                                React.createElement("div", { className: 'w-10 h-10 flex place-content-center items-center bg-white cursor-pointer relative rounded-full font-semibold border border-slate-300' },
                                    leaves.length,
                                    "+")),
                            leaves.length > 100 && (React.createElement("div", { className: 'bg-white grid grid-cols-2 p-1 rounded-md gap-1 drop-shadow-xl border-2 border-slate-300' },
                                leaves
                                    .sort((a, b) => {
                                    return a.properties.index < b.properties.index ? -1 : 1;
                                })
                                    .map((leave) => {
                                    var _a, _b;
                                    const groupColor = context.tour.groups[leave.properties.groupIndex].color;
                                    const isSelected = ((_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.type) == 'waypoint' &&
                                        ((_b = modalContext.meta) === null || _b === void 0 ? void 0 : _b.id) == leave.properties.id &&
                                        modalContext.meta.subType == null;
                                    return (React.createElement("div", { key: leave.properties.id, className: 'relative' },
                                        isSelected && (React.createElement("div", { className: 'absolute w-5 h-5 animate-ping bg-rose-600 rounded-full' })),
                                        React.createElement("div", { className: 'rounded-full w-5 h-5 flex place-content-center items-center justify-center text-white relative', style: { backgroundColor: `${groupColor}` } }, props.allWaypoints.findIndex((wp) => wp.id == leave.properties.id) + 1)));
                                }),
                                leaves.length % 2 == 1 && (React.createElement("div", { className: 'rounded-full w-5 h-5 flex place-content-center items-center justify-center text-white relative bg-slate-100' })))))))),
                isVisible && (React.createElement("div", { ref: ref, className: 'relative z-90 ts_animation_bounceIn pointer-events-auto drop-shadow-xl' },
                    React.createElement(Card, { className: '!overflow-visible' },
                        React.createElement(CardHeader, null,
                            React.createElement(Flexbox, { direction: 'horizontal', position: 'center', className: 'cursor-pointer text-sm', gap: 'md' },
                                React.createElement(FolderIcon, { className: 'w-4 h-4' }),
                                React.createElement(Text, { className: 'font-semibold' }, t('countWaypoint', { count: leaves.length })),
                                React.createElement("div", { className: 'flex-grow' }),
                                React.createElement(Anchor, { onClick: () => {
                                        toggle(false);
                                        const points = leaves.map((leave) => leave.properties.location.coordinates);
                                        let bbox = turf.bbox(turf.lineString(points));
                                        bbox = turf.bbox(turf.buffer(turf.bboxPolygon(bbox), 1, { units: 'kilometers' }));
                                        window.dispatchEvent(new CustomEvent('zoomMapTo', { detail: { bbox, pitch: 0 } }));
                                    } },
                                    React.createElement(ZoomInIcon, { className: 'w-4 h-4' })),
                                React.createElement(Anchor, { onClick: () => {
                                        var _a;
                                        if (markerElementRef.current)
                                            (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.removeClassName('active');
                                        toggle(!isVisible);
                                    } },
                                    React.createElement(XIcon, { className: 'w-4 h-4' })))),
                        React.createElement(CardBody, { className: `${isVisible ? '' : 'hidden'} !overflow-visible` },
                            React.createElement(Flexbox, { direction: 'vertical', className: 'divide-y' }, leaves
                                .sort((a, b) => {
                                return a.properties.index < b.properties.index ? -1 : 1;
                            })
                                .map((leave) => (React.createElement(ClusterItem, { map: props.map, key: nanoid(), leave: leave, onEdit: actions.onEdit, onZoom: actions.onZoom, isDetailVisibleRef: isDetailVisible }))))))))))));
});
