import React from 'react';
import { useDialogDispatchCtx } from '../../../atoms/dialog/context';
import { AddToGroupSelect } from './addToGroup/select';
import { useTranslation } from 'react-i18next';
export const AddToGroupButton = (props) => {
    const { t } = useTranslation();
    const dialogDispatch = useDialogDispatchCtx();
    const actions = {
        onShowDialog: () => {
            dialogDispatch({
                type: 'SET_NODE',
                node: React.createElement(AddToGroupSelect, { place: props.place, middleware: props.middleware }),
                title: t('insertWhere'),
                maxWidth: 400,
            });
        },
    };
    return (React.createElement("div", { className: 'relative', onClick: actions.onShowDialog }, props.children));
};
