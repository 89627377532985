import React from 'react';
import { WaypointGenericSearchAddress } from '../waypoint/generic/searchAddress';
import { zoomToPlaces } from '../../../../lib/zoom';
import { usePlacesDispatchCtx } from './context';
import { convertToPlaceAddress } from '../../../../lib/utils';
export const PlaceSearch = () => {
    const placeDispatch = usePlacesDispatchCtx();
    return (React.createElement(WaypointGenericSearchAddress, { type: 'search', selected: undefined, renderSelected: () => '', onSetAddress: (selected) => {
            var _a, _b, _c, _d, _e;
            if (!selected.location)
                return;
            const place = {
                type: 'default',
                address: convertToPlaceAddress(selected.address),
                location: selected.location,
                title: (_a = selected.poi) === null || _a === void 0 ? void 0 : _a.name,
                contact: {
                    website: ((_b = selected.poi) === null || _b === void 0 ? void 0 : _b.url) ? `https://${(_c = selected.poi) === null || _c === void 0 ? void 0 : _c.url}` : undefined,
                    phone: ((_d = selected.poi) === null || _d === void 0 ? void 0 : _d.phone) ? (_e = selected.poi) === null || _e === void 0 ? void 0 : _e.phone : undefined,
                },
                state: {
                    public: false,
                },
            };
            placeDispatch({
                type: 'SET_TMP_PLACE',
                place: place,
            });
            zoomToPlaces([place]);
        } }));
};
