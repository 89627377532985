import React, { useEffect, useRef } from 'react';
import { useModalCtx, useModalDispatchCtx } from './context';
import { Card, CardHeader } from '../../../touring-ui/components/cards';
import { Text } from '../../../touring-ui/components/typography/text';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { XIcon } from '../../../touring-ui/icons/_export';
import { Button } from '../../../touring-ui/components/buttons';
import { WaypointIcon } from '../../molecules/tour/floating/waypoint/icon';
import { useApplicationCtx } from '../../../../context/context';
import { trimText } from '../../../../lib/formatters';
import { getAllWaypoints } from '../../../../context/slices/waypoints';
import { toAddressString } from '../../../../lib/utils';
import SimpleBar from 'simplebar-react';
export const ModalIndex = (props) => {
    var _a, _b, _c, _d, _e;
    const appContext = useApplicationCtx();
    const context = useModalCtx();
    const modalDispatch = useModalDispatchCtx();
    const additional = (_b = (_a = context.meta) === null || _a === void 0 ? void 0 : _a.additional) !== null && _b !== void 0 ? _b : null;
    const group = additional && additional.groupIndex >= 0 ? appContext.tour.groups[additional.groupIndex] : null;
    const waypoint = additional && group && additional.waypointIndex >= 0 ? group.waypoints[additional.waypointIndex] : null;
    const index = waypoint ? getAllWaypoints(appContext.tour).findIndex((wp) => wp.id == waypoint.id) : -1;
    const ref = useRef(null);
    useEffect(() => {
        return () => {
            if (context.onDisappear)
                context.onDisappear();
        };
    }, [context.node]);
    return (React.createElement(React.Fragment, null, context.node && (React.createElement("div", { key: `modal-${(_c = context.meta) === null || _c === void 0 ? void 0 : _c.type}-${(_d = context.meta) === null || _d === void 0 ? void 0 : _d.id}`, className: 'w-[460px] pointer-events-none max-h-full', ref: ref },
        React.createElement(Card, { className: 'shadow-xl pointer-events-auto max-h-full overflow-hidden' },
            React.createElement(CardHeader, null,
                React.createElement(Flexbox, { style: { paddingTop: '2px' }, className: 'tex-sm h-6' },
                    waypoint && index >= 0 && (React.createElement(React.Fragment, null,
                        React.createElement(WaypointIcon, { id: waypoint.id, variant: 'horizontal', color: (_e = group === null || group === void 0 ? void 0 : group.color) !== null && _e !== void 0 ? _e : '#000', index: index + 1, travelModel: waypoint.config.travelMode, type: waypoint.type, size: 'xs' }),
                        React.createElement(Text, { className: 'flex-grow text-sm' },
                            waypoint.title && trimText(waypoint.title),
                            !waypoint.title &&
                                waypoint.address &&
                                trimText(toAddressString(waypoint.address))))),
                    React.createElement("div", { className: 'flex-grow' }),
                    React.createElement(Button, { size: 'xs', onClick: () => {
                            modalDispatch({ type: 'REMOVE_NODE' });
                            if (context.onClose)
                                context.onClose();
                        }, className: 'flex items-center gap-1' },
                        React.createElement(XIcon, { className: "w-4 h-4" })))),
            React.createElement(SimpleBar, { style: { maxHeight: props.maxHeight - 50 } }, context.node))))));
};
