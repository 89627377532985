import { createDraft, finishDraft } from 'immer';
export const GroupMiddleware = (tour) => {
    for (let index = 0; index < tour.groups.length; index++) {
        const draft = createDraft(tour.groups[index]);
        draft.summary.lengthInMeters = 0;
        draft.summary.travelTimeInSeconds = 0;
        draft.waypoints.map((waypoint, index) => {
            draft.summary.lengthInMeters += waypoint.summary.lengthInMeters;
            draft.summary.travelTimeInSeconds += waypoint.summary.travelTimeInSeconds;
            draft.summary.travelTimeInSeconds += waypoint.summary.breakInSeconds;
            if (index == draft.waypoints.length - 1) {
                draft.summary.arrivalTime = waypoint.summary.arrivalTime;
            }
        });
        tour.groups[index] = finishDraft(draft);
    }
};
