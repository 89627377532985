import { original, produceWithPatches } from 'immer';
import { Middleware } from '../middlewares/middleware';
export const updateTour = (ctx, action) => {
    const { id, title, description, isPublic, groups, summary, image, user, updatedAt, createdAt } = action.tour;
    const [nextState, patches, inversePatches] = produceWithPatches(ctx, (draft) => {
        const tour = draft.tour;
        const originalTour = original(tour);
        tour.id = id;
        tour.title = title;
        tour.description = description;
        tour.image = image;
        tour.isPublic = isPublic;
        tour.groups = groups;
        tour.summary = summary;
        tour.user = user;
        tour.updatedAt = updatedAt;
        tour.createdAt = createdAt;
        if (action.inSync != undefined) {
            draft.ui.inSync = action.inSync;
        }
        if (!action.withoutMiddleWare) {
            Middleware(draft.tour, originalTour);
        }
    });
    return { context: nextState, patches, inversePatches };
};
