import React, { Fragment } from 'react';
import { Listbox } from '@headlessui/react';
import { nanoid } from 'nanoid';
import { ChevronDownIcon } from '../../icons/_export';
import { Flexbox } from '../flexbox';
import { Text } from '../typography/text';
export const ListBox = (props) => {
    const wrapperSize = (() => {
        var _a;
        const selectedType = (_a = props.size) !== null && _a !== void 0 ? _a : 'md';
        switch (selectedType) {
            case 'sm':
                return 'px-2 py-1';
            case 'md':
                return 'p-2';
        }
    })();
    const wrapperClasses = (() => {
        var _a;
        const selectedType = (_a = props.type) !== null && _a !== void 0 ? _a : 'form';
        switch (selectedType) {
            case 'form':
            case 'relative':
                return 'text-left box-border border border-slate-300 focus:!border-indigo-600 w-full rounded-md outline-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-md disabled:!text-neutral-500 dark:disabled:!text-gray-600';
            default:
                return 'font-semibold w-full bg-transparent outline-none text-black dark:text-white';
        }
    })();
    const optionClasses = (() => {
        var _a;
        const selectedType = (_a = props.type) !== null && _a !== void 0 ? _a : 'form';
        switch (selectedType) {
            case 'form':
                return 'absolute z-10 shadow-xl';
            default:
                return 'mb-2 shadow-md';
        }
    })();
    return (React.createElement("div", { className: 'relative' },
        React.createElement(Listbox, { value: props.selected, onChange: props.setSelected },
            React.createElement(Listbox.Button, { className: `${wrapperSize} ${wrapperClasses}` },
                React.createElement(Flexbox, { direction: 'horizontal', position: 'center' },
                    React.createElement(Text, { className: 'flex-grow font-semibold' }, props.renderSelected(props.selected)),
                    React.createElement(ChevronDownIcon, { className: 'w-4 h-4' }))),
            props.options.length > 0 && (React.createElement(Listbox.Options, { className: `${optionClasses} outline-none mt-2 w-content max-h-64 overflow-auto drop-shadow-xl bg-white dark:bg-neutral-950 rounded-md text-sm font-semibold border border-slate-200 dark:border-neutral-700 divide-y divide-slate-200 dark:divide-neutral-700 flex flex-col` }, props.options.map((option) => (React.createElement(Listbox.Option, { key: nanoid(), value: option, as: Fragment }, ({ active }) => (React.createElement("li", { className: `${active
                    ? 'bg-indigo-600 text-white'
                    : 'bg-white dark:bg-neutral-800 text-black dark:text-white'} p-2 cursor-pointer outline-none` }, props.renderOption(option)))))))))));
};
