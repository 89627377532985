import React from 'react';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { ActivityIndicator } from '../../../../touring-ui/components/acitivityIndicator';
import { CardBody } from '../../../../touring-ui/components/cards';
import { useTranslation } from 'react-i18next';
export const UserLoginVerifyUsernamePassword = (props) => {
    if (props.state != 'verifyToken')
        return React.createElement(React.Fragment, null);
    const { t } = useTranslation();
    return (React.createElement(CardBody, null,
        React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
            React.createElement(ActivityIndicator, null, t('pleaseWaitDataIsBeingLoaded')))));
};
