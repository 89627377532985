var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef } from 'react';
import { useLoaderData } from 'react-router-dom';
import { LayoutDefault } from '../../ui/components/molecules/layout/default';
import { Text } from '../../ui/touring-ui/components/typography/text';
import { Flexbox } from '../../ui/touring-ui/components/flexbox';
import QRCode from 'react-qr-code';
import { Button } from '../../ui/touring-ui/components/buttons';
import { UAParser } from 'ua-parser-js';
import { parseTSMarkdown } from '../../lib/formatters';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { GuideGroup } from './guide/group';
import { downloadTourById, updateTour } from '../../api/internal/tour';
import { Divider } from '../../ui/touring-ui/components/general/divider';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../context/context';
export const TourGuidePage = () => {
    var _a;
    const { t } = useTranslation();
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const tour = context.tour;
    const loader = useLoaderData();
    const initialLoadDone = useRef(false);
    const parser = new UAParser();
    const isIOSOriPadOS = parser.getOS().name == 'iOS';
    const isMobileOrTablet = parser.getDevice().type == 'mobile' || parser.getDevice().type == 'tablet';
    const publicURL = tour ? `${location.protocol}//${location.host}/tour/${tour.id}/guide` : 'https://touring.studio';
    const actions = {
        onDownload: (id, title) => {
            if (!id || !title)
                return;
            downloadTourById(id, title);
        },
        updateTour: () => __awaiter(void 0, void 0, void 0, function* () {
            if (!tour || !tour.id)
                return;
            if (!initialLoadDone.current) {
                initialLoadDone.current = true;
                return;
            }
            yield updateTour(tour);
        }),
    };
    useEffect(() => {
        actions.updateTour();
    }, [tour]);
    useEffect(() => {
        if (!loader.tour)
            return;
        dispatch({
            type: 'UPDATE_TOUR',
            tour: loader.tour,
            withoutMiddleWare: true,
        });
    }, [loader.tour]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (React.createElement(LayoutDefault, null, tour && (React.createElement("div", { className: 'max-w-3xl w-full mx-auto' },
        React.createElement(Flexbox, { direction: 'vertical' },
            React.createElement(Flexbox, { direction: 'vertical', gap: 'xl', className: 'mb-8' },
                React.createElement(Flexbox, { gap: 'xl', position: 'center' },
                    React.createElement(Flexbox, { direction: 'vertical', gap: 'none', className: 'flex-grow' },
                        React.createElement(Text, { type: 'title', className: 'text-indigo-600 dark:text-indigo-300' }, t('tourGuide')),
                        React.createElement(Text, { type: 'headline' }, tour === null || tour === void 0 ? void 0 : tour.title),
                        React.createElement(Flexbox, { direction: 'horizontal', gap: 'sm' }, tour.summary && (React.createElement(React.Fragment, null,
                            React.createElement("b", null, t('departure')),
                            React.createElement(Text, { type: 'body' }, DateTime.fromMillis(tour.summary.departureTime).toLocaleString(DateTime.DATE_FULL))))),
                        tour.id && (React.createElement("div", null,
                            React.createElement(Button, { size: 'sm', type: 'secondary', onClick: () => { var _a; return actions.onDownload((_a = tour.id) !== null && _a !== void 0 ? _a : null, tour.title); } }, t('downloadGpx'))))),
                    React.createElement("div", { className: 'hidden md:block' },
                        React.createElement("div", { className: 'border border-slate-300 dark:border-transparent' },
                            React.createElement(QRCode, { value: `${publicURL}`, className: 'w-24 h-24 border-8 border-white' })))),
                React.createElement(Divider, null),
                tour.image && React.createElement("img", { src: tour.image, className: 'w-full rounded-lg' }),
                React.createElement("div", { className: 'text-balance whitespace-pre-wrap hyphens-auto text-md text-black dark:text-white', dangerouslySetInnerHTML: { __html: parseTSMarkdown((_a = tour === null || tour === void 0 ? void 0 : tour.description) !== null && _a !== void 0 ? _a : '') } })),
            tour.groups.map((group, groupIndex) => {
                return (React.createElement(GuideGroup, { key: group.id, groupIndex: groupIndex, isMobileOrTablet: isMobileOrTablet, isIOSOriPadOS: isIOSOriPadOS }));
            }))))));
};
