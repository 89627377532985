import { createDraft, finishDraft } from 'immer';
import { DateTime } from 'luxon';
import { migration_2023_12_19 } from '../migrations/2023-12-19';
const log = (...args) => {
    const props = args[0];
    const rest = args.slice(1);
    //console.log(`[${props.updated.waypoint.id}][${props.updated.groupIndex}][${props.updated.indexInGroup}] `, ...rest)
};
const setLineStringandDepartureTimeForFirstItemInGroup = (props) => {
    const { groupIndex, indexInGroup, group, waypoint } = props.updated;
    if (groupIndex == 0 && indexInGroup == 0) {
        log(props, `Is First item of Route. Remove Line String and Summary only set departure time to ${group.summary.departureTime}`);
        waypoint.lineString = null;
        waypoint.summary.departureTime = group.summary.departureTime;
        waypoint.summary.arrivalTime = 0;
        waypoint.summary.lengthInMeters = 0;
        waypoint.summary.travelTimeInSeconds = 0;
        waypoint.summary.breakInSeconds = 0;
    }
    else {
    }
};
const setWaypointArrivalAndDepartureTime = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    const { updated, original } = props;
    if (updated.groupIndex == 0 && updated.indexInGroup == 0)
        return;
    log(props, 'Check Summary.', original ? JSON.parse(JSON.stringify(original)) : null, updated ? JSON.parse(JSON.stringify(updated)) : null);
    if (!original.previousWaypoint && !updated.previousWaypoint)
        return;
    if (((_a = updated.waypoint) === null || _a === void 0 ? void 0 : _a.lineString) &&
        updated.previousWaypoint &&
        (updated.previousWaypoint.summary.departureTime > 0 || updated.indexInGroup == 0) &&
        (((_b = original.previousWaypoint) === null || _b === void 0 ? void 0 : _b.summary.departureTime) != ((_c = updated.previousWaypoint) === null || _c === void 0 ? void 0 : _c.summary.departureTime) ||
            ((_e = (_d = original.waypoint) === null || _d === void 0 ? void 0 : _d.lineString) === null || _e === void 0 ? void 0 : _e.properties.travelTimeInSeconds) !=
                ((_g = (_f = updated.waypoint) === null || _f === void 0 ? void 0 : _f.lineString) === null || _g === void 0 ? void 0 : _g.properties.travelTimeInSeconds))) {
        const arrivalTime = DateTime.fromMillis(updated.indexInGroup == 0
            ? updated.group.summary.departureTime
            : updated.previousWaypoint.summary.departureTime).plus({
            seconds: (_j = (_h = updated.waypoint) === null || _h === void 0 ? void 0 : _h.lineString) === null || _j === void 0 ? void 0 : _j.properties.travelTimeInSeconds,
        });
        log(props, `Update Arrival and departure time to ${arrivalTime.toString()}`);
        updated.waypoint.summary.arrivalTime = arrivalTime.toMillis();
        updated.waypoint.summary.departureTime = arrivalTime.toMillis();
    }
    else if (((_k = updated.waypoint) === null || _k === void 0 ? void 0 : _k.lineString) &&
        updated.previousWaypoint &&
        updated.previousWaypoint.summary.departureTime == 0 &&
        updated.indexInGroup != 0 &&
        (((_l = original.previousWaypoint) === null || _l === void 0 ? void 0 : _l.summary.departureTime) != ((_m = updated.previousWaypoint) === null || _m === void 0 ? void 0 : _m.summary.departureTime) ||
            ((_p = (_o = original.waypoint) === null || _o === void 0 ? void 0 : _o.lineString) === null || _p === void 0 ? void 0 : _p.properties.travelTimeInSeconds) !=
                ((_r = (_q = updated.waypoint) === null || _q === void 0 ? void 0 : _q.lineString) === null || _r === void 0 ? void 0 : _r.properties.travelTimeInSeconds))) {
        log(props, `Reset Arrival and departure time`);
        updated.waypoint.summary.arrivalTime = 0;
        updated.waypoint.summary.departureTime = 0;
    }
    else if (!((_s = updated.waypoint) === null || _s === void 0 ? void 0 : _s.lineString) &&
        (((_t = original.previousWaypoint) === null || _t === void 0 ? void 0 : _t.summary.departureTime) != ((_u = updated.previousWaypoint) === null || _u === void 0 ? void 0 : _u.summary.departureTime) ||
            ((_w = (_v = original.waypoint) === null || _v === void 0 ? void 0 : _v.lineString) === null || _w === void 0 ? void 0 : _w.properties.travelTimeInSeconds) !=
                ((_y = (_x = updated.waypoint) === null || _x === void 0 ? void 0 : _x.lineString) === null || _y === void 0 ? void 0 : _y.properties.travelTimeInSeconds))) {
        log(props, `Reset Arrival and departure time`);
        updated.waypoint.summary.arrivalTime = 0;
        updated.waypoint.summary.departureTime = 0;
    }
    else if (updated.indexInGroup == 0 &&
        ((_z = original.group) === null || _z === void 0 ? void 0 : _z.summary.departureTime) != updated.group.summary.departureTime) {
        log(props, 'Update waypoint departure time', ((_0 = original.group) === null || _0 === void 0 ? void 0 : _0.summary.departureTime) != updated.group.summary.departureTime);
        const arrivalTime = DateTime.fromMillis(updated.group.summary.departureTime).plus({
            seconds: (_2 = (_1 = updated.waypoint) === null || _1 === void 0 ? void 0 : _1.lineString) === null || _2 === void 0 ? void 0 : _2.properties.travelTimeInSeconds,
        });
        log(props, `Update Arrival and departure time to ${arrivalTime.toString()}`);
        updated.waypoint.summary.arrivalTime = arrivalTime.toMillis();
        updated.waypoint.summary.departureTime = arrivalTime.toMillis();
    }
};
const addBreak = (props) => {
    var _a, _b, _c, _d;
    const { updated, original } = props;
    if (updated.groupIndex === 0 && updated.indexInGroup === 0)
        return;
    log(props, 'Check if break added', (_a = original.waypoint) === null || _a === void 0 ? void 0 : _a.summary, (_b = updated.waypoint) === null || _b === void 0 ? void 0 : _b.summary);
    const departureTime = DateTime.fromMillis(updated.waypoint.summary.arrivalTime)
        .plus({
        seconds: (_c = updated.waypoint) === null || _c === void 0 ? void 0 : _c.summary.breakInSeconds,
    })
        .toUTC()
        .toMillis();
    //if (departureTime == original.waypoint?.summary.arrivalTime) return
    log(props, 'Add break', (_d = original.waypoint) === null || _d === void 0 ? void 0 : _d.summary.arrivalTime, departureTime);
    updated.waypoint.summary.departureTime = departureTime;
};
const setLengthAndTravelTimeInSeconds = (props) => {
    var _a, _b, _c;
    const { updated, original } = props;
    if (((_a = updated.waypoint.lineString) === null || _a === void 0 ? void 0 : _a.properties.travelTimeInSeconds) ==
        ((_c = (_b = original.waypoint) === null || _b === void 0 ? void 0 : _b.lineString) === null || _c === void 0 ? void 0 : _c.properties.travelTimeInSeconds))
        return;
    if (updated.waypoint.lineString && updated.waypoint.lineString.properties) {
        updated.waypoint.summary.lengthInMeters = updated.waypoint.lineString.properties.lengthInMeters;
        updated.waypoint.summary.travelTimeInSeconds = updated.waypoint.lineString.properties.travelTimeInSeconds;
    }
};
const currentOrPreviousLocationChanged = (props) => {
    var _a, _b, _c;
    const { updated, original } = props;
    log(props, 'Check locations original.', JSON.parse(JSON.stringify(original.waypoint)), JSON.parse(JSON.stringify(updated.waypoint)));
    if (original.previousWaypoint && updated.previousWaypoint) {
        log(props, 'Check locations previous.', JSON.parse(JSON.stringify(original.previousWaypoint)), JSON.parse(JSON.stringify(updated.previousWaypoint)));
    }
    if (JSON.stringify((_a = original.waypoint) === null || _a === void 0 ? void 0 : _a.location) != JSON.stringify(updated.waypoint.location) &&
        ((_b = original.waypoint) === null || _b === void 0 ? void 0 : _b.lineString) != null) {
        log(props, 'Location changed for original waypoint.');
        updated.waypoint.lineString = null;
    }
    if (original.previousWaypoint && updated.previousWaypoint) {
        if (JSON.stringify(original.previousWaypoint.location) != JSON.stringify(updated.previousWaypoint.location) &&
            ((_c = original.waypoint) === null || _c === void 0 ? void 0 : _c.lineString) != null) {
            log(props, 'Location changed for previous waypoint.');
            updated.waypoint.lineString = null;
        }
    }
};
const checkIfConfigChanged = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { updated, original } = props;
    if (!((_a = original.waypoint) === null || _a === void 0 ? void 0 : _a.config) && !((_b = updated.waypoint) === null || _b === void 0 ? void 0 : _b.config))
        return;
    if (JSON.stringify((_c = original.waypoint) === null || _c === void 0 ? void 0 : _c.config) != JSON.stringify((_d = updated.waypoint) === null || _d === void 0 ? void 0 : _d.config)) {
        log(props, 'Check config', (_e = original.waypoint) === null || _e === void 0 ? void 0 : _e.config, (_f = updated.waypoint) === null || _f === void 0 ? void 0 : _f.config);
        updated.waypoint.lineString = null;
        updated.waypoint.summary.departureTime = updated.indexInGroup == 0 ? updated.group.summary.departureTime : 0;
        updated.waypoint.summary.arrivalTime = 0;
        updated.waypoint.summary.lengthInMeters = 0;
        updated.waypoint.summary.travelTimeInSeconds = 0;
    }
};
export const WaypointMiddleware = (tour, originalTour) => {
    var _a, _b, _c, _d;
    const originalWaypoints = originalTour === null || originalTour === void 0 ? void 0 : originalTour.groups.flatMap((group, groupIndex) => group.waypoints.map((waypoint, waypointIndex) => {
        return {
            groupIndex,
            group: group,
            waypointIndex,
            waypoint: waypoint,
        };
    }));
    const waypoints = tour === null || tour === void 0 ? void 0 : tour.groups.flatMap((group, groupIndex) => group.waypoints.map((waypoint, waypointIndex) => {
        return { groupIndex, group: group, waypointIndex, waypoint: waypoint };
    }));
    for (const item of waypoints) {
        const group = tour.groups[item.groupIndex];
        const groupDraft = createDraft(group);
        const waypoint = tour.groups[item.groupIndex].waypoints[item.waypointIndex];
        const waypointDraft = createDraft(waypoint);
        const index = waypoints === null || waypoints === void 0 ? void 0 : waypoints.findIndex((wp) => wp.waypoint.id == waypointDraft.id);
        const groupIndex = item.groupIndex;
        const indexInGroup = item.waypointIndex;
        const previousItemIndex = index - 1;
        let previousWaypoint = null;
        if (previousItemIndex > -1) {
            const previousGroupIndex = waypoints[previousItemIndex].groupIndex;
            const previousWaypointIndex = waypoints[previousItemIndex].waypointIndex;
            previousWaypoint = tour.groups[previousGroupIndex].waypoints[previousWaypointIndex];
        }
        const originalIndex = originalWaypoints
            ? originalWaypoints.findIndex((f) => f.waypoint.id == item.waypoint.id)
            : null;
        const original = originalWaypoints && originalIndex ? originalWaypoints[originalIndex] : null;
        const originalWaypoint = (_a = original === null || original === void 0 ? void 0 : original.waypoint) !== null && _a !== void 0 ? _a : null;
        const originalGroupIndex = (_b = original === null || original === void 0 ? void 0 : original.groupIndex) !== null && _b !== void 0 ? _b : null;
        const originalGroup = (_c = original === null || original === void 0 ? void 0 : original.group) !== null && _c !== void 0 ? _c : null;
        const originalIndexInGroup = (_d = original === null || original === void 0 ? void 0 : original.waypointIndex) !== null && _d !== void 0 ? _d : null;
        const originalPreviousWaypointIndex = originalIndex ? originalIndex - 1 : -1;
        const originalPreviousWaypoint = originalWaypoints && originalPreviousWaypointIndex > -1
            ? originalWaypoints[originalPreviousWaypointIndex].waypoint
            : null;
        const props = {
            original: {
                index: originalIndex,
                indexInGroup: originalIndexInGroup,
                waypoint: originalWaypoint !== null && originalWaypoint !== void 0 ? originalWaypoint : null,
                groupIndex: originalGroupIndex,
                group: originalGroup,
                previousWaypoint: originalPreviousWaypoint,
            },
            updated: {
                index,
                indexInGroup,
                waypoint: waypointDraft,
                groupIndex: groupIndex,
                group,
                previousWaypoint,
            },
        };
        migrations(props);
        currentOrPreviousLocationChanged(props);
        setLineStringandDepartureTimeForFirstItemInGroup(props);
        setWaypointArrivalAndDepartureTime(props);
        addBreak(props);
        setLengthAndTravelTimeInSeconds(props);
        checkIfConfigChanged(props);
        groupDraft.waypoints[item.waypointIndex] = finishDraft(waypointDraft);
        tour.groups[item.groupIndex] = finishDraft(groupDraft);
    }
};
const migrations = (props) => {
    migration_2023_12_19(props);
};
