import React, { createContext, useContext } from 'react';
import { produce } from 'immer';
import { useImmerReducer } from 'use-immer';
const set = (ctx, action) => {
    ctx.zoom = action.data.zoom;
};
const MapInitialContext = {
    zoom: null,
};
const dndReducer = produce((ctx, action) => {
    switch (action.type) {
        case 'SET':
            set(ctx, action);
            break;
    }
});
const MapContextProvider = createContext(MapInitialContext);
const MapDispatchCtx = createContext(() => { });
export const useMapCtx = () => useContext(MapContextProvider);
export const useMapDispatchCtx = () => useContext(MapDispatchCtx);
export const MapContext = (props) => {
    const [ctx, dispatch] = useImmerReducer(dndReducer, MapInitialContext);
    return (React.createElement(MapContextProvider.Provider, { value: ctx },
        React.createElement(MapDispatchCtx.Provider, { value: dispatch }, props.children)));
};
