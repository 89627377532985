export const isDarkModeActive = () => {
    return localStorage.getItem('darkMode') != null;
};
export const setDarkMode = (value) => {
    if (value) {
        localStorage.setItem('darkMode', 'true');
    }
    else {
        localStorage.removeItem('darkMode');
    }
};
