var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../context/context';
import languages from '../../../../lib/languages';
import validator from 'validator';
import { redirectToBilling, updateUserRemote } from '../../../../api/internal/auth';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Text } from '../../../touring-ui/components/typography/text';
import { Form } from '../../../touring-ui/components/form';
import { InputSection } from '../../../touring-ui/components/form/inputSection';
import { Label } from '../../../touring-ui/components/form/label';
import { Input } from '../../../touring-ui/components/form/input';
import { InputInfo } from '../../../touring-ui/components/form/info';
import { Divider } from '../../../touring-ui/components/general/divider';
import { createDraft, finishDraft } from 'immer';
import debounce from 'lodash.debounce';
import { Card, CardBody, CardFooter } from '../../../touring-ui/components/cards';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from '../../../touring-ui/components/acitivityIndicator';
import { ListBox } from '../../../touring-ui/components/form/listbox';
import { WaypointGenericSearchAddress } from '../waypoint/generic/searchAddress';
import { ChangePassword } from './changePassword';
import { Anchor } from '../../../touring-ui/components/anchor';
import { DateTime } from 'luxon';
import { PurchaseBanner } from '../purchase/purchaseBanner';
export const ProfilePage = () => {
    var _a;
    const context = useApplicationCtx();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [languageFilterQuery, setLanguageFilterQuery] = useState('');
    const dispatch = useApplicationDispatchCtx();
    const { t, i18n } = useTranslation();
    const [errorData, setErrorData] = useState({
        email: null,
        firstname: null,
        lastname: null,
        language: null,
        address: null,
        location: null,
    });
    // Function to handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        handleInputChange(name, value);
    };
    const handleInputChange = (name, value) => {
        if (!context.user)
            return;
        const draft = createDraft(context.user);
        switch (name) {
            case 'email':
                if (!validator.isEmail(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('invalidEmail') }));
                if (validator.isEmail(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                draft.email = value;
                break;
            case 'firstname':
                if (!validator.isAlphanumeric(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('invalidFirstName') }));
                if (validator.isAlphanumeric(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                draft.firstname = value;
                break;
            case 'lastname':
                if (!validator.isAlphanumeric(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('invalidLastName') }));
                if (validator.isAlphanumeric(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                draft.lastname = value;
                break;
            case 'language':
                if (!validator.isAlphanumeric(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: t('invalidLanguage') }));
                if (validator.isAlphanumeric(value))
                    setErrorData(Object.assign(Object.assign({}, errorData), { [name]: null }));
                draft.language = value;
                i18n.changeLanguage(value);
                break;
        }
        const user = finishDraft(draft);
        onSaveRemote(user);
        dispatch({ type: 'UPDATE_USER', user: user });
    };
    const saveRemote = (user) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const response = yield updateUserRemote(user);
        setLoading(false);
        if (response) {
            setSuccess(true);
            dispatch({ type: 'UPDATE_USER', user: response });
        }
    });
    const onSaveRemote = useCallback(debounce(saveRemote, 500, { maxWait: 1000 }), []);
    // Function to handle form submission
    const handleSubmit = (e) => {
        if (e)
            e.preventDefault();
        if (context.user) {
            saveRemote(context.user);
        }
    };
    const setAddress = (data) => {
        if (!context.user)
            return;
        const addressDraft = createDraft(context.user);
        addressDraft.address = data.address;
        addressDraft.location = data.location;
        const user = finishDraft(addressDraft);
        saveRemote(user);
        dispatch({ type: 'UPDATE_USER', user: user });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'text-sm' }, context.user && (React.createElement(Flexbox, { direction: 'vertical', gap: 'xl' },
            React.createElement(Text, { type: 'title', className: 'font-semibold' }, context.user.firstname ? t('helloName', { name: context.user.firstname }) : t('helloYou')),
            React.createElement(Divider, null),
            !context.user.subscription && React.createElement(PurchaseBanner, null),
            context.user.subscription && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'flex flex-grow' },
                    React.createElement("div", null,
                        "Abonnement g\u00FCltig bis:",
                        ' ',
                        DateTime.fromISO(context.user.subscription.current_period_end).toLocaleString(DateTime.DATETIME_FULL)),
                    React.createElement("div", { className: 'flex-grow' }),
                    React.createElement(Anchor, { onClick: () => redirectToBilling() }, "Abonnement verwalten")),
                React.createElement(Divider, null))),
            React.createElement(Card, null,
                React.createElement(CardBody, null,
                    React.createElement(Form, { onSubmit: handleSubmit },
                        React.createElement("div", { className: 'grid grid-cols-1 lg:grid-cols-2 gap-5' },
                            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', grow: true },
                                React.createElement(InputSection, null,
                                    React.createElement(Label, null, t('eMail')),
                                    React.createElement(Input, { type: 'email', name: 'email', value: context.user.email, onChange: handleChange, disabled: true }),
                                    React.createElement(InputInfo, null, t('eMailDescription'))),
                                React.createElement(InputSection, null,
                                    React.createElement(Label, null, t('firstName')),
                                    React.createElement(Input, { type: 'text', name: 'firstname', value: context.user.firstname, onChange: handleChange }),
                                    React.createElement(InputInfo, { type: errorData.firstname ? 'error' : 'normal' },
                                        errorData.firstname,
                                        !errorData.firstname && t('firstNameDescription'))),
                                React.createElement(InputSection, null,
                                    React.createElement(Label, null, t('lastName')),
                                    React.createElement(Input, { type: 'text', name: 'lastname', value: context.user.lastname, onChange: handleChange }),
                                    React.createElement(InputInfo, { type: errorData.lastname ? 'error' : 'normal' },
                                        errorData.lastname,
                                        !errorData.lastname && t('lastNameDescription')))),
                            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', grow: true },
                                React.createElement(InputSection, null,
                                    React.createElement(Label, null, t('language')),
                                    React.createElement(ListBox, { renderSelected: (selected) => selected.label, selected: (_a = languages.find((f) => { var _a, _b; return (_b = f.value == ((_a = context.user) === null || _a === void 0 ? void 0 : _a.language)) !== null && _b !== void 0 ? _b : 'en'; })) !== null && _a !== void 0 ? _a : {
                                            value: 'en',
                                            label: 'English',
                                        }, setSelected: (language) => {
                                            handleInputChange('language', language.value);
                                        }, options: languages.filter((language) => language.label
                                            .toLowerCase()
                                            .includes(languageFilterQuery.toLowerCase())), renderOption: (option) => option.label }),
                                    React.createElement(InputInfo, null, t('preferredLanguage'))),
                                React.createElement(InputSection, null,
                                    React.createElement(Label, null, t('address')),
                                    React.createElement(WaypointGenericSearchAddress, { type: 'form', selected: {
                                            address: context.user.address,
                                            location: context.user.location,
                                        }, onSetAddress: (selected) => {
                                            setAddress(selected);
                                        } }),
                                    React.createElement(InputInfo, null, t('preferredAddress'))))))),
                React.createElement(CardFooter, null,
                    !loading && React.createElement(React.Fragment, null, "\u00A0"),
                    loading && (React.createElement("div", { className: "flex items-center gap-1" },
                        React.createElement(ActivityIndicator, null),
                        " ",
                        t('pleaseWaitDataIsBeingLoaded'))))),
            React.createElement(ChangePassword, null))))));
};
