var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { ReminderSignUp } from './signup';
import { useDialogCtx, useDialogDispatchCtx } from '../../atoms/dialog/context';
import { useApplicationCtx } from '../../../../context/context';
import { db } from '../../../../lib/localDB';
import LoadDate from '../../../../lib/loadDate';
import { createDraft, finishDraft } from 'immer';
export var ReminderKeys;
(function (ReminderKeys) {
    ReminderKeys["SignUp"] = "signup";
})(ReminderKeys || (ReminderKeys = {}));
export const useReminderCoordinator = () => {
    const reminders = {
        [ReminderKeys.SignUp]: {
            node: React.createElement(ReminderSignUp, null),
            showWhenPro: false,
            showWhenSignedIn: false,
            showAfterSecondsOnPage: 3 * 60,
            showAgainAfterSeonds: 259200,
        },
    };
    const context = useApplicationCtx();
    const dialogCtx = useDialogCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const loadDate = LoadDate.getInstance();
    const [queuedReminders, setQueuedReminders] = useState({});
    const setSeen = (key) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield isSeen(ReminderKeys.SignUp);
        if (!result) {
            return db.reminder.add({ key, timestamp: new Date().getTime() });
        }
        else {
            return db.reminder.where({ key }).modify({ timestamp: new Date().getTime() });
        }
    });
    const isSeen = (key) => {
        return db.reminder.where({ key }).first();
    };
    const showReminderIfNeededFor = (key) => __awaiter(void 0, void 0, void 0, function* () {
        const reminder = reminders[key];
        if (!reminder || dialogCtx.node != null)
            return;
        if (!reminder.showWhenSignedIn) {
            if (context.user)
                return;
        }
        const lastSeen = yield isSeen(key);
        const onPageInSeconds = new Date().getTime() / 1000 - loadDate.getInitialTimestamp() / 1000;
        const canShow = lastSeen
            ? new Date().getTime() / 1000 - lastSeen.timestamp >= reminder.showAgainAfterSeonds
            : true;
        if (onPageInSeconds > reminder.showAfterSecondsOnPage && canShow) {
            yield showReminder(key);
        }
    });
    const showReminder = (key) => __awaiter(void 0, void 0, void 0, function* () {
        yield setSeen(key);
        const reminder = reminders[key];
        dialogDispatch({
            type: 'SET_NODE',
            node: reminder.node,
            maxWidth: 650,
        });
        removeReminderFromQueue(key);
    });
    const addReminderToQueue = (key) => {
        const draft = createDraft(queuedReminders);
        draft[key] = reminders[key];
        setQueuedReminders(finishDraft(draft));
    };
    const removeReminderFromQueue = (key) => {
        const draft = createDraft(queuedReminders);
        delete draft[key];
        setQueuedReminders(finishDraft(draft));
    };
    const checkReminders = () => {
        Object.keys(queuedReminders).forEach((key) => {
            // @ts-ignore
            showReminderIfNeededFor(key);
        });
    };
    useEffect(() => {
        const intervalId = setInterval(checkReminders, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [queuedReminders, dialogCtx.node, context.user]);
    return {
        addReminderToQueue,
        showReminder,
    };
};
