import React from 'react';
import { WaypointListItem } from './item';
import { DnDDraggable } from '../../../atoms/dnd/draggable';
import { useApplicationCtx } from '../../../../../context/context';
export const WaypointListIndex = (props) => {
    const context = useApplicationCtx();
    const group = context.tour.groups[props.groupIndex];
    return (React.createElement(React.Fragment, null, context.tour.groups[props.groupIndex].waypoints.map((waypoint, index) => {
        return (React.createElement("div", { className: "relative", key: waypoint.id },
            React.createElement(DnDDraggable, { key: waypoint.id, type: "waypoint", groupId: group.id, waypointId: waypoint.id },
                React.createElement(WaypointListItem, { key: waypoint.id, waypointIndex: index, groupIndex: props.groupIndex }))));
    })));
};
