import React, { useEffect, useRef } from 'react';
import { Flexbox } from '../../../../../touring-ui/components/flexbox';
import { toAddressString } from '../../../../../../lib/utils';
import { SummaryArrivalTime } from '../../../route/summary';
import { Anchor } from '../../../../../touring-ui/components/anchor';
import { AddWaypoint } from '../components/addWaypoint';
import { addAlpha } from '../../../../../../lib/color';
import { Text } from '../../../../../touring-ui/components/typography/text';
import { PencilLineIcon, Trash01Icon, ZoomInIcon } from '../../../../../touring-ui/icons/_export';
import { WaypointIcon } from './icon';
import { useTranslation } from 'react-i18next';
import { WaypointGenericSearchAddress } from '../../../waypoint/generic/searchAddress';
import { useApplicationCtx } from '../../../../../../context/context';
import { flyTo } from '../../../../../../lib/zoom';
export const FloatingWaypoint = (props) => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const { groupIndex, waypointIndex } = props;
    const context = useApplicationCtx();
    const group = context.tour.groups[groupIndex];
    const waypoint = group.waypoints[waypointIndex];
    useEffect(() => {
        var _a;
        if (!ref.current)
            return;
        window.dispatchEvent(new CustomEvent('ScrollToTourPosition', { detail: (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect() }));
    }, [ref]);
    return (React.createElement("div", { className: 'relative', ref: ref },
        React.createElement(Flexbox, { className: 'py-5 px-5 border-l-8', style: {
                borderLeftColor: addAlpha(group.color, 0.5),
            }, position: 'center', gap: 'xl', nowrap: true },
            React.createElement(WaypointIcon, { id: waypoint.id, size: 'md', color: group.color, type: waypoint.type, travelModel: waypoint.config.travelMode, index: props.globalIndex + 1, onClick: () => flyTo([waypoint]), isLast: props.globalIsLast, isLastInGroup: props.isLast, hasNotes: waypoint.notes ? waypoint.notes.length > 0 : false }),
            React.createElement(Flexbox, { direction: 'vertical', className: 'flex-grow' },
                React.createElement(Flexbox, { direction: 'vertical', nowrap: true },
                    !waypoint.address && !waypoint.location && (React.createElement(WaypointGenericSearchAddress, { type: 'waypoint', onSetAddress: (selected) => {
                            if (!props.onSetAddress)
                                return;
                            props.onSetAddress(selected);
                        } })),
                    waypoint.address && (React.createElement(React.Fragment, null,
                        React.createElement(Text, { className: 'font-semibold font-tight flex-grow cursor-pointer', onClick: props.onEdit }, waypoint.title),
                        React.createElement(Text, { className: `${!waypoint.title ? 'font-semibold' : 'text-sm'} flex-grow cursor-pointer`, onClick: props.onEdit }, toAddressString(waypoint.address)),
                        props.globalIndex != 0 && (React.createElement(SummaryArrivalTime, { arrivalTime: waypoint.summary.arrivalTime, timezone: waypoint.timezone })))))),
            React.createElement(Flexbox, { gap: 'lg', className: 'text-sm', position: 'center', nowrap: true },
                waypoint.address && (React.createElement(React.Fragment, null,
                    React.createElement(Anchor, { className: 'underline decoration-dotted underline-offset-4 flex gap-1 items-center hidden', onClick: props.onZoom },
                        React.createElement(ZoomInIcon, { className: 'w-4 h-4' })),
                    React.createElement(Anchor, { className: 'underline decoration-dotted underline-offset-4 flex gap-1 items-center', onClick: props.onEdit },
                        React.createElement(PencilLineIcon, { className: 'w-4 h-4' })))),
                React.createElement(Anchor, { className: 'underline decoration-dotted underline-offset-4 flex gap-1 items-center', onClick: props.onDelete },
                    React.createElement(Trash01Icon, { className: 'w-4 h-4' })))),
        React.createElement(AddWaypoint, { isFloating: !props.isLast, index: props.globalIndex + 1, onAdd: () => {
                if (!props.onAdd)
                    return;
                props.onAdd(waypoint);
            }, nextWaypoint: props.nextWaypoint })));
};
