import React, { useEffect } from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { useModalDispatchCtx } from '../../../atoms/modal/context';
import { GroupsListDetail } from './detail';
import { DNDDropable } from '../../../atoms/dnd/dropable';
import { WaypointListIndex } from '../../waypoint/list';
import { newWaypoint } from '../../../../../context/slices/waypoints';
import { useOrigin } from '../../../../../context/effects/groups/useOrigin';
import { FloatingGroup } from '../../tour/floating/group';
import { createDraft, finishDraft } from 'immer';
import { CalendarPicker } from '../../calendar/picker';
import { WaypointActivitiesIndex } from '../../waypoint/detail/activities';
import * as turf from '@turf/turf';
import { zoomTo } from '../../../../../lib/zoom';
import { CardBody } from '../../../../touring-ui/components/cards';
import { useTranslation } from 'react-i18next';
export const GroupsItem = (props) => {
    const { index, group } = props;
    const waypoints = group.waypoints;
    const context = useApplicationCtx();
    const dispatch = useApplicationDispatchCtx();
    const modalDispatch = useModalDispatchCtx();
    const { origin } = useOrigin(index);
    const { t } = useTranslation();
    const actions = {
        onAddWaypoint: () => {
            dispatch({
                type: 'ADD_WAYPOINT',
                atIndex: 0,
                groupIndex: index,
                newWaypoint: newWaypoint({ travelMode: context.ui.defaultTravelMode }),
            });
        },
        onDelete: () => {
            if (confirm(t('deleteConfirm'))) {
                dispatch({ type: 'REMOVE_GROUP', id: group.id });
            }
        },
        onEdit: () => {
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: { type: 'group', id: group.id, subType: null },
                node: React.createElement(GroupsListDetail, { index: index }),
            });
        },
        onEditDepartureTime: () => {
            modalDispatch({
                type: 'SET_NODE',
                title: 'Abfahrt',
                nodeType: { type: 'group', id: group.id, subType: 'departureTime' },
                node: React.createElement(CalendarPicker, { groupIndex: index }),
            });
        },
        onCollapse: () => {
            const isCollapsed = !group.isCollapsed;
            const draft = createDraft(group);
            draft.isCollapsed = isCollapsed;
            if (isCollapsed) {
                const waypointsOfGroup = structuredClone(waypoints);
                if (origin) {
                    waypointsOfGroup.splice(0, 0, context.tour.groups[origin.groupIndex].waypoints[origin.waypointIndex]);
                }
                zoomTo(waypointsOfGroup);
            }
            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft), withoutMiddleware: true });
        },
        onFindActivities: () => {
            if (!origin)
                return;
            const originWaypoint = context.tour.groups[origin.groupIndex].waypoints[origin.waypointIndex];
            modalDispatch({
                type: 'SET_NODE',
                title: 'Details',
                nodeType: { type: 'waypoint', id: originWaypoint.id, subType: 'activities' },
                node: (React.createElement(React.Fragment, null, originWaypoint.address && originWaypoint.location && (React.createElement(CardBody, null,
                    React.createElement(WaypointActivitiesIndex, { address: originWaypoint.address, location: originWaypoint.location }))))),
            });
        },
        zoomToGroup: (noCollapse) => {
            const waypointsOfGroup = structuredClone(waypoints);
            if (origin) {
                waypointsOfGroup.splice(0, 0, context.tour.groups[origin.groupIndex].waypoints[origin.waypointIndex]);
            }
            zoomTo(waypointsOfGroup);
            if (!group.isCollapsed || !noCollapse) {
                actions.onCollapse();
            }
        },
        zoomToOrigin: () => {
            if (!origin)
                return;
            const originWaypoint = context.tour.groups[origin.groupIndex].waypoints[origin.waypointIndex];
            if (originWaypoint && originWaypoint.location) {
                const buffer = turf.buffer(originWaypoint.location, 100, { units: 'meters' });
                const bbox = turf.bbox(buffer);
                window.dispatchEvent(new CustomEvent('zoomMapTo', { detail: { bbox, pitch: 0 } }));
            }
        },
    };
    useEffect(() => {
        return () => modalDispatch({ type: 'REMOVE_NODE' });
    }, []);
    const hasActivity = (() => {
        if (waypoints && waypoints.length > 0) {
            return waypoints.filter((waypoint) => waypoint.type == 'tourist_attraction').length > 0;
        }
        return false;
    })();
    const hasRestaurant = (() => {
        if (waypoints && waypoints.length > 0) {
            return waypoints.filter((waypoint) => waypoint.type == 'restaurant').length > 0;
        }
        return false;
    })();
    const hasHotel = (() => {
        if (waypoints && waypoints.length > 0) {
            return waypoints.filter((waypoint) => waypoint.type == 'hotel').length > 0;
        }
        return false;
    })();
    const hasFlight = (() => {
        if (waypoints && waypoints.length > 0) {
            return waypoints.filter((waypoint) => waypoint.type == 'flight').length > 0;
        }
        return false;
    })();
    return (React.createElement(React.Fragment, null,
        React.createElement(DNDDropable, { groupIndex: index, groupId: group.id },
            React.createElement(FloatingGroup, { index: index, group: group, origin: origin !== null && origin !== void 0 ? origin : null, originWaypoint: origin ? context.tour.groups[origin === null || origin === void 0 ? void 0 : origin.groupIndex].waypoints[origin === null || origin === void 0 ? void 0 : origin.waypointIndex] : null, originGroup: origin ? context.tour.groups[origin === null || origin === void 0 ? void 0 : origin.groupIndex] : null, onClickOrigin: actions.zoomToOrigin, waypoints: waypoints.length, onEdit: actions.onEdit, onEditDepartureTime: actions.onEditDepartureTime, onDelete: actions.onDelete, onCollapse: actions.onCollapse, onZoom: actions.zoomToGroup, onAddWaypoint: actions.onAddWaypoint, onFindActivities: actions.onFindActivities, hasActivity: hasActivity, hasRestaurant: hasRestaurant, hasHotel: hasHotel, hasFlight: hasFlight })),
        group.isCollapsed && React.createElement(WaypointListIndex, { groupIndex: index })));
};
