import React, { useEffect, useRef } from 'react';
export const Textarea = (props) => {
    const ref = useRef(null);
    const resize = () => {
        if (!ref.current)
            return;
        ref.current.style.height = 'auto';
        ref.current.style.height = ref.current.scrollHeight + 'px';
    };
    useEffect(() => {
        if (!ref.current)
            return;
        resize();
    }, [props.value, ref]);
    return (React.createElement("textarea", Object.assign({}, props, { ref: ref, onChange: (e) => {
            resize();
            if (props.onChange)
                props.onChange(e);
        }, className: `p-2 resize-none max-h-96 !box-border border border-slate-300  dark:border-neutral-700 dark:text-white focus:!border-indigo-600 w-full rounded-md outline-none dark:bg-neutral-900 text-md disabled:!text-neutral-500 dark:disabled:!text-gray-600` })));
};
