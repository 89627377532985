import React from 'react';
import { useApplicationCtx } from '../../../../../context/context';
import { DnDContext } from '../../../atoms/dnd/context';
import { GroupsItem } from './item';
import { FloatingGroupEmpty } from '../../tour/floating/group/empty';
export const GroupsIndex = () => {
    const { tour: { groups }, } = useApplicationCtx();
    return (React.createElement(React.Fragment, null,
        React.createElement(DnDContext, null, groups.map((group, index) => (React.createElement(GroupsItem, { key: `group-${group.id}`, index: index, group: group })))),
        React.createElement(FloatingGroupEmpty, { isEmpty: !groups.length })));
};
