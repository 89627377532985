import React, { useEffect, useRef } from 'react';
import MapLibreGL from 'maplibre-gl';
import { PlaceIcon } from './icon';
import { PlaceDetailIndex } from './generic/detail';
import { useModalCtx, useModalDispatchCtx } from '../../atoms/modal/context';
import { useTranslation } from 'react-i18next';
import { useApplicationCtx } from '../../../../context/context';
export const Place = (props) => {
    var _a, _b, _c, _d;
    const context = useApplicationCtx();
    const markerRef = useRef(null);
    const markerElementRef = useRef(null);
    const modalDispatch = useModalDispatchCtx();
    const modalCtx = useModalCtx();
    const { t } = useTranslation();
    const initMarker = () => {
        if (markerRef.current == null || props.place.location == null || !props.map)
            return null;
        return new MapLibreGL.Marker({
            element: markerRef.current,
            draggable: false,
            className: 'place-marker ative',
        })
            .setLngLat(props.place.location.coordinates)
            .addTo(props.map);
    };
    useEffect(() => {
        const marker = initMarker();
        markerElementRef.current = marker !== null && marker !== void 0 ? marker : null;
        return () => {
            var _a;
            (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.remove();
            markerElementRef.current = null;
        };
    }, [props.place.location, props.map]);
    const actions = {
        onDetail: () => {
            var _a;
            modalDispatch({
                type: 'SET_NODE',
                title: t('place'),
                nodeType: { type: 'place', id: (_a = props.place._id) !== null && _a !== void 0 ? _a : '' },
                node: React.createElement(PlaceDetailIndex, Object.assign({}, props)),
            });
        },
    };
    useEffect(() => {
        var _a;
        if (((_a = modalCtx.meta) === null || _a === void 0 ? void 0 : _a.type) == 'place' && modalCtx.meta.id == props.place._id && markerElementRef.current) {
            modalDispatch({
                type: 'SET_MARKER_REF',
                markerRef: markerElementRef.current,
            });
        }
    }, [markerElementRef.current, modalCtx]);
    return (React.createElement("div", { className: 'hidden' },
        React.createElement("div", { ref: markerRef, className: 'rounded-md' },
            React.createElement(PlaceIcon, { size: 'sm', color: !props.place.user
                    ? undefined
                    : props.place.user !== ((_a = context.user) === null || _a === void 0 ? void 0 : _a._id)
                        ? 'bg-indigo-300 border-indigo-500 text-indigo-600'
                        : undefined, type: props.place.type, id: props.place._id, canEdit: true, onClick: actions.onDetail, curated: ((_b = props.place.state) === null || _b === void 0 ? void 0 : _b.reviewStatus) == 'approved', isSelected: ((_c = modalCtx.meta) === null || _c === void 0 ? void 0 : _c.type) == 'place' && ((_d = modalCtx.meta) === null || _d === void 0 ? void 0 : _d.id) == props.place._id }))));
};
