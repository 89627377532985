import React, { useEffect, useRef } from 'react';
import MapLibreGL from 'maplibre-gl';
import { PlaceIcon } from '../place/icon';
import { Tooltip } from '../../../touring-ui/components/tooltip';
import { POI } from './poi';
import { nanoid } from 'nanoid';
import { useThirdPartyPlaceSelectedPlacesCtx } from './context';
export const ThirdPartyPlaceMapItem = (props) => {
    var _a;
    const place = props.place;
    const markerRef = useRef(null);
    const markerElementRef = useRef(null);
    const thirdPartyPlacesContext = useThirdPartyPlaceSelectedPlacesCtx();
    const initMarker = () => {
        if (markerRef.current == null || props.place.location.coordinates == null || !props.map)
            return null;
        return new MapLibreGL.Marker({
            element: markerRef.current,
            draggable: false,
            className: 'tp-place-marker',
        })
            .setLngLat(props.place.location.coordinates)
            .addTo(props.map);
    };
    useEffect(() => {
        const marker = initMarker();
        markerElementRef.current = marker !== null && marker !== void 0 ? marker : null;
        return () => {
            markerElementRef.current = null;
            marker === null || marker === void 0 ? void 0 : marker.remove();
        };
    }, [props.place.location, props.map]);
    const poi = (React.createElement("div", { className: 'w-[400px]' },
        React.createElement(POI, { key: nanoid(), place: place, disableZoom: true })));
    return (React.createElement("div", { className: 'hidden' },
        React.createElement("div", { ref: markerRef, className: 'rounded-md' },
            React.createElement(Tooltip, { node: poi, showOnClick: true, onClick: (isOpen) => {
                    var _a, _b;
                    if (isOpen)
                        (_a = markerElementRef.current) === null || _a === void 0 ? void 0 : _a.addClassName('active');
                    if (!isOpen)
                        (_b = markerElementRef.current) === null || _b === void 0 ? void 0 : _b.removeClassName('active');
                } },
                React.createElement(PlaceIcon, { size: 'sm', color: 'bg-rose-300 border-rose-500 text-rose-600', type: place.type, id: props.place._id, canEdit: true, onClick: () => { }, isSelected: ((_a = thirdPartyPlacesContext.selected) === null || _a === void 0 ? void 0 : _a._id) == props.place._id })))));
};
