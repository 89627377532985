var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import validator from 'validator';
import { emailPasswordLogin, requestCode, verifyCode, verifyToken } from '../../../../../api/internal/auth';
import { setAccessToken } from '../../../../../lib/accessToken';
import { useApplicationDispatchCtx } from '../../../../../context/context';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { UserLoginRequestCode } from './requestCode';
import { UserLoginVerifyCode } from './verifyCode';
import { UserLoginVerifyToken } from './verifyToken';
import { UserLoginLoading } from './loading';
import { Alert } from '../../../../touring-ui/components/alerts';
import { useTranslation } from 'react-i18next';
import { UserLoginEmailPassword } from './emailPassword';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { UserLoginVerifyUsernamePassword } from './verifyUsernamePassword';
import { Text } from '../../../../touring-ui/components/typography/text';
import { UserLoginSetPassword } from './setPassword';
import { Anchor } from '../../../../touring-ui/components/anchor';
export const UserLoginIndex = (props) => {
    var _a;
    const [method, setMethod] = useState((_a = props.method) !== null && _a !== void 0 ? _a : null);
    const { t } = useTranslation();
    const [authState, setAuthState] = useState('requestCode');
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [emailSignIn, setEmailSignIn] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useApplicationDispatchCtx();
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        setMethod('password');
        setError(null);
        if (validator.isEmpty(emailSignIn) && validator.isEmpty(emailSignIn)) {
            setError(t('pleaseCheckYourEmailAddress'));
            return;
        }
        setLoading(true);
        const accessToken = yield emailPasswordLogin(emailSignIn, password);
        setLoading(false);
        if (accessToken) {
            handleVerifyToken(accessToken);
            return;
        }
        setMethod(null);
        setError(t('unfortunatelyAnErrorOccurredPleaseTryAgain'));
        return;
    });
    const handleRequestCode = () => __awaiter(void 0, void 0, void 0, function* () {
        setMethod('otp');
        setError(null);
        if (validator.isEmpty(email) && validator.isEmail(email)) {
            setError(t('pleaseCheckYourEmailAddress'));
            return;
        }
        setLoading(true);
        const success = yield requestCode(email);
        setLoading(false);
        if (success) {
            setAuthState('verifyCode');
            return;
        }
        setError(t('unfortunatelyAnErrorOccurredPleaseTryAgain'));
        return;
    });
    const handleVerifyCode = () => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        if (validator.isEmpty(email) || validator.isEmpty(code !== null && code !== void 0 ? code : '') || code == null) {
            setError(t('pleaseCheckYourEmailAddress'));
            return;
        }
        setLoading(true);
        const accessToken = yield verifyCode(email, code);
        setLoading(false);
        if (accessToken) {
            handleVerifyToken(accessToken);
            return;
        }
        setError(t('unfortunatelyAnErrorOccurredPleaseTryAgain'));
        return;
    });
    const handleVerifyToken = (accessToken) => __awaiter(void 0, void 0, void 0, function* () {
        setError(null);
        if (!accessToken)
            return;
        setAccessToken(accessToken);
        setAuthState('verifyToken');
        setLoading(true);
        const user = yield verifyToken();
        setLoading(false);
        if (user) {
            dispatch({ type: 'SET_USER', user: user });
            if (user.hasPassword) {
                props.onDone();
            }
            else {
                setAuthState('password');
            }
            return;
        }
        setError(t('unfortunatelyAnErrorOccurredPleaseTryAgain'));
        setAuthState('requestCode');
        return;
    });
    const onSetPassword = () => __awaiter(void 0, void 0, void 0, function* () {
        props.onDone();
    });
    return (React.createElement(Flexbox, { direction: 'vertical', className: 'text-sm' },
        loading && React.createElement(UserLoginLoading, { loading: loading }),
        error && (React.createElement(Alert, { type: 'error', className: 'mb-5' }, error)),
        !loading && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: `grid ${!method ? 'grid-cols-1 gap-10 md:gap-0 md:grid-cols-2' : 'grid-cols-1'} md:divide-x` },
                React.createElement("div", { className: 'order-2 md:order-1 md:pr-3' },
                    React.createElement(UserLoginRequestCode, { state: authState, onRequestCode: handleRequestCode, email: email, onSetEmail: setEmail }),
                    React.createElement(UserLoginVerifyCode, { state: authState, code: code !== null && code !== void 0 ? code : '', onSetCode: setCode, onVerifyCode: handleVerifyCode }),
                    React.createElement(UserLoginVerifyToken, { state: authState }),
                    React.createElement(UserLoginSetPassword, { state: authState, onSetPassword: onSetPassword })),
                React.createElement("div", { className: 'order-1 md:order-2 md:pl-3' },
                    React.createElement(UserLoginEmailPassword, { state: authState, onLogin: handleLogin, email: emailSignIn, onSetEmail: setEmailSignIn, password: password, onSetPassword: setPassword }),
                    React.createElement(UserLoginVerifyUsernamePassword, { state: authState }))),
            React.createElement("div", { className: 'py-5' },
                React.createElement(Divider, null)),
            React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
                React.createElement(Text, { type: 'body', className: 'text-slate-500 text-sm' }, t('acceptTerms'))),
            React.createElement("div", { className: 'flex gap-5 opacity-75' },
                React.createElement(Anchor, { href: 'https://touring.studio/terms-of-service/', target: '_blank' }, t('termsAndConditions')),
                React.createElement(Anchor, { href: 'https://touring.studio/privacy-policy/', target: '_blank' }, t('privacyPolicy')))))));
};
