import React, { useEffect, useRef } from 'react';
import { Flexbox } from '../../../../../touring-ui/components/flexbox';
import { Text } from '../../../../../touring-ui/components/typography/text';
import { ChevronDownIcon, ChevronRightIcon, PencilLineIcon, Trash01Icon, ZoomInIcon, } from '../../../../../touring-ui/icons/_export';
import { Anchor } from '../../../../../touring-ui/components/anchor';
import { toAddressString } from '../../../../../../lib/utils';
import { addAlpha } from '../../../../../../lib/color';
import { AddWaypoint } from '../components/addWaypoint';
import { PoiIcon } from '../../../thirdPartyPlaces/poi/icon';
import { WaypointIcon } from '../waypoint/icon';
import { useTranslation } from 'react-i18next';
import { useWaypointGlobalPosition } from '../../../../../../context/effects/waypoints/useWaypointGlobalPosition';
import { useApplicationCtx } from '../../../../../../context/context';
import { useModalCtx } from '../../../../atoms/modal/context';
import { GroupSummary } from '../../../groups/details/summary';
import { DateTime } from 'luxon';
import { trimText } from '../../../../../../lib/formatters';
export const FloatingGroup = (props) => {
    var _a, _b, _c, _d, _e;
    const context = useApplicationCtx();
    const modalContext = useModalCtx();
    const ref = useRef(null);
    const { t } = useTranslation();
    const originWaypoint = props.originWaypoint
        ? props.originWaypoint
        : props.group.waypoints.length > 0
            ? props.group.waypoints[0]
            : null;
    const originGlobalPosition = props.originWaypoint ? useWaypointGlobalPosition(context, props.originWaypoint.id) : 0;
    const isSelected = ((_b = (_a = modalContext.meta) === null || _a === void 0 ? void 0 : _a.additional) === null || _b === void 0 ? void 0 : _b.groupIndex) == props.index;
    const scrollTo = () => {
        var _a;
        if (!ref.current)
            return;
        window.dispatchEvent(new CustomEvent('ScrollToTourPosition', { detail: (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect() }));
    };
    useEffect(() => {
        scrollTo();
    }, [ref]);
    return (React.createElement("div", { ref: ref },
        React.createElement(Flexbox, { direction: 'vertical', gap: 'none', className: 'relative' },
            React.createElement(Flexbox, { direction: 'vertical', className: 'py-5 px-5 bg-slate-100 dark:bg-neutral-900 border-l-8', gap: 'md', style: {
                    borderLeftColor: props.group.color,
                    backgroundColor: addAlpha(props.group.color, 0.1),
                } },
                React.createElement(Flexbox, { gap: 'md', className: 'text-sm', position: 'center' },
                    React.createElement(Text, { className: 'cursor-pointer', onClick: () => {
                            scrollTo();
                            if (!props.onCollapse)
                                return;
                            props.onCollapse();
                        } },
                        React.createElement("div", { className: 'relative' },
                            isSelected && (React.createElement("div", { className: 'absolute w-5 h-5 animate-ping bg-rose-600 rounded-full' })),
                            props.group.isCollapsed && React.createElement(ChevronDownIcon, { className: 'w-5 h-5' }),
                            !props.group.isCollapsed && React.createElement(ChevronRightIcon, { className: 'w-5 h-5' }))),
                    React.createElement(Text, { type: 'title', className: 'font-semibold font-tight flex-grow cursor-pointer', onClick: props.onCollapse }, trimText(props.group.title)),
                    React.createElement(Flexbox, { gap: 'lg', className: 'text-sm', position: 'center' },
                        props.hasHotel && (React.createElement("div", { className: 'text-slate-600 bg-white dark:text-neutral-300 dark:bg-neutral-700 w-5 h-5 flex items-center justify-center rounded-full' },
                            React.createElement(PoiIcon, { type: 'hotel', size: 'xs' }))),
                        props.hasActivity && (React.createElement("div", { className: 'text-slate-600 bg-white dark:text-neutral-300 dark:bg-neutral-700 w-5 h-5 flex items-center justify-center rounded-full' },
                            React.createElement(PoiIcon, { type: 'tourist_attraction', size: 'xs' }))),
                        props.hasRestaurant && (React.createElement("div", { className: 'text-slate-600 bg-white dark:text-neutral-300 dark:bg-neutral-700 w-5 h-5 flex items-center justify-center rounded-full' },
                            React.createElement(PoiIcon, { type: 'restaurant', size: 'xs' }))),
                        props.hasFlight && (React.createElement("div", { className: 'text-slate-600 bg-white dark:text-neutral-300 dark:bg-neutral-700 w-5 h-5 flex items-center justify-center rounded-full' },
                            React.createElement(PoiIcon, { type: 'flight', size: 'xs' }))),
                        React.createElement(Anchor, { className: 'underline decoration-dotted underline-offset-4 flex gap-1 items-center', onClick: props.onZoom },
                            React.createElement(ZoomInIcon, { className: 'w-4 h-4' }),
                            " "),
                        React.createElement(Anchor, { className: 'underline decoration-dotted underline-offset-4 flex gap-1 items-center', onClick: props.onEdit },
                            React.createElement(PencilLineIcon, { className: 'w-4 h-4' })),
                        React.createElement(Anchor, { className: 'underline decoration-dotted underline-offset-4 flex gap-1 items-center', onClick: props.onDelete },
                            React.createElement(Trash01Icon, { className: 'w-4 h-4' })))),
                React.createElement("div", { className: "text-sm mt-2" },
                    React.createElement(GroupSummary, { color: props.group.color, summary: props.group.summary, origin: (_c = props.originWaypoint) !== null && _c !== void 0 ? _c : originWaypoint }),
                    React.createElement(Text, { type: 'body', className: 'mt-2' }, DateTime.fromMillis(props.group.summary.departureTime).toLocaleString(DateTime.DATE_FULL)))),
            props.origin &&
                props.originWaypoint &&
                props.originGroup &&
                props.group.isCollapsed &&
                props.index != 0 && (React.createElement(Flexbox, { direction: 'horizontal', className: 'py-5 px-5 border-l-8 border-t border-t-slate-200 dark:border-t-neutral-700', gap: 'xl', style: {
                    borderLeftColor: props.group.color,
                }, position: 'center', nowrap: true },
                React.createElement(WaypointIcon, { id: props.originWaypoint.id, color: (_e = (_d = props.originGroup) === null || _d === void 0 ? void 0 : _d.color) !== null && _e !== void 0 ? _e : props.group.color, index: originGlobalPosition ? originGlobalPosition.index + 1 : 1, travelModel: props.originWaypoint.config.travelMode, type: props.originWaypoint.type, onClick: props.onClickOrigin, isLastInGroup: true }),
                React.createElement(Flexbox, { direction: 'vertical', className: 'flex-grow', gap: 'none' },
                    React.createElement(Text, { type: 'body', className: 'font-semibold' }, t('youAreStartingFrom')),
                    props.originWaypoint.title && (React.createElement(Text, { type: 'footnote' }, props.originWaypoint.title)),
                    !props.originWaypoint.title && props.originWaypoint.address && (React.createElement(Text, { type: 'footnote' }, toAddressString(props.originWaypoint.address)))))),
            props.group.isCollapsed && (React.createElement(AddWaypoint, { isFloating: props.waypoints > 0, index: props.index, onAdd: props.onAddWaypoint, nextWaypoint: props.index > 0 && props.group.waypoints[0]
                    ? { groupIndex: props.index, waypointIndex: 0 }
                    : null })))));
};
