import React from 'react';
import { Flexbox } from '../flexbox';
import { Text } from '../typography/text';
import { Button } from '../buttons';
import { Divider } from '../general/divider';
export const CardImage = (props) => (React.createElement("div", { className: 'aspect-video bg-cover bg-center w-full', style: { backgroundImage: `url(${props.src})` } }));
export const CardHeader = (props) => (React.createElement("div", Object.assign({}, props, { className: 'rounded-tl-lg rounded-tr-lg px-4 py-3 bg-slate-100 border-b border-slate-300 font-semibold  dark:bg-neutral-800 dark:border-neutral-600 text-black dark:text-white' }), props.children));
export const CardFooter = (props) => (React.createElement("div", Object.assign({}, props, { className: 'rounded-bl-lg rounded-br-lg px-4 py-3 bg-slate-100 border-t border-slate-300 dark:bg-neutral-800 dark:border-neutral-600' }), props.children));
export const CardBody = (props) => (React.createElement("div", Object.assign({}, props, { className: `px-4 py-3 ${props.className}` }), props.children));
export const Card = (props) => (React.createElement("div", { className: `rounded-lg border border-slate-300 dark:border-neutral-600 bg-white dark:bg-neutral-900 w-full max-h-full flex flex-col ${props.className}` }, props.children));
export const CardPreview = () => (React.createElement(Flexbox, { gap: 'lg', direction: 'vertical' },
    React.createElement("a", { id: 'cards' }),
    React.createElement(Text, { type: 'subheadline' }, "Cards"),
    React.createElement(Divider, null),
    React.createElement("div", { className: 'grid grid-cols-3 gap-5' },
        React.createElement(Card, null,
            React.createElement(CardHeader, null,
                React.createElement(Text, { className: 'font-semibold' }, "Card")),
            React.createElement(CardImage, { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Sebastiano_Ricci_002.jpg/981px-Sebastiano_Ricci_002.jpg' }),
            React.createElement(CardBody, null,
                React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
                    React.createElement(Text, { type: 'body', className: 'font-semibold' }, "A card component"),
                    React.createElement(Text, { type: 'footnote' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."))),
            React.createElement(CardFooter, null,
                React.createElement(Flexbox, { gap: 'md' },
                    React.createElement(Button, { type: 'primary' }, "More"),
                    React.createElement(Button, { type: 'secondary' }, "Delete")))),
        React.createElement(Card, null,
            React.createElement(CardHeader, null,
                React.createElement(Text, { className: 'font-semibold' }, "Card")),
            React.createElement(CardImage, { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Sebastiano_Ricci_002.jpg/981px-Sebastiano_Ricci_002.jpg' }),
            React.createElement(CardBody, null,
                React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
                    React.createElement(Text, { type: 'body', className: 'font-semibold' }, "A card component"),
                    React.createElement(Text, { type: 'footnote' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."))),
            React.createElement(CardFooter, null,
                React.createElement(Flexbox, { gap: 'md' },
                    React.createElement(Button, { type: 'primary' }, "More"),
                    React.createElement(Button, { type: 'secondary' }, "Delete")))),
        React.createElement(Card, null,
            React.createElement(CardHeader, null,
                React.createElement(Text, { className: 'font-semibold' }, "Card")),
            React.createElement(CardImage, { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Sebastiano_Ricci_002.jpg/981px-Sebastiano_Ricci_002.jpg' }),
            React.createElement(CardBody, null,
                React.createElement(Flexbox, { direction: 'vertical', gap: 'md' },
                    React.createElement(Text, { type: 'body', className: 'font-semibold' }, "A card component"),
                    React.createElement(Text, { type: 'footnote' }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."))),
            React.createElement(CardFooter, null,
                React.createElement(Flexbox, { gap: 'md' },
                    React.createElement(Button, { type: 'primary' }, "More"),
                    React.createElement(Button, { type: 'secondary' }, "Delete")))))));
