import { initialSummaryObject } from '../../interfaces/waypoint';
import { lineString, simplify } from '@turf/turf';
export const TourMiddleware = (tour) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!tour.summary)
        tour.summary = structuredClone(initialSummaryObject);
    tour.summary.lengthInMeters = 0;
    tour.summary.travelTimeInSeconds = 0;
    tour.groups.map((group, index) => {
        tour.summary.lengthInMeters += group.summary.lengthInMeters;
        tour.summary.travelTimeInSeconds += group.summary.travelTimeInSeconds;
        if (index == 0) {
            tour.summary.departureTime = group.summary.departureTime;
        }
        if (index == tour.groups.length - 1) {
            tour.summary.arrivalTime = group.summary.arrivalTime;
        }
    });
    const waypointsMerged = tour.groups.flatMap((group) => group.waypoints.flatMap((w) => (w.lineString != null ? w.lineString.coordinates : [])));
    if (waypointsMerged.length > 0) {
        const simplifiedRoute = simplify(lineString(waypointsMerged), {
            tolerance: 0.05,
            highQuality: false,
        });
        tour.lineString = {
            type: 'LineString',
            coordinates: simplifiedRoute.geometry.coordinates,
            properties: {
                arrivalTime: (_b = (_a = tour.summary) === null || _a === void 0 ? void 0 : _a.arrivalTime) !== null && _b !== void 0 ? _b : 0,
                departureTime: (_d = (_c = tour.summary) === null || _c === void 0 ? void 0 : _c.departureTime) !== null && _d !== void 0 ? _d : 0,
                lengthInMeters: (_f = (_e = tour.summary) === null || _e === void 0 ? void 0 : _e.lengthInMeters) !== null && _f !== void 0 ? _f : 0,
                travelTimeInSeconds: (_h = (_g = tour.summary) === null || _g === void 0 ? void 0 : _g.travelTimeInSeconds) !== null && _h !== void 0 ? _h : 0,
                breakInSeconds: (_k = (_j = tour.summary) === null || _j === void 0 ? void 0 : _j.breakInSeconds) !== null && _k !== void 0 ? _k : 0,
            },
        };
    }
};
