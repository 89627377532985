import { newGroup } from '../context/slices/groups';
import { newWaypoint } from '../context/slices/waypoints';
import { nanoid } from 'nanoid';
export const initNewTour = (t, user, defaultTravelMode) => {
    const groupId = nanoid();
    const tour = {
        id: null,
        title: t('unknownTour'),
        description: '',
        isPublic: false,
        groups: [newGroup(groupId, 0)],
        summary: null,
    };
    if ((user === null || user === void 0 ? void 0 : user.address) && (user === null || user === void 0 ? void 0 : user.location)) {
        tour.groups[0].waypoints.push(newWaypoint({
            travelMode: defaultTravelMode,
            address: user.address,
            location: user.location,
        }));
    }
    else {
        tour.groups[0].waypoints.push(newWaypoint({
            travelMode: defaultTravelMode,
            address: undefined,
            location: undefined,
        }));
    }
    return tour;
};
