import React, { useState } from 'react';
import { Flexbox } from '../../../ui/touring-ui/components/flexbox';
import { Text } from '../../../ui/touring-ui/components/typography/text';
import { CheckCircleIcon } from '../../../ui/touring-ui/icons/_export';
import { toAddressString } from '../../../lib/utils';
import { parseTSMarkdown } from '../../../lib/formatters';
import { generateAppleMapsWaypointLink, generateGoogleMapsWaypointLink } from '../../../lib/utils';
import { RouteSummary } from '../../../ui/components/molecules/route/summary';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui/touring-ui/components/buttons';
import { createDraft, finishDraft } from 'immer';
import { WaypointDetailIndex } from '../../../ui/components/molecules/waypoint/detail';
import { DateTime } from 'luxon';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../context/context';
export const GuideWaypoint = (props) => {
    var _a, _b, _c, _d, _e;
    const context = useApplicationCtx();
    const { waypointDetails, isIOSOriPadOS } = props;
    const group = context.tour.groups[waypointDetails.groupIndex];
    const waypoint = group.waypoints[waypointDetails.waypointIndex];
    const dispatch = useApplicationDispatchCtx();
    const [editMode, setEditMode] = useState(false);
    const previousGroup = waypointDetails.previousWaypoint != null && waypointDetails.previousWaypoint.groupIndex > -1
        ? context.tour.groups[waypointDetails.previousWaypoint.groupIndex]
        : null;
    const previousWaypoint = previousGroup != null &&
        waypointDetails.previousWaypoint != null &&
        ((_a = waypointDetails.previousWaypoint) === null || _a === void 0 ? void 0 : _a.waypointIndex) > -1
        ? previousGroup === null || previousGroup === void 0 ? void 0 : previousGroup.waypoints[(_b = waypointDetails.previousWaypoint) === null || _b === void 0 ? void 0 : _b.waypointIndex]
        : null;
    const nextGroup = waypointDetails.nextWaypoint != null && waypointDetails.nextWaypoint.groupIndex > -1
        ? context.tour.groups[waypointDetails.nextWaypoint.groupIndex]
        : null;
    const nextWaypoint = nextGroup != null && waypointDetails.nextWaypoint != null && ((_c = waypointDetails.nextWaypoint) === null || _c === void 0 ? void 0 : _c.waypointIndex) > -1
        ? nextGroup === null || nextGroup === void 0 ? void 0 : nextGroup.waypoints[(_d = waypointDetails.nextWaypoint) === null || _d === void 0 ? void 0 : _d.waypointIndex]
        : null;
    const { t } = useTranslation();
    const isLastInGroup = waypointDetails.isLastInGroup;
    const index = waypointDetails.waypointIndex;
    const seen = (_e = waypoint.summary.checkOut) !== null && _e !== void 0 ? _e : 0 > 0;
    const actions = {
        onOpenWaypointInGoogleMaps: (waypoint) => {
            window.open(generateGoogleMapsWaypointLink(waypoint), '_blank');
        },
        onOpenWaypointInAppleMaps: (waypoint) => {
            window.open(generateAppleMapsWaypointLink(waypoint), '_blank');
        },
        setSeen: (isSeen) => {
            const draft = createDraft(waypoint);
            draft.summary.checkOut = isSeen ? DateTime.now().toMillis() : 0;
            const updated = finishDraft(draft);
            dispatch({
                type: 'UPDATE_WAYPOINT',
                groupIndex: waypointDetails.groupIndex,
                waypoint: updated,
                withoutMiddleware: true,
            });
        },
        onEdit: () => {
            const newState = !editMode;
            setEditMode(newState);
        },
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `border-2 border-slate-200 bg-slate-200 rounded-xl p-2 flex flex-col gap-2 ${seen ? 'opacity-50' : ''}` },
            editMode && (React.createElement(WaypointDetailIndex, { hideExtendedRouteOptions: true, hideZoom: true, hideActivities: true, groupIndex: waypointDetails.groupIndex, waypointIndex: waypointDetails.waypointIndex })),
            !editMode && (React.createElement(React.Fragment, null,
                React.createElement(Flexbox, { position: 'center', gap: 'sm', nowrap: true, className: 'bg-slate-100 p-4 rounded-md' },
                    React.createElement(Flexbox, { direction: 'vertical', gap: 'sm', className: 'flex-grow items-center' },
                        React.createElement(Text, { type: 'footnote', className: 'font-medium text-xl text-center font-tight' }, waypoint.title),
                        waypoint.address && (React.createElement(Text, { type: 'footnote', className: `${waypoint.title ? 'text-xs' : 'font-semibold'} text-center` }, toAddressString(waypoint.address))))),
                !seen && (React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        props.type == 'origin' && nextGroup && (React.createElement(RouteSummary, { timezone: waypoint.timezone, arrivalTime: 0, departureTime: nextGroup.summary.departureTime, lengthInMeters: 0, travelTimeInSeconds: 0, breakInSeconds: 0, waypointIndex: 0, previousWaypoint: null, groupDepartureTime: nextGroup.summary.departureTime })),
                        props.type == 'destination' && (React.createElement(RouteSummary, { timezone: waypoint.timezone, arrivalTime: waypoint.summary.arrivalTime, departureTime: waypoint.summary.departureTime, lengthInMeters: waypoint.summary.lengthInMeters, travelTimeInSeconds: waypoint.summary.travelTimeInSeconds, breakInSeconds: waypoint.summary.breakInSeconds, waypointIndex: waypointDetails.waypointIndex, previousWaypoint: previousWaypoint, nextWaypoint: nextWaypoint, groupDepartureTime: group.summary.departureTime, isLastInGroup: waypointDetails.isLastInGroup }))),
                    waypoint.image && React.createElement("img", { src: waypoint.image, className: 'w-full rounded-md' }),
                    waypoint.notes && (React.createElement("div", { className: 'bg-slate-100 p-4 rounded-md text-sm' },
                        React.createElement("div", { className: 'font-semibold mb-1 font-tight' },
                            t('notes'),
                            ":"),
                        React.createElement("div", { className: 'text-black dark:text-white text-balance whitespace-pre-wrap hyphens-auto', dangerouslySetInnerHTML: {
                                __html: parseTSMarkdown(waypoint.notes),
                            } }))),
                    React.createElement("div", { className: 'bg-slate-100 p-4 rounded-md text-center' },
                        React.createElement("div", { className: 'flex flex-row gap-2' },
                            React.createElement(Button, { className: 'flex-grow place-content-center', type: 'secondary', onClick: () => actions.onOpenWaypointInGoogleMaps(waypoint) }, "Google Maps"),
                            ' ',
                            isIOSOriPadOS && (React.createElement(Button, { type: 'secondary', className: 'flex-grow place-content-center', onClick: () => actions.onOpenWaypointInAppleMaps(waypoint) }, "Apple Maps")))))),
                React.createElement("div", { className: `${seen ? 'text-lime-500' : 'text-slate-400'} text-center mt-3 mb-3 cursor-pointer`, onClick: () => actions.setSeen(!seen) },
                    React.createElement(CheckCircleIcon, { className: 'w-11 h-11 ' })))))));
};
