import React from 'react';
import { Text } from '../../../touring-ui/components/typography/text';
import { DateTime } from 'luxon';
import { formatTimeString, formatLength } from '../../../../lib/formatters';
import { useTranslation } from 'react-i18next';
import { ClockSnoozeIcon, Flag06Icon, WalkIcon } from '../../../touring-ui/icons/_export';
import { Divider } from '../../../touring-ui/components/general/divider';
export const SummaryDepartureTime = (props) => {
    var _a;
    const { t } = useTranslation();
    const timezone = props.timezone;
    return (React.createElement(Text, { type: 'footnote', className: props.className },
        !props.hideLabel && React.createElement("span", { className: 'font-semibold' }, (_a = props.customLabel) !== null && _a !== void 0 ? _a : t('start')),
        props.departureTime == 0 && '-',
        props.departureTime > 0 && (React.createElement("span", { className: `${props.onEdit
                ? 'cursor-pointer underline underline-offset-2 decoration-dotted decoration-slate-600'
                : ''}`, onClick: props.onEdit }, DateTime.fromMillis(props.departureTime).setZone(timezone).toLocaleString(DateTime.TIME_24_SIMPLE)))));
};
export const SummaryArrivalTime = (props) => {
    const { t } = useTranslation();
    const timezone = props.timezone;
    return (React.createElement(Text, { type: 'footnote', className: props.className },
        !props.hideLabel && React.createElement("span", { className: 'font-semibold' }, t('arrival')),
        props.arrivalTime == 0 && '-',
        props.arrivalTime > 0 &&
            DateTime.fromMillis(props.arrivalTime).setZone(timezone).toLocaleString(DateTime.TIME_24_SIMPLE)));
};
export const SummaryLengthInMeters = (props) => {
    const { t } = useTranslation();
    return (React.createElement(Text, { type: 'footnote', className: props.className },
        !props.hideLabel && React.createElement("span", { className: 'font-semibold' }, t('distance')),
        props.lengthInMeters == 0 && '-',
        props.lengthInMeters > 0 && formatLength(props.lengthInMeters)));
};
export const SummaryTravelTimeInSeconds = (props) => {
    const { t } = useTranslation();
    return (React.createElement(Text, { type: 'footnote', className: props.className },
        !props.hideLabel && React.createElement("span", { className: 'font-semibold' }, t('travelTime')),
        props.travelTimeInSeconds == 0 && '-',
        props.travelTimeInSeconds > 0 && formatTimeString(props.travelTimeInSeconds)));
};
export const SummaryBreakInSeconds = (props) => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { type: 'footnote', className: props.className },
            !props.hideLabel && React.createElement("span", { className: 'font-semibold' }, t('stay')),
            (props.breakInSeconds == 0 || !props.breakInSeconds) && t('-'),
            props.breakInSeconds > 0 && formatTimeString(props.breakInSeconds))));
};
export const RouteSummary = (props) => {
    return (React.createElement("div", { className: 'text-black dark:text-white text-sm' },
        React.createElement("div", { className: "rounded-md bg-slate-100 px-3 pt-3 flex flex-col gap-2" },
            React.createElement("div", { className: 'flex md:hidden flex-row gap-1 place-content-center' },
                React.createElement(SummaryTravelTimeInSeconds, { className: 'text-slate-400', travelTimeInSeconds: props.travelTimeInSeconds > 60 ? props.travelTimeInSeconds : 60 }),
                React.createElement("div", { className: 'text-slate-400' }, "/"),
                React.createElement(SummaryLengthInMeters, { className: 'text-slate-400', lengthInMeters: props.lengthInMeters })),
            React.createElement("div", { className: 'block md:hidden' },
                React.createElement(Divider, null)),
            React.createElement("div", { className: "flex w-full flex-row place-content-center items-center gap-2 py-10" },
                props.arrivalTime > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'relative flex-grow' },
                        React.createElement("div", { className: "relative h-4 flex-grow rounded-full bg-slate-300 animate_direction" }),
                        React.createElement("div", { className: "hidden md:flex transform absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 whitespace-nowrap font-semibold flex-col gap-0 rounded-md p-1 bg-white/70 px-2 place-content-center place-items-center" },
                            React.createElement(SummaryTravelTimeInSeconds, { className: 'text-slate-400 text-xs font-normal', travelTimeInSeconds: props.travelTimeInSeconds > 60 ? props.travelTimeInSeconds : 60, hideLabel: true }),
                            React.createElement(SummaryLengthInMeters, { className: 'text-slate-400 text-xs font-normal', lengthInMeters: props.lengthInMeters, hideLabel: true }))),
                    React.createElement("div", { className: "relative h-6 w-6 rounded-full bg-indigo-600 flex place-content-center items-center text-white" },
                        React.createElement(Flag06Icon, { className: 'w-4 h-4' }),
                        React.createElement("div", { className: "transform absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap font-semibold text-black bg-white rounded-md p-1  flex flex-row gap-1" },
                            React.createElement(SummaryArrivalTime, { arrivalTime: props.arrivalTime, hideLabel: true, timezone: props.timezone }))))),
                props.breakInSeconds > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "h-2 w-[35px] flex-none rounded-full bg-slate-300" }),
                    React.createElement("div", { className: "relative h-6 w-6 rounded-full bg-indigo-600 flex place-content-center items-center text-white" },
                        React.createElement(ClockSnoozeIcon, { className: 'w-4 h-4' }),
                        React.createElement("div", { className: "transform absolute left-1/2 -translate-x-1/2 top-[27px] md:top-[23px] whitespace-nowrap font-semibold text-black bg-white rounded-md p-1" },
                            React.createElement(SummaryBreakInSeconds, { breakInSeconds: props.breakInSeconds, hideLabel: true }))))),
                props.arrivalTime != props.departureTime && (React.createElement(React.Fragment, null,
                    !props.previousWaypoint && React.createElement("div", { className: "relative h-4 flex-grow rounded-full " }),
                    props.previousWaypoint && (React.createElement("div", { className: "h-2 w-[35px] flex-none rounded-full bg-slate-300" })),
                    React.createElement("div", { className: "relative h-6 w-6 rounded-full bg-indigo-600 flex place-content-center items-center text-white" },
                        React.createElement(WalkIcon, { className: 'w-4 h-4' }),
                        React.createElement("div", { className: `transform absolute left-1/2 -translate-x-1/2 -top-8 whitespace-nowrap font-semibold text-black bg-white rounded-md p-1` },
                            React.createElement(SummaryDepartureTime, { departureTime: props.departureTime, hideLabel: true, timezone: props.timezone }))))),
                !props.isLastInGroup && (React.createElement("div", { className: 'relative flex-grow' },
                    React.createElement("div", { className: "relative h-4 flex-grow rounded-full bg-slate-300 animate_direction" }),
                    props.nextWaypoint && (React.createElement("div", { className: "hidden md:flex transform absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 whitespace-nowrap font-semibold flex-col gap-0 rounded-md p-1 bg-white/70 px-2 place-content-center place-items-center" },
                        React.createElement(SummaryTravelTimeInSeconds, { className: 'text-slate-400 text-xs font-normal', travelTimeInSeconds: props.nextWaypoint.summary.travelTimeInSeconds > 60
                                ? props.nextWaypoint.summary.travelTimeInSeconds
                                : 60, hideLabel: true }),
                        React.createElement(SummaryLengthInMeters, { className: 'text-slate-400 text-xs font-normal', lengthInMeters: props.nextWaypoint.summary.lengthInMeters, hideLabel: true }))))),
                props.isLastInGroup && React.createElement("div", { className: "h-4 flex-grow rounded-full" })))));
};
