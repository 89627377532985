var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fuzzySearch } from '../../../../../api/tomtom';
import debounce from 'lodash.debounce';
import { toAddressString } from '../../../../../lib/utils';
import { Select } from '../../../../touring-ui/components/form/select';
export const WaypointGenericSearchAddress = (props) => {
    const { t } = useTranslation();
    const [foundAddresses, setFoundAddresses] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const searchAddress = (value) => __awaiter(void 0, void 0, void 0, function* () {
        if (!value || value.length == 0)
            return;
        setLoading(true);
        const addresses = yield fuzzySearch(value);
        setFoundAddresses(addresses !== null && addresses !== void 0 ? addresses : []);
        setLoading(false);
    });
    const onSearchAddress = useCallback(debounce(searchAddress, 500, { maxWait: 1000 }), []);
    return (React.createElement(Select, { disableAutoFocus: props.disableAutoFocus, selected: props.selected, placeholder: t('searchForPlace'), type: props.type, renderSelected: (selected) => {
            if (props.renderSelected) {
                return props.renderSelected(selected);
            }
            else {
                return selected.address ? toAddressString(selected.address) : '';
            }
            return '';
        }, setSelected: (selected) => {
            if (!props.onSetAddress)
                return;
            props.onSetAddress(selected);
        }, options: foundAddresses, renderOption: (option) => (React.createElement(React.Fragment, null,
            option.poi && React.createElement("div", { className: 'font-medium' }, option.poi.name),
            option.address && React.createElement("div", null, toAddressString(option.address)))), onFilter: (value) => {
            onSearchAddress.cancel();
            onSearchAddress(value);
        }, isLoading: isLoading }));
};
