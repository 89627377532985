import React, { useEffect } from 'react';
import { LayoutDefault } from '../../ui/components/molecules/layout/default';
import { UserLoginIndex } from '../../ui/components/molecules/user/login';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApplicationCtx } from '../../context/context';
export const LoginPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const context = useApplicationCtx();
    const action = {
        redirect: () => {
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has('from')) {
                const url = decodeURIComponent(searchParams.get('from'));
                navigate(url);
            }
            else {
                navigate('/');
            }
        },
    };
    useEffect(() => {
        if (context.user) {
            action.redirect();
        }
    }, []);
    return (React.createElement(LayoutDefault, null,
        React.createElement(UserLoginIndex, { onDone: action.redirect })));
};
