import React, { useEffect, useState } from 'react';
import { Flexbox } from '../../../ui/touring-ui/components/flexbox';
import { Text } from '../../../ui/touring-ui/components/typography/text';
import { DateTime } from 'luxon';
import { GroupSummary } from '../../../ui/components/molecules/groups/details/summary';
import { parseTSMarkdown } from '../../../lib/formatters';
import { findNextWaypoint, findPreviousWaypoint } from '../../../context/slices/waypoints';
import { GuideWaypoint } from './waypoint';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { lastWaypointOfPreviousGroup } from '../../../context/effects/groups/useOrigin';
import { useApplicationCtx } from '../../../context/context';
import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon } from '../../../ui/touring-ui/icons/_export';
import { addAlpha } from '../../../lib/color';
export const GuideGroup = (props) => {
    var _a;
    const { t } = useTranslation();
    const context = useApplicationCtx();
    const { groupIndex, isMobileOrTablet, isIOSOriPadOS } = props;
    const group = context.tour.groups[groupIndex];
    const isResolved = group.waypoints.filter((item) => { var _a; return (_a = item.summary.checkOut) !== null && _a !== void 0 ? _a : 0 > 0; }).length == group.waypoints.length;
    const originDetails = lastWaypointOfPreviousGroup(groupIndex, context.tour);
    const originWaypoint = originDetails
        ? context.tour.groups[originDetails === null || originDetails === void 0 ? void 0 : originDetails.groupIndex].waypoints[originDetails.waypointIndex]
        : group.waypoints.length > 0
            ? group.waypoints[0]
            : null;
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => { }, [isResolved]);
    return (React.createElement("div", { key: group.id },
        React.createElement(Flexbox, { direction: 'vertical', gap: 'none', className: 'mb-5 p-5 rounded-2xl', style: { backgroundColor: addAlpha(group.color, 0.2) } },
            React.createElement("div", { className: 'flex flex-row gap-5 items-center' },
                React.createElement("div", null,
                    !isResolved && (React.createElement("div", { className: 'w-8 h-8 flex items-center justify-center rounded-full text-white', style: { backgroundColor: addAlpha(group.color, 1) } }, groupIndex + 1)),
                    isResolved && (React.createElement("div", { className: `text-lime-500 text-center` },
                        React.createElement(CheckCircleIcon, { className: 'w-8 h-8' })))),
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement(Text, { className: 'text-sm text-gray-700' }, DateTime.fromMillis(group.summary.departureTime).toLocaleString(DateTime.DATE_FULL)),
                    React.createElement(Text, { className: 'text-xl font-semibold' }, group.title)),
                React.createElement("div", { className: 'flex-grow' }),
                React.createElement("div", { className: 'relative', onClick: () => setIsOpen(!isOpen) },
                    React.createElement("div", { className: 'w-10 h-10 flex-none bg-white items-center flex place-content-center rounded-full' },
                        isOpen && React.createElement(ChevronDownIcon, { className: 'w-8 h-8 pt-1' }),
                        !isOpen && React.createElement(ChevronRightIcon, { className: 'w-6 h-6' })))),
            React.createElement(React.Fragment, null,
                (!isResolved || isOpen) && (React.createElement("div", { className: 'p-2 bg-slate-100 rounded-lg my-2' },
                    React.createElement(GroupSummary, { color: group.color, summary: group.summary, origin: originWaypoint }))),
                (group.image || group.description) && (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg', className: 'mb-5' },
                    group.image && React.createElement("img", { src: group.image, className: 'w-full rounded-lg' }),
                    group.description && (React.createElement("div", { className: 'text-balance whitespace-pre-wrap hyphens-auto text-md text-black dark:text-white', dangerouslySetInnerHTML: {
                            __html: parseTSMarkdown((_a = group === null || group === void 0 ? void 0 : group.description) !== null && _a !== void 0 ? _a : ''),
                        } })))),
                isOpen && (React.createElement(Flexbox, { direction: 'vertical', gap: 'xl', className: 'my-4' }, group.waypoints.map((waypoint, waypointIndex) => {
                    const previouseWaypointDetails = findPreviousWaypoint(context.tour, waypoint.id);
                    const nextWaypointDetails = findNextWaypoint(context.tour, waypoint.id);
                    return (React.createElement(React.Fragment, { key: nanoid() },
                        groupIndex > 0 && waypointIndex == 0 && previouseWaypointDetails && (React.createElement(GuideWaypoint, { type: 'origin', isMobileOrTablet: isMobileOrTablet, isIOSOriPadOS: isIOSOriPadOS, waypointDetails: {
                                groupIndex: previouseWaypointDetails.groupIndex,
                                waypointIndex: previouseWaypointDetails.waypointIndex,
                                isLastInGroup: false,
                                previousWaypoint: null,
                                nextWaypoint: {
                                    waypointIndex: waypointIndex,
                                    groupIndex: groupIndex,
                                },
                            } })),
                        React.createElement(GuideWaypoint, { type: 'destination', isMobileOrTablet: isMobileOrTablet, isIOSOriPadOS: isIOSOriPadOS, waypointDetails: {
                                groupIndex: groupIndex,
                                waypointIndex: waypointIndex,
                                isLastInGroup: group.waypoints.length - 1 == waypointIndex,
                                previousWaypoint: previouseWaypointDetails,
                                nextWaypoint: nextWaypointDetails,
                            } })));
                })))))));
};
