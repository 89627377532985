import React, { useEffect, useRef, useState } from 'react';
import MapLibreGL from 'maplibre-gl';
import { PlaceIcon } from './icon';
import { zoomToPlaces } from '../../../../lib/zoom';
export const PlaceCluster = (props) => {
    const markerRef = useRef(null);
    const markerElementRef = useRef(null);
    const [leaves, setLeaves] = useState([]);
    const initMarker = () => {
        if (markerRef.current == null || !props.map)
            return;
        return new MapLibreGL.Marker({
            element: markerRef.current,
            draggable: false,
            className: 'cluster-place-marker',
        })
            .setLngLat(props.cluster.geometry.coordinates)
            .addTo(props.map);
    };
    useEffect(() => {
        if (!props.superCluster)
            return;
        try {
            setLeaves(props.superCluster.getLeaves(props.cluster.id, Infinity));
        }
        catch (error) { }
        const markerElement = initMarker();
        markerElementRef.current = markerElement !== null && markerElement !== void 0 ? markerElement : null;
        return () => {
            markerElement === null || markerElement === void 0 ? void 0 : markerElement.remove();
        };
    }, [props.cluster, props.superCluster]);
    return (React.createElement("div", { className: 'hidden' },
        React.createElement("div", { ref: markerRef, className: 'relative', onClick: () => zoomToPlaces(leaves.map((leave) => leave.properties)) },
            React.createElement(PlaceIcon, { type: 'cluster', color: 'bg-slate-300 border-slate-500 text-slate-600', isSelected: false }),
            React.createElement("div", { className: 'absolute -top-2 -right-2 bg-red-400 w-6 h-6 flex place-items-center place-content-center rounded-full font-semibold text-white z-60' }, leaves.length > 10 ? 10 + '+' : leaves.length))));
};
