export const handleApiErrorResponse = (error) => {
    switch (error.response.status) {
        case 401:
            window.dispatchEvent(new CustomEvent('showLogin'));
            break;
        case 429:
            window.dispatchEvent(new CustomEvent('showPurchaseScreen', { detail: error.response.data.error }));
            break;
        default:
            break;
    }
};
