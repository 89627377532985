import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '../../../touring-ui/icons/_export';
import { DateTime } from 'luxon';
import { autoPlacement, offset, useFloating } from '@floating-ui/react';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
export const CalendarIndex = ({ initialValue, onChange, zone }) => {
    var _a;
    const [selectedDate, setSelectedDate] = useState(initialValue ? DateTime.fromMillis(initialValue, { zone: zone }) : null);
    const [currentDate, setCurrentDate] = useState(DateTime.utc());
    const today = useRef(DateTime.utc());
    useEffect(() => {
        if (initialValue) {
            setSelectedDate(DateTime.fromMillis(initialValue, { zone: zone }));
            setCurrentDate(DateTime.fromMillis(initialValue, { zone: zone }).set({ day: 1 }));
        }
    }, [initialValue]);
    const daysInMonth = (_a = currentDate.daysInMonth) !== null && _a !== void 0 ? _a : 0;
    const firstDayOfMonth = currentDate.set({ day: 1 });
    const startingDayOfWeek = firstDayOfMonth.weekday;
    const renderDays = () => {
        const days = [];
        // Render empty cells for the days before the start of the month
        for (let i = 0; i < startingDayOfWeek; i++) {
            days.push(React.createElement("div", { key: `empty-${i}`, className: "text-center border-b border-r dark:border-neutral-700" }));
        }
        // Render the days of the month
        for (let day = 1; day <= daysInMonth; day++) {
            const date = firstDayOfMonth.set({ day });
            const isCurrentDate = +today.current.startOf('day') === +date.startOf('day');
            const isSelectedDate = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.hasSame(date, 'day');
            days.push(React.createElement("div", { key: day, onClick: () => handleDateClick(date), className: `cursor-pointer p-2 text-center border-b border-r dark:border-neutral-700 
                    ${isSelectedDate && !isCurrentDate ? 'bg-indigo-600 text-white' : ''} 
                    ${isCurrentDate && !isSelectedDate ? 'bg-slate-100 dark:bg-neutral-800 text-white' : ''}
                    ${isSelectedDate && isCurrentDate ? 'bg-indigo-600 text-white' : ''} 
                    ` }, day));
        }
        const fillingDays = 7 - (days.length % 7);
        // Render the days of the next month
        for (let day = 0; day < fillingDays; day++) {
            days.push(React.createElement("div", { key: `next-${day}`, className: "text-center text-gray-500 border-b border-r dark:border-neutral-700" }));
        }
        return days;
    };
    const handleDateClick = (date) => {
        setSelectedDate(date);
        onChange(date.toUTC().toMillis());
    };
    const handleNextMonth = () => {
        setCurrentDate(currentDate.plus({ months: 1 }));
    };
    const handlePrevMonth = () => {
        setCurrentDate(currentDate.minus({ months: 1 }));
    };
    return (React.createElement("div", { className: "w-full" },
        React.createElement("div", { className: "flex justify-between items-center mb-2" },
            React.createElement("button", { onClick: handlePrevMonth, className: "px-2 py-1 bg-slate-100 dark:bg-neutral-800 rounded-xl" },
                React.createElement(ChevronLeftIcon, { className: 'w-4 h-4' })),
            React.createElement("div", { className: "font-bold text-center" }, currentDate.toFormat('MMMM yyyy')),
            React.createElement("button", { onClick: handleNextMonth, className: "px-2 py-1 bg-slate-100 dark:bg-neutral-800 rounded-xl" },
                React.createElement(ChevronRightIcon, { className: 'w-4 h-4' }))),
        React.createElement("div", { className: "grid grid-cols-7 border-t border-l dark:border-neutral-700" },
            React.createElement("div", { className: "text-center border-b border-r  bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Sun"),
            React.createElement("div", { className: "text-center border-b border-r bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Mon"),
            React.createElement("div", { className: "text-center border-b border-r bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Tue"),
            React.createElement("div", { className: "text-center border-b border-r bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Wed"),
            React.createElement("div", { className: "text-center border-b border-r bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Thu"),
            React.createElement("div", { className: "text-center border-b border-r bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Fri"),
            React.createElement("div", { className: "text-center border-b border-r bg-slate-100 dark:bg-neutral-800 dark:border-neutral-700 font-semibold" }, "Sat"),
            renderDays())));
};
export const TimePicker = ({ selectedTime, onChange, zone }) => {
    const initialValue = DateTime.fromMillis(selectedTime).setZone(zone);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const hourScrollBar = useRef();
    const minuteScrollbar = useRef();
    useEffect(() => {
        if (!hourScrollBar.current)
            return;
        const item = hourScrollBar.current.querySelectorAll('.selected');
        const container = hourScrollBar.current.getBoundingClientRect();
        const selected = item[0].getBoundingClientRect();
        hourScrollBar.current.scrollTo({ top: selected.top - container.top - 10 });
    }, [hourScrollBar, isOpen]);
    useEffect(() => {
        if (!minuteScrollbar.current)
            return;
        const item = minuteScrollbar.current.querySelectorAll('.selected');
        const container = minuteScrollbar.current.getBoundingClientRect();
        const selected = item[0].getBoundingClientRect();
        minuteScrollbar.current.scrollTo({ top: selected.top - container.top - 10 });
    }, [minuteScrollbar, isOpen]);
    const handleHourChange = (value) => {
        updateTime(value, initialValue.toFormat('mm'));
        setIsOpen(false);
    };
    const handleMinuteChange = (value) => {
        updateTime(initialValue.toFormat('HH'), value);
        setIsOpen(false);
    };
    const updateTime = (newHour, newMinute) => {
        const newTime = initialValue
            .set({ hour: parseInt(newHour), minute: parseInt(newMinute) })
            .toUTC()
            .toMillis();
        onChange(newTime);
    };
    const { refs, floatingStyles, context } = useFloating({
        middleware: [
            autoPlacement({
                allowedPlacements: ['bottom-start', 'top-start'],
            }),
            offset(5),
        ],
    });
    return (React.createElement("div", { className: "w-full", ref: refs.setReference },
        React.createElement("div", { className: 'flex items-center cursor-pointer p-2 !box-border w-full rounded-md outline-none border border-slate-300 focus:!border-indigo-600 dark:bg-neutral-900 dark:border-neutral-700 dark:text-white text-md disabled:!text-neutral-500 dark:disabled:!text-gray-600', onClick: () => setIsOpen(!isOpen) },
            initialValue.toLocaleString(DateTime.TIME_24_SIMPLE),
            React.createElement("div", { className: 'flex-grow' }),
            React.createElement(ChevronDownIcon, { className: 'w-4 h-4' })),
        isOpen && (React.createElement("div", { ref: refs.setFloating, className: 'border rounded-lg p-2 shadow bg-white grid grid-cols-2 gap-1', style: floatingStyles },
            React.createElement("div", { className: 'text-center font-semibold' }, t('hourShortDesc')),
            React.createElement("div", { className: 'text-center font-semibold' }, t('minuteShortDesc')),
            React.createElement(SimpleBar, { scrollableNodeProps: { ref: hourScrollBar }, style: { maxHeight: '150px', width: '45px' }, className: "bg-white mr-1 border border-slate-200 dark:border-neutral-700 dark:bg-neutral-800 rounded-md dark:text-white font-semibold p-2 flex-grow text-sm" },
                React.createElement("ul", null, [...Array(24)].map((_, index) => (React.createElement("li", { key: index, onClick: () => handleHourChange(index.toString().padStart(2, '0')), className: `px-1 cursor-pointer text-center ${initialValue.toFormat('HH') == index.toString().padStart(2, '0')
                        ? 'bg-indigo-600 text-white selected'
                        : 'bg-transparent'}` }, index.toString().padStart(2, '0')))))),
            React.createElement(SimpleBar, { scrollableNodeProps: { ref: minuteScrollbar }, style: { maxHeight: '150px', width: '45px' }, className: "bg-white mr-1 border border-slate-200 dark:border-neutral-700 dark:bg-neutral-800 rounded-md dark:text-white font-semibold p-2 flex-grow text-sm" },
                React.createElement("ul", null, [...Array(60)].map((_, index) => (React.createElement("li", { key: index, onClick: () => handleMinuteChange(index.toString().padStart(2, '0')), className: `px-1 cursor-pointer text-center ${initialValue.toFormat('mm') == index.toString().padStart(2, '0')
                        ? 'bg-indigo-600 text-white selected'
                        : 'bg-transparent'}` }, index.toString().padStart(2, '0'))))))))));
};
