import React from 'react';
import { BedIcon, CalendarCheck02Icon, FoodIcon, MarkerPin01Icon, ParkingIcon, PlaneIcon, } from '../../../../touring-ui/icons/_export';
import { Text } from '../../../../touring-ui/components/typography/text';
export const PoiIcon = (props) => {
    const sizeWrapper = (() => {
        var _a;
        switch ((_a = props.size) !== null && _a !== void 0 ? _a : 'md') {
            case 'xs':
                return 'w-6 h-6';
            case 'sm':
                return 'w-8 h-8';
            case 'md':
                return 'w-16 h-16';
            case 'lg':
                return 'w-24 h-24';
        }
    })();
    const size = (() => {
        var _a;
        switch ((_a = props.size) !== null && _a !== void 0 ? _a : 'md') {
            case 'xs':
                return 'w-4 h-4';
            case 'sm':
                return 'w-4 h-4';
            case 'md':
                return 'w-8 h-8';
            case 'lg':
                return 'w-16 h-16';
        }
    })();
    return (React.createElement("div", { className: `${sizeWrapper} flex flex-none items-center justify-center rounded-full text-white bg-slate-200 dark:bg-neutral-800` },
        React.createElement(Text, null,
            props.type == 'default' && React.createElement(MarkerPin01Icon, { className: `${size} opacity-50` }),
            props.type == 'parking_lot' && React.createElement(ParkingIcon, { className: `${size} opacity-50` }),
            props.type == 'hotel' && React.createElement(BedIcon, { className: `${size} opacity-50` }),
            props.type == 'restaurant' && React.createElement(FoodIcon, { className: `${size} opacity-50` }),
            props.type == 'tourist_attraction' && React.createElement(CalendarCheck02Icon, { className: `${size} opacity-50` }),
            props.type == 'flight' && React.createElement(PlaneIcon, { className: `${size} opacity-50` }))));
};
