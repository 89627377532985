import React from 'react';
import { Flexbox } from '../flexbox';
export const Text = (props) => {
    const size = (() => {
        var _a;
        const selectedSize = (_a = props.type) !== null && _a !== void 0 ? _a : 'body';
        switch (selectedSize) {
            case 'headline':
                return `text-3xl font-semibold`;
            case 'subheadline':
                return `text-xl`;
            case 'title':
                return `text-lg`;
            case 'body':
                return `text-md`;
            case 'footnote':
                return `text-sm`;
        }
    })();
    return (React.createElement("div", Object.assign({}, props, { className: `text-black dark:text-white flex flex-row gap-1 items-center ${size} ${props.className}`, onClick: props.onClick }), props.children));
};
export const TextPreview = () => (React.createElement(Flexbox, { direction: 'vertical', gap: 'md', className: 'mb-14' },
    React.createElement(Text, { type: 'headline' }, "Components"),
    React.createElement(Text, { type: 'subheadline' }, "React Components for touring.studio"),
    React.createElement(Text, null, "Welcome to our React Components Preview Page, where innovation meets visualization! Explore the building blocks of modern web development with our meticulously crafted React components. Whether you're a seasoned developer or just starting your coding journey, this page serves as a dynamic showcase of the diverse and powerful UI elements that can elevate your projects.")));
