import i18n from '../lib/i18n';
export const formatTimeString = (seconds) => {
    if (!Number.isInteger(seconds)) {
        throw new Error('Input must be an integer representing the number of seconds.');
    }
    if (seconds < 0) {
        throw new Error('Input must be a non-negative integer.');
    }
    // Calculate days, hours, and remaining minutes
    const days = Math.floor(seconds / (24 * 3600));
    let remainingSeconds = seconds % (24 * 3600);
    const hours = Math.floor(remainingSeconds / 3600);
    remainingSeconds %= 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const timeString = [];
    if (days) {
        timeString.push(i18n.t('day', { count: days }));
    }
    if (hours) {
        timeString.push(i18n.t('hourShort', { count: hours }));
    }
    if (minutes) {
        timeString.push(i18n.t('minuteShort', { count: minutes }));
    }
    // Format the time string
    return timeString.join(' ');
};
export const formatLength = (lengthInMeters) => {
    if (!Number.isInteger(lengthInMeters) || lengthInMeters < 0) {
        throw new Error('Input must be a non-negative integer representing length in meters.');
    }
    const kilometers = Math.floor(lengthInMeters / 1000);
    const remainingMeters = lengthInMeters % 1000;
    if (kilometers === 0) {
        return `${remainingMeters} m`;
    }
    else {
        return `${kilometers} km`;
    }
};
export const parseTSMarkdown = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // Parse bold and italic
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
    // Replace URLs with HTML anchor tags
    text = text.replace(urlRegex, (url) => {
        // Extract the top-level domain (TLD) from the URL
        const tldMatch = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim);
        const tld = tldMatch ? tldMatch[0] : url;
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-indigo-600 hover:opacity-60 underline underline-offset-4 decoration-dashed">${tld}</a>`;
    });
    return text;
};
export const trimText = (text) => (text.length > 30 ? text.substring(0, 30) + '…' : text);
