import React from 'react';
import { CalendarIndex, TimePicker } from '.';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../context/context';
import { createDraft, finishDraft } from 'immer';
import { CardBody, CardFooter } from '../../../touring-ui/components/cards';
import { Flexbox } from '../../../touring-ui/components/flexbox';
import { Text } from '../../../touring-ui/components/typography/text';
export const CalendarPicker = (props) => {
    const context = useApplicationCtx();
    const group = context.tour.groups[props.groupIndex];
    const dispatch = useApplicationDispatchCtx();
    return (React.createElement(React.Fragment, null,
        React.createElement(CardBody, null,
            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
                React.createElement(Text, { type: 'footnote' },
                    React.createElement(CalendarIndex, { initialValue: group.summary.departureTime, onChange: (timestampInMilliseconds) => {
                            const draft = createDraft(group);
                            draft.summary.departureTime = timestampInMilliseconds;
                            dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft) });
                        } })))),
        React.createElement(CardFooter, null,
            React.createElement(Flexbox, null,
                React.createElement(Text, { type: 'footnote', className: 'font-semibold text-sm' }, "Uhrzeit"),
                React.createElement(TimePicker, { selectedTime: group.summary.departureTime, onChange: (timestampInMilliseconds) => {
                        const draft = createDraft(group);
                        draft.summary.departureTime = timestampInMilliseconds;
                        dispatch({ type: 'UPDATE_GROUP', group: finishDraft(draft) });
                    } })))));
};
