import React from 'react';
import { Text } from '../../../../touring-ui/components/typography/text';
import { UserRight01Icon } from '../../../../touring-ui/icons/_export';
import { Form } from '../../../../touring-ui/components/form';
import { Input } from '../../../../touring-ui/components/form/input';
import { Button } from '../../../../touring-ui/components/buttons';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { useTranslation } from 'react-i18next';
import { Divider } from '../../../../touring-ui/components/general/divider';
export const UserLoginEmailPassword = (props) => {
    if (props.state != 'requestCode')
        return React.createElement(React.Fragment, null);
    const { t } = useTranslation();
    return (React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
        React.createElement(Text, { type: 'title', className: 'font-semibold' },
            React.createElement(UserRight01Icon, { className: "w-8 h-8" }),
            t('signIn')),
        React.createElement(Divider, null),
        React.createElement(Text, null, t('signInWithEmailAndPassword')),
        React.createElement(Form, { onSubmit: (e) => {
                e.preventDefault();
                props.onLogin();
            } },
            React.createElement(Input, { name: 'email', type: 'email', value: props.email, placeholder: "Email", onChange: (e) => props.onSetEmail(e.currentTarget.value) }),
            React.createElement(Input, { type: 'password', name: 'password', value: props.password, placeholder: "Password", onChange: (e) => props.onSetPassword(e.currentTarget.value) }),
            React.createElement(Button, { onClick: props.onLogin, type: 'primary', size: 'md', className: 'justify-center' }, t('login')))));
};
