import React from 'react';
import { parseTSMarkdown } from '../../../../lib/formatters';
export const TutorialItem = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col gap-3 ' },
            props.image && React.createElement("img", { src: props.image, className: 'w-full' }),
            React.createElement("div", { className: 'bg-slate-100 p-3 rounded-lg gap-5 grid grid-cols-1 lg:grid-cols-2' },
                props.video && (React.createElement("div", { className: 'col-span-4' },
                    React.createElement("div", { className: 'video-container w-full border-2 border-slate-400 rounded-md overflow-hidden' },
                        React.createElement("iframe", { src: `https://www.youtube-nocookie.com/embed/${props.video}?si=fxBykcswqyBYD1b5&autoplay=0&cc_load_policy=0&fs=1&loop=0&color=white&iv_load_policy=3&showinfo=0&rel=0`, title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true })))),
                React.createElement("div", { className: 'col-span-2 text-[13px]' },
                    React.createElement("div", { className: "whitespace-pre-wrap", dangerouslySetInnerHTML: {
                            __html: parseTSMarkdown(props.description),
                        } }),
                    props.tip && (React.createElement("div", { className: "whitespace-pre-wrap bg-white p-3 rounded-lg", dangerouslySetInnerHTML: {
                            __html: parseTSMarkdown(props.tip),
                        } })),
                    props.audio && (React.createElement("audio", { controls: true, className: 'w-full' },
                        React.createElement("source", { src: props.audio, type: "audio/mpeg" }))))))));
};
