var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// placeApi.ts
import axios from 'axios';
import { getAccessToken } from '../../lib/accessToken';
import { handleApiErrorResponse } from './apiErrorHandler';
const baseURL = process.env['PLACE_API_BASE_URL'];
const CancelToken = axios.CancelToken;
let cancel;
export function savePlace(place) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places`;
        try {
            const response = yield axios.post(url, place, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        }
        catch (error) {
            // Handle error (e.g., log or throw an exception)
            console.error('Error fetching place details:', error.message);
            throw error;
        }
    });
}
export function updatePlace(place) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places/${place._id}`;
        try {
            const response = yield axios.patch(url, place, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        }
        catch (error) {
            // Handle error (e.g., log or throw an exception)
            console.error('Error fetching place details:', error.message);
            throw error;
        }
    });
}
// Function to fetch tour details by ID
export function getPlaceById(placeId) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places/${placeId}`;
        try {
            const response = yield axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        }
        catch (error) {
            // Handle error (e.g., log or throw an exception)
            console.error('Error fetching place details:', error.message);
            throw error;
        }
    });
}
export function getPlaces(bbox) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places?bbox=${JSON.stringify(bbox)}`;
        if (cancel) {
            cancel();
        }
        const response = yield axios.get(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            cancelToken: new CancelToken(function executor(c) {
                // Diese Funktion bekommt eine Funktion zum Abbrechen der Anfrage als Argument
                cancel = c;
            }),
        });
        return response.data;
    });
}
export function deletePlace(placeId) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places/${placeId}`;
        try {
            const response = yield axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        }
        catch (error) {
            // Handle error (e.g., log or throw an exception)
            console.error('Error fetching tour details:', error.message);
            throw error;
        }
    });
}
export function uploadImage(placeId, acceptedFiles, setUploadProgress) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places/${placeId}/image`;
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
            formData.append('image', file);
        });
        try {
            const response = yield axios.post(url, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                cancelToken: new CancelToken(function executor(c) {
                    // Diese Funktion bekommt eine Funktion zum Abbrechen der Anfrage als Argument
                    cancel = c;
                }),
                onUploadProgress: (progressEvent) => {
                    var _a;
                    const progress = (progressEvent.loaded / ((_a = progressEvent.total) !== null && _a !== void 0 ? _a : 0)) * 100;
                    setUploadProgress(progress);
                },
            });
            return response.data.image;
        }
        catch (error) {
            if (axios.isCancel(error)) {
                console.log('Upload canceled');
            }
            else {
                // Handle other errors
                console.error('Error uploading images:', error);
            }
            return undefined;
        }
    });
}
export function deletePlaceImage(placeId) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/places/${placeId}/image`;
        try {
            const response = yield axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.status == 200) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            // Handle error (e.g., log or throw an exception)
            console.error('Error fetching tour details:', error.message);
            throw error;
        }
    });
}
export const getPlaceImageURL = (place) => {
    var _a, _b;
    return ((_a = place.image) === null || _a === void 0 ? void 0 : _a.url) ? `${(_b = place.image) === null || _b === void 0 ? void 0 : _b.url}&rnd=${new Date().getTime()}` : undefined;
};
export function getPoisNearby(lat, lng, type, lang, radius) {
    return __awaiter(this, void 0, void 0, function* () {
        const accessToken = getAccessToken();
        const url = `${baseURL}/overpass?lng=${lng}&lat=${lat}&type=${type}&lang=${lang}&radius=${radius}`;
        try {
            const response = yield axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        }
        catch (error) {
            handleApiErrorResponse(error);
            return null;
        }
    });
}
