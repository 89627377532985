import React, { useEffect } from 'react';
import { Place } from './item';
import { nanoid } from 'nanoid';
import { PlaceDetailIndex } from './generic/detail';
import { useModalDispatchCtx } from '../../atoms/modal/context';
import { useTranslation } from 'react-i18next';
import { usePlacesCtx, usePlacesDispatchCtx } from './context';
export const PlaceTemp = (props) => {
    const placesCtx = usePlacesCtx();
    const placesDispatch = usePlacesDispatchCtx();
    const modalDispatch = useModalDispatchCtx();
    const { t } = useTranslation();
    useEffect(() => {
        if (placesCtx.temporaryPlace) {
            const id = placesCtx.temporaryPlace._id ? placesCtx.temporaryPlace._id : nanoid();
            modalDispatch({
                type: 'SET_NODE',
                title: t('place'),
                nodeType: { type: 'place', id: id, subType: 'tmp_place' },
                node: React.createElement(PlaceDetailIndex, { place: placesCtx.temporaryPlace }),
                onClose: () => placesDispatch({ type: 'REMOVE_TMP_PLACE' }),
            });
        }
    }, [placesCtx.temporaryPlace]);
    return (React.createElement(React.Fragment, null, placesCtx.temporaryPlace && (React.createElement(Place, { key: placesCtx.temporaryPlace._id ? placesCtx.temporaryPlace._id : nanoid(), map: props.map, place: placesCtx.temporaryPlace }))));
};
