var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { AttributionControl, GeolocateControl, Map as MapInstance, NavigationControl, } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useApplicationCtx } from '../../../context/context';
import { reverseGeocode } from '../../../api/tomtom';
import { WaypointMapIndex } from '../molecules/waypoint/map';
import * as turf from '@turf/turf';
import { useLoaderData } from 'react-router-dom';
import { calculateBBoxForWaypoints, convertToPlaceAddress } from '../../../lib/utils';
import { Places } from '../molecules/place';
import { Routes } from '../molecules/route';
import { zoomTo } from '../../../lib/zoom';
import { useModalCtx } from '../atoms/modal/context';
import { useDialogDispatchCtx } from '../atoms/dialog/context';
import { useTranslation } from 'react-i18next';
import { PlaceTemp } from '../molecules/place/temp';
import PromiseQueue from '../../../lib/promiseQueue';
import { useMapCtx, useMapDispatchCtx } from '../atoms/map/context';
import { ThirdPartyPlacesMapItems } from '../molecules/thirdPartyPlaces/map';
import { usePlacesDispatchCtx } from '../molecules/place/context';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const style = require('../../../../public/assets/streets_touring_studio.json');
const promiseQueue = PromiseQueue.getInstance();
export const Map = () => {
    const { t } = useTranslation();
    const loader = useLoaderData();
    const context = useApplicationCtx();
    const modalContext = useModalCtx();
    const dialogDispatch = useDialogDispatchCtx();
    const mapElement = React.useRef(null);
    const [mapInstance, setMapInstance] = useState(null);
    const mapDispatch = useMapDispatchCtx();
    const mapCtx = useMapCtx();
    const placeDispatch = usePlacesDispatchCtx();
    const addReverseGeoCodeWaypoint = (location) => __awaiter(void 0, void 0, void 0, function* () {
        const address = yield reverseGeocode(location);
        if (!address)
            return;
        placeDispatch({
            type: 'SET_TMP_PLACE',
            place: {
                type: 'default',
                address: convertToPlaceAddress(address),
                location: location,
                state: {
                    public: false,
                },
            },
        });
    });
    const onDblClick = (e) => {
        if (!mapInstance)
            return;
        const features = mapInstance.queryRenderedFeatures(e.point);
        const isOverWater = features.filter((f) => { var _a; return (_a = f.sourceLayer) === null || _a === void 0 ? void 0 : _a.includes('water'); }).length > 0;
        if (!isOverWater) {
            const { lat, lng } = e.lngLat;
            addReverseGeoCodeWaypoint({ type: 'Point', coordinates: [lng, lat] });
        }
        else {
            dialogDispatch({ type: 'SET_NODE', node: t('insertWaypointNotPossible') });
        }
    };
    const calculateCenter = () => {
        var _a, _b;
        const defaultLocation = [15.3909583, 47.0332011];
        if (loader.tour) {
            const waypointsMerged = context.tour.groups.flatMap((group) => group.waypoints.flatMap((w) => (w.lineString != null ? w.lineString.coordinates : [])));
            const bbox = calculateBBoxForWaypoints(waypointsMerged);
            if (bbox)
                return turf.center(turf.bboxPolygon(bbox)).geometry.coordinates;
            return defaultLocation;
        }
        else if (loader.user) {
            return (_b = (_a = loader.user.location) === null || _a === void 0 ? void 0 : _a.coordinates) !== null && _b !== void 0 ? _b : defaultLocation;
        }
        else {
            return defaultLocation;
        }
    };
    useEffect(() => {
        var _a;
        const mapInit = new MapInstance({
            container: (_a = mapElement.current) !== null && _a !== void 0 ? _a : '',
            center: calculateCenter(),
            zoom: 7,
            maxZoom: 18,
            minZoom: 0,
            doubleClickZoom: false,
            attributionControl: false,
            //style: `https://api.maptiler.com/maps/642c77a9-2113-496f-ba85-3fdfb9d707f4/style.json?key=31Sl0qCYeBBroXEo9Quu`,
            style: style,
        });
        mapInit.setPadding({
            top: 200,
            left: 600,
            bottom: 200,
            right: 150,
        });
        mapInit.addControl(new GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true,
            },
            trackUserLocation: true,
        }));
        mapInit.addControl(new NavigationControl({ visualizePitch: true }));
        mapInit.addControl(new AttributionControl({
            compact: true,
        }));
        mapInit.on('load', () => {
            setMapInstance(mapInit);
            mapDispatch({ type: 'SET', data: Object.assign(Object.assign({}, mapCtx), { zoom: mapInit.getZoom() }) });
        });
    }, []);
    useEffect(() => {
        if (!mapInstance)
            return;
        promiseQueue.add(() => {
            return new Promise((resolve) => {
                if (modalContext.node != null) {
                    mapInstance.easeTo({
                        padding: {
                            top: 200,
                            left: 1000,
                            bottom: 200,
                            right: 150,
                        },
                        duration: 300,
                    });
                }
                else {
                    mapInstance.easeTo({
                        padding: {
                            top: 200,
                            left: 600,
                            bottom: 200,
                            right: 150,
                        },
                        duration: 300,
                    });
                }
                setTimeout(() => {
                    resolve();
                }, 300);
            });
        });
    }, [modalContext]);
    useEffect(() => {
        if (!mapInstance || context.ui.isReadOnly)
            return;
        mapInstance.on('dblclick', onDblClick);
        return () => {
            mapInstance.off('dblclick', onDblClick);
        };
    }, [mapInstance, context.tour.groups, context.ui.defaultTravelMode]);
    const zoomToBBox = (e) => {
        const bbox = e.detail.bbox;
        promiseQueue.add(() => {
            return new Promise((resolve) => {
                mapInstance === null || mapInstance === void 0 ? void 0 : mapInstance.fitBounds(bbox, {
                    duration: 800,
                    pitch: e.detail.pitch,
                    essential: true,
                    maxZoom: 18,
                });
                setTimeout(() => {
                    resolve();
                }, 800);
            });
        });
    };
    const flyToBBox = (e) => {
        const bbox = e.detail.bbox;
        promiseQueue.add(() => {
            return new Promise((resolve) => {
                mapInstance === null || mapInstance === void 0 ? void 0 : mapInstance.flyTo({
                    center: turf.center(turf.bboxPolygon(bbox)).geometry.coordinates,
                    zoom: 8,
                    duration: 800,
                    essential: true,
                    curve: 1,
                });
                setTimeout(() => {
                    resolve();
                }, 850);
            });
        });
    };
    const setZoomInContext = () => {
        var _a;
        const zoom = (_a = mapInstance === null || mapInstance === void 0 ? void 0 : mapInstance.getZoom()) !== null && _a !== void 0 ? _a : 0;
        mapDispatch({ type: 'SET', data: Object.assign(Object.assign({}, mapCtx), { zoom: zoom }) });
    };
    useEffect(() => {
        if (!mapInstance)
            return;
        if (loader.tour) {
            zoomTo(context.tour.groups.flatMap((gr) => gr.waypoints));
        }
        window.addEventListener('zoomMapTo', zoomToBBox);
        window.addEventListener('flyMapTo', flyToBBox);
        mapInstance.on('zoom', setZoomInContext);
        return () => {
            window.removeEventListener('zoomMapTo', zoomToBBox);
            window.removeEventListener('flyMapTo', flyToBBox);
            mapInstance.off('zoom', setZoomInContext);
        };
    }, [mapInstance]);
    return (React.createElement("div", { className: "z-10", style: { height: '100vh', width: '100vw' } },
        React.createElement("div", { ref: mapElement, style: { width: '100%', height: '100%' } }),
        mapInstance && React.createElement(WaypointMapIndex, { map: mapInstance }),
        mapInstance && React.createElement(Routes, { map: mapInstance }),
        context.user && mapInstance && React.createElement(Places, { map: mapInstance }),
        mapInstance && React.createElement(PlaceTemp, { map: mapInstance }),
        React.createElement(ThirdPartyPlacesMapItems, { map: mapInstance })));
};
