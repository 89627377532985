import React from 'react';
import { LayoutDefault } from '../ui/components/molecules/layout/default';
import { AlertTriangleIcon } from '../ui/touring-ui/icons/_export';
import { PageIndex } from './index';
export const NotFound = () => (React.createElement(PageIndex, null,
    React.createElement(LayoutDefault, null,
        React.createElement("div", { className: "text-center flex flex-col gap-5 items-center" },
            React.createElement(AlertTriangleIcon, { className: "w-14 h-14" }),
            React.createElement("h1", { className: "text-2xl" }, "Oops! You've ventured into the digital wilderness."),
            React.createElement("p", null, "It seems like the page you were looking for has gone on a little hide-and-seek adventure. Don't worry; we've dispatched our best virtual search party to track it down.")))));
