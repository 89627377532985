import React, { createContext, useContext } from 'react';
import { produce } from 'immer';
import { useImmerReducer } from 'use-immer';
const setNode = (ctx, action) => {
    var _a, _b;
    if (((_a = ctx.meta) === null || _a === void 0 ? void 0 : _a.id) == action.nodeType.id &&
        ctx.meta.type == action.nodeType.type &&
        ctx.meta.subType == action.nodeType.subType)
        return;
    ctx.meta = action.nodeType;
    ctx.node = action.node;
    ctx.title = action.title;
    ctx.markerRef = (_b = action.markerRef) !== null && _b !== void 0 ? _b : null;
    ctx.onClose = action.onClose;
    ctx.onDisappear = action.onDisappear;
};
const setRef = (ctx, action) => {
    ctx.ref = action.ref;
};
const setMarkerRef = (ctx, action) => {
    ctx.markerRef = action.markerRef;
};
const removeNode = (ctx) => {
    ctx.meta = null;
    ctx.node = null;
    ctx.title = '';
    ctx.markerRef = null;
    ctx.onClose = undefined;
    ctx.onDisappear = undefined;
};
const ModalInitialContext = {
    meta: null,
    node: null,
    title: '',
    ref: null,
    markerRef: null,
};
const dndReducer = produce((ctx, action) => {
    switch (action.type) {
        case 'SET_REF':
            setRef(ctx, action);
            break;
        case 'SET_NODE':
            setNode(ctx, action);
            break;
        case 'SET_MARKER_REF':
            setMarkerRef(ctx, action);
            break;
        case 'REMOVE_NODE':
            removeNode(ctx);
            break;
    }
});
const ModalContextProvider = createContext(ModalInitialContext);
const ModalDispatchCtx = createContext(() => { });
export const useModalCtx = () => useContext(ModalContextProvider);
export const useModalDispatchCtx = () => useContext(ModalDispatchCtx);
export const ModalContext = (props) => {
    const [ctx, dispatch] = useImmerReducer(dndReducer, ModalInitialContext);
    return (React.createElement(ModalContextProvider.Provider, { value: ctx },
        React.createElement(ModalDispatchCtx.Provider, { value: dispatch }, props.children)));
};
