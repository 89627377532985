import React, { memo, useEffect, useState } from 'react';
import { useApplicationCtx, useApplicationDispatchCtx } from '../../../../../context/context';
import { WaypointDetailGeneral } from './general';
import { CardBody, CardFooter } from '../../../../touring-ui/components/cards';
import { Flexbox } from '../../../../touring-ui/components/flexbox';
import { WaypointActivitiesIndex } from './activities';
import { useModalDispatchCtx } from '../../../atoms/modal/context';
import { Anchor } from '../../../../touring-ui/components/anchor';
import { Divider } from '../../../../touring-ui/components/general/divider';
import { ChevronLeftIcon, Trash01Icon, Umbrella03Icon, ZoomInIcon } from '../../../../touring-ui/icons/_export';
import * as turf from '@turf/turf';
import { useTranslation } from 'react-i18next';
export const WaypointDetailIndex = memo((props) => {
    var _a;
    const { t } = useTranslation();
    const { groupIndex, waypointIndex } = props;
    const context = useApplicationCtx();
    const group = (_a = context.tour.groups[groupIndex]) !== null && _a !== void 0 ? _a : null;
    const waypoint = group ? group.waypoints[waypointIndex] : null;
    const [show, setShow] = useState({ type: 'settings' });
    const dispatch = useApplicationDispatchCtx();
    const modalDispatch = useModalDispatchCtx();
    const actions = {
        onClose: () => {
            modalDispatch({ type: 'REMOVE_NODE' });
        },
        onDelete: () => {
            if (confirm(t('deleteConfirm'))) {
                if (!(waypoint === null || waypoint === void 0 ? void 0 : waypoint.location))
                    return;
                actions.onClose();
                dispatch({
                    type: 'REMOVE_WAYPOINT',
                    details: { groupIndex: props.groupIndex, waypointIndex: props.waypointIndex },
                });
            }
        },
        onZoom: () => {
            if (waypoint === null || waypoint === void 0 ? void 0 : waypoint.location) {
                const buffer = turf.buffer(waypoint.location, 100, { units: 'meters' });
                const bbox = turf.bbox(buffer);
                window.dispatchEvent(new CustomEvent('zoomMapTo', { detail: { bbox, pitch: 0 } }));
            }
        },
    };
    useEffect(() => {
        if (!waypoint)
            actions.onClose();
    }, [waypoint]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CardBody, { className: 'text-sm' },
            show.type == 'settings' && (React.createElement(Flexbox, { direction: 'horizontal', gap: 'lg' },
                !props.hideActivities && (React.createElement(Anchor, { className: 'text-sm', onClick: () => {
                        setShow({ type: 'activities' });
                    } },
                    React.createElement(Flexbox, { direction: 'horizontal', position: 'center', gap: 'sm' },
                        React.createElement(Umbrella03Icon, { className: 'w-4 h-4' }),
                        t('nearbyActivities')))),
                React.createElement("div", { className: 'flex-grow' }),
                !props.hideZoom && (React.createElement(Anchor, { onClick: actions.onZoom },
                    React.createElement(Flexbox, { direction: 'horizontal', position: 'center', gap: 'sm' },
                        React.createElement(ZoomInIcon, { className: 'w-4 h-4' })))),
                React.createElement(Anchor, { onClick: actions.onDelete },
                    React.createElement(Flexbox, { direction: 'horizontal', position: 'center', gap: 'sm' },
                        React.createElement(Trash01Icon, { className: 'w-4 h-4' }))))),
            show.type == 'activities' && (React.createElement(Anchor, { onClick: () => {
                    setShow({ type: 'settings' });
                } },
                React.createElement(Flexbox, { direction: 'horizontal', position: 'center', gap: 'sm' },
                    React.createElement(ChevronLeftIcon, { className: 'w-4 h-4' }),
                    t('goBack'))))),
        React.createElement(Divider, null),
        waypoint && show.type == 'settings' && (React.createElement(CardBody, { key: waypoint.id },
            React.createElement(Flexbox, { direction: 'vertical', gap: 'lg' },
                React.createElement(WaypointDetailGeneral, { groupIndex: props.groupIndex, waypointIndex: props.waypointIndex, hideExtendedRouteOptions: props.hideExtendedRouteOptions, hideZoom: props.hideZoom, hideActivities: props.hideActivities })))),
        waypoint && show.type == 'activities' && (React.createElement(React.Fragment, null, waypoint.address && waypoint.location && (React.createElement(CardBody, null,
            React.createElement(WaypointActivitiesIndex, { address: waypoint.address, location: waypoint.location }))))),
        React.createElement(CardFooter, null, "\u00A0")));
}, (oldProps, newProps) => {
    return oldProps.groupIndex == newProps.groupIndex && oldProps.waypointIndex == newProps.waypointIndex;
});
